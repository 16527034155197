.btn_drawer {
  border: none;
  display: block;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  padding: 5px 0 5px 20px;
  width: 100%;
  text-align: left;
  @include border-radius(4px);

  background-color: transparent;
  color: $app_color;
  margin: 0px;
  overflow: visible;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 0px solid;
  @include box-sizing(border-box);

  .btn_icon {
    @include transition(all 0.1s ease);
  }

  &:hover,
  &:focus {
    color: $blue;
  }

  &:hover {
    background-color: $lighter_grey;
  }

  &.btn_drawer_active {
    color: $app_color;

    .btn_icon {
      @include rotate(90);
    }
  }
}

.drawer_container {
  .drawer_header {
    position: relative;
    padding-left: 30px;

    .drawer_header_toggle {
      position: absolute;
      left: 0;
      top: 0;
    }

    .drawer_header_title {
      .drawer_content {
        margin-left: 30px;
        .tree_link {
          width: 100%;
          display: block;
          @include box-sizing(border-box);
        }
      }

      a {
        font-weight: 600;
      }
    }
  }

  /* Drawer Content (Hidden by Default) */
  .drawer_content {
    display: none;
    position: relative;

    &.drawer_active {
      display: block;
    }
  }
}

.drawer_active {
  font-weight: bold !important;
  color: $green !important;
}
