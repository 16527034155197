app-account-tree,
app-equation-bar,
app-exogenous-lines-equations,
app-exogenous-lines,
app-hover-notification,
app-import-alm-dataset,
app-input-structure,
app-invalid-rows,
app-liquidity-sources,
app-liquity-equations,
app-lookup-data,
app-lookup-setup,
app-lookup-tree,
app-root,
app-balance-sheet,
app-scenario-list,
app-simulation-set,
app-template,
gc-spread-sheets {
  @extend .full-size;
}

app-account-tree,
app-lookup-tree,
app-manage-results {
  @extend .display-content;
}

app-lookup-setup,
app-liquidity-sources,
app-exogenous-lines {
  .panel-main-header {
    min-height: 50px !important;
    height: 50px !important;
  }
}

app-equation-bar,
app-balance-sheet {
  kendo-window {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  }
  kendo-window-titlebar,
  .k-dialog-titlebar,
  .k-window-titlebar {
    background-color: #51738b !important;
    padding: 0.25rem 0.5rem !important;
  }
}


// Setup pages dropdowns
app-input-structure,
app-lookup-setup,
app-liquidity-sources,
app-exogenous-lines {
  select {
    animation: fadein 0.3s;
  }
}
