ul#notifications_list {
	margin:0;
	padding:0;
	list-style: none;

	>li {
		padding: 10px 0;
		border-bottom: 1px solid $border_color;

		&:last-child {
			border-bottom: 0px solid $border_color;
		}

		.notification_title {}

		p {
			line-height: 20px;
			color: $dark_grey;

			&:last-of-type {
				padding-bottom: 0;
				margin-bottom: 0;
			}

			a {
				color: $notification;
				text-decoration: none;

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}

	}
}


#notification_container {
	font-size: $btn_font_size;
	background-color: $notification;
	color: $dark_grey;
	padding: 5px;
	text-align: center;
	display: block;
	margin:0 auto;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	// @include transform(translate(-50%,0));
	// @include border-bottom-radius(4px);
	z-index: 1;

	p {
		line-height: 15px;

		&:last-child {
			padding:0;
			margin:0;
		}
	}

	[class^="icon_"], [class*=" icon_"] {
		vertical-align: middle;
	}
}