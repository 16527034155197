.notification-banner-message {
  @extend .top-inner-shadow;
  @extend .border--bottom;
  // @extend .border--top;
  // @extend .bottom-inner-shadow;
  position: relative;
  width: 100%;
  color: $black;
  background: $white;
  cursor: default;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: $notification_light;
  padding-bottom: 1px;

  .banner-title,
  .banner-body,
  .banner-actions {
    width: auto;
    text-align: center;
    max-width: 800px;
  }

  .banner-title,
  .banner-body {
    padding: 0.618rem auto;
  }

  .banner-actions {
    padding-bottom: 0.618rem;
  }

  .banner-actions {
    display: flex;
    justify-content: center;

    a,
    .link,
    .banner-action-button,
    button {
      @extend .btn_normal;
      background: unset;
      color: unset;
      border: unset;
      flex: 1 auto;
      flex-wrap: wrap;
      text-transform: uppercase;
      color: $primary_color;
      width: auto;
      min-width: 7rem;
      min-height: 2rem;
      margin-left: 1rem;
      cursor: pointer;

      :first-of-type() {
        margin-left: 0;
      }

      &.primary-action {
        background: $navy;
        color: $white;
      }

      &.secondary-action {
        background: transparent;
        border: 1px solid $navy;
        color: $navy;
      }
    }

    // .toast-close-button {
    //   &,
    //   button {
    //     color: $black;
    //   }
    // }
  }
}

app-notification-banner,
.app-notification-banner,
.notification-banner-message {
  width: 100%;
  padding: 0;
}

// Overwrites the default toast-container styles If they include the class "banner-like-message"
// toaster-container.notification-banner-message {
//   @extend .notification-banner-message;

//   #toast-container {
//     position: relative;

//     .toast {
//       position: relative;
//       box-sizing: border-box;
//       width: 100%;
//       margin: 0;
//       padding: 0;
//       border-radius: 0; // border-radius: 3px;
//       background: unset;
//       background-position: unset;
//       background-repeat: unset;
//       box-shadow: unset;
//       color: unset;
//       opacity: 1;
//       // opacity: 0.8;
//       // -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
//       // filter: alpha(opacity=80);
//       transform: translate(0px, 0px);

//       .toaster-icon {
//         display: none;
//       }
//     }
//   }
// }
