#sim_group,
.sim_group {
  width: calc(100% - 30px);
  padding: 15px;

  // Sim Group Header
  #sim_header {
    border-bottom: 1px solid $app_color;

    > div {
      .sim_header_label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  .sim_scenario,
  .sim_mnemonic,
  .sim_description {
    float: left;
    min-height: 30px;
    padding: 5px;
    @include box-sizing(border-box);

    .is_invalid_mnemonic {
      line-height: 20px;
      margin: 0;
      width: 100%;
      @include box-sizing(border-box);

      .idle-component {
        border: 1px solid $red;
        @include border-radius(4px);
      }

      .invalid-message {
        white-space: normal;
      }
    }

    &:hover {
      .inner_cell_text {
        .inline-edit {
          text-decoration: underline;
        }
      }
    }

    .inner_cell_text {
      line-height: 20px;
      padding: 0px 0px;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;

      .inline-edit {
        line-height: 20px;
        padding: 5px;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;

        &.force_multiline_text {
          white-space: normal !important;
        }
      }

      input {
        line-height: 20px;
        margin: 0;
        padding: 4px 5px;
        border: 1px solid $input_border;
        @include border-radius(4px);
        width: 100%;
        @include box-sizing(border-box);
      }
    }
  }

  .sim_row_toggle,
  .sim_last_updated,
  .sim_dataset,
  .sim_delete,
  .sim_notifications,
  .sim_action {
    float: left;
    min-height: 30px;
    padding: 5px 5px;
    @include box-sizing(border-box);
  }

  .inner_cell_text {
    line-height: 20px;
    padding: 5px 0px;
    display: block;
    @include box-sizing(border-box);
    vertical-align: top;

    a {
      color: $navy;

      &:hover,
      &:focus {
        color: $green;
      }
    }
  }

  .sim_scenario {
    .inner_cell_text {
      font-weight: 600;
    }
  }

  .sim_row_toggle {
    width: 40px;
  }
  .sim_scenario {
    width: 15%;
  }
  .sim_mnemonic {
    width: 15%;
  }
  .sim_description {
    width: calc(70% - 370px);
  } // This is the width of all the fixed cells

  .manage_resultsets_table {
    .sim_description {
      width: calc(70% - 370px + 40px);
    } // This is the width of all the fixed cells
  }

  .sim_last_updated {
    width: 130px;
  }
  .sim_dataset {
    width: 160px;
  }
  .sim_delete {
    width: 40px;
  }
  .sim_notifications {
    width: 40px;
  }
  .sim_action {
    width: 120px;
  }

  .sim_notifications {
    .icon_notification {
      color: $notification;
    }
  }

  .sim_delete {
    button {
      @include opacity(0.1);
    }
  }

  .sim_description {
    .inner_cell_text {
      &.wrap_text {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 30px);
      }
    }

    .more_link,
    .less_link {
      line-height: 20px;
      padding: 5px 0px;
      display: inline-block;
      @include box-sizing(border-box);
      vertical-align: top;

      a {
        color: $navy;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  // Sim Group Row
  .sim_row_container {
    padding: 10px 0;
    border-bottom: 1px solid $border_color;

    &:last-of-type {
      padding: 10px 0;
      border-bottom: 1px solid $border_color;
    }

    &.show_children {
      .sim_row {
        .sim_row_toggle {
          button {
            font-size: 12px;
            position: relative;
            @include rotate(90);

            .btn_icon {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .sim_row {
      .sim_row_toggle {
        button {
          font-size: 12px;

          .btn_icon {
            span {
              font-size: 12px;
            }
          }
        }
      }

      &:hover {
        background-color: $row_hover;

        .sim_delete {
          button {
            @include opacity(1);
          }
        }
      }

      // Baseline
      &.sim_baseline {
        .sim_scenario {
          padding-left: 20px;
          @include box-sizing(border-box);
          position: relative;

          .color-dot {
            @include color-dot($grey); // color by default
          }

          select {
          }
        }
      }

      // Stress
      &.sim_stress {
        .sim_scenario {
          padding-left: 35px;
          @include box-sizing(border-box);
          position: relative;

          .color-line {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: 0 auto;
            width: 4px;
            height: 100%;
            margin-left: -1em;
            background-color: $grey; // default
          }

          select {
          }
        }
      }
    }

    .sim_stress_add {
      .sim_scenario {
        padding-left: 35px;
        @include box-sizing(border-box);
        position: relative;
      }
    }

    .sim_baseline_add {
      .sim_scenario {
        padding-left: 20px;
        @include box-sizing(border-box);
        position: relative;

        .color-dot {
          @include color-dot($grey);
        }

        select {
        }
      }
    }
  }
}

.is_invalid_dataset {
  border: 1px solid red !important;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.8rem;
}
