.inputbox[type="radio"]:checked,
.inputbox[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.inputbox[type="radio"]:checked + label,
.inputbox[type="radio"]:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $app_color;
    padding: 5px 0 5px 20px !important;
}
.inputbox[type="radio"]:checked + label:before,
.inputbox[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 14px;
    height: 14px;
    border: 1px solid $input_border;
    border-radius: 100%;
    background: $white;
}

.inputbox[type="radio"]:checked + label:after,
.inputbox[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: $navy;
    position: absolute;
    top: 9px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}




// Hover & Focus States

.inputbox[type="radio"]:focus + label,
.inputbox[type="radio"]:hover + label {
    color: $app_color;
}
.inputbox[type="radio"]:focus + label:before,
.inputbox[type="radio"]:hover + label:before {
    border: 1px solid $input_border_focus;
}

.inputbox[type="radio"]:focus + label:after,
.inputbox[type="radio"]:hover + label:after {
}


// Disabled State

.inputbox[type="radio"]:disabled:checked + label,
.inputbox[type="radio"]:disabled:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $app_color;
    padding: 5px 0 5px 20px;
}
.inputbox[type="radio"]:disabled:checked + label:before,
.inputbox[type="radio"]:disabled:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 14px;
    height: 14px;
    border: 1px solid $grey;
    border-radius: 100%;
    background: $light_grey;
}

.inputbox[type="radio"]:disabled:checked + label:after,
.inputbox[type="radio"]:disabled:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: $dark_grey;
    position: absolute;
    top: 9px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}



// Checked & Not Checked State


.inputbox[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.inputbox[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.inputbox[type="radio"]:checked + label:before {
    border-color: $navy;
}
