.inputbox[type="checkbox"]:checked,
.inputbox[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.inputbox[type="checkbox"]:checked + label,
.inputbox[type="checkbox"]:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $app_color;
    padding: 5px 0 5px 20px !important;
}
.inputbox[type="checkbox"]:checked + label:before,
.inputbox[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 14px;
    height: 14px;
    border: 1px solid $input_border;
    background: $white;
    @include border-radius(3px);
}

.inputbox[type="checkbox"]:checked + label:after,
.inputbox[type="checkbox"]:not(:checked) + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 3px;
    height: 8px;
    border: solid $navy;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


// Hover & Focus State

.inputbox[type="checkbox"]:hover + label,
.inputbox[type="checkbox"]:focus + label {
    color: $app_color;
}
.inputbox[type="checkbox"]:hover + label:before,
.inputbox[type="checkbox"]:focus + label:before {
    border: 1px solid $input_border_focus;
}

.inputbox[type="checkbox"]:hover + label:after,
.inputbox[type="checkbox"]:focus + label:after {
}


// Disabled State

.inputbox[type="checkbox"]:disabled:checked + label,
.inputbox[type="checkbox"]:disabled:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $grey;
    padding: 5px 0 5px 20px;
}
.inputbox[type="checkbox"]:disabled:checked + label:before,
.inputbox[type="checkbox"]:disabled:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 14px;
    height: 14px;
    border: 1px solid $grey;
    background: $light_grey;
}

.inputbox[type="checkbox"]:disabled:checked + label:after,
.inputbox[type="checkbox"]:disabled:not(:checked) + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 3px;
    height: 8px;
    border: solid $dark_grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


// Checked & Unchecked State

.inputbox[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

.inputbox[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: $white;
}

.inputbox[type="checkbox"]:checked + label:before {
    border-color: $navy;
    border-width: 0;
    height: 16px;
    width: 16px;
    background-color: $navy;
}

