// // Common properties
// .animation {
//   animation-duration: 1s;
//   animation-fill-mode: forwards;
//   animation-timing-function: ease-in-out;
// }

// // APPEAR

// .appear {
//   @extend .animation;
//   height: 100%;
//   animation-name: appearAnimation;
// }

// @keyframes appearAnimation {
//   0% {
//     height: 0%;
//     // top: -100%;
//   }
//   100% {
//     height: 100%;
//     // top: 0;
//   }
// }

// // DISAPPEAR

// .disappear {
//   @extend .animation;
//   height: 0%;
//   animation-name: disappearAnimation;
// }

// @keyframes disappearAnimation {
//   0% {
//     // top: 0;
//     height: 100%;
//   }
//   100% {
//     // top: -100%;
//     height: 0;
//   }
// }

.fadein {
  animation: fadein 0.3s;
}
.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
