#sheet_list {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 400px;
  z-index: 1;
  background: $white;
  padding: 15px;
  @include border-radius(3px);
  box-shadow: 0 1px 4px rgba($black, 0.3), 0 1px 20px rgba($black, 0.3);
  @include box-sizing(border-box);

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      // ! DEPRECATED since task 3635
      // &:nth-child(1) {
      //   .sheet_name:before {
      //     background-color: $group_color_1;
      //   }
      // }

      // &:nth-child(2) {
      //   .sheet_name:before {
      //     background-color: $group_color_2;
      //   }
      // }

      // &:nth-child(3) {
      //   .sheet_name:before {
      //     background-color: $group_color_3;
      //   }
      // }

      // &:nth-child(4) {
      //   .sheet_name:before {
      //     background-color: $group_color_4;
      //   }
      // }
      // &:nth-child(5) {
      //   .sheet_name:before {
      //     background-color: $group_color_5;
      //   }
      // }
      // &:nth-child(6) {
      //   .sheet_name:before {
      //     background-color: $group_color_6;
      //   }
      // }
      // &:nth-child(7) {
      //   .sheet_name:before {
      //     background-color: $group_color_7;
      //   }
      // }
      // &:nth-child(8) {
      //   .sheet_name:before {
      //     background-color: $group_color_8;
      //   }
      // }

      > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          a {
            display: block;
            color: inherit;
            text-decoration: none;
            padding: 0;

            &:hover,
            &:focus {
              background-color: $lighter_grey;
              color: $app_color;

              .sheet_name {
                text-decoration: underline;
              }
            }

            &:after {
              clear: both;
              content: '';
              display: block;
              height: 0;
              visibility: hidden;
            }

            .sheet_name {
              width: 70%;
              display: block;
              vertical-align: top;
              float: left;
              word-wrap: break-word;
              @include box-sizing(border-box);
              padding: 5px 0 5px 20px;
              line-height: 20px;
              position: relative;
            }

            .sheet_dataset {
              padding: 5px 0;
              line-height: 20px;
              width: 30%;
              display: block;
              vertical-align: right;
              float: left;
              text-align: right;
              word-wrap: break-word;
            }

            &.sheet_baseline {
              .sheet_name {
                &:before {
                  content: '';
                  position: absolute;
                  left: 5px;
                  top: 10px;
                  height: 10px;
                  width: 10px;
                  @include border-radius(50%);
                }
              }
            }

            &.sheet_stress {
              .sheet_name {
                padding-left: 40px;

                &:before {
                  content: '';
                  position: absolute;
                  left: 20px;
                  top: 0;
                  bottom: 0;
                  height: 100%;
                  width: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
