/*

    HELPERS

*/

.link {
  cursor: pointer;
}

.full-size {
  height: 100%;
  width: 100%;
}

.display-content {
  display: contents;
}

.page-container,
.fill {
  height: 100%;
  width: 100%;
  display: contents;
}

.bold {
  font-weight: bold;
}

.hidden,
.invisible {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.flex {
  display: flex;
}

.fill {
  min-height: 100%;
  height: 100%;
}

// Borders
.border--left {
  border-left: 1px solid $border_color;
}

.border--right {
  border-right: 1px solid $border_color;
}

.border--bottom {
  border-bottom: 1px solid $border_color;
}

.border--top {
  border-top: 1px solid $border_color;
}

.border--all {
  border: 1px solid $border_color;
}

.shadow {
  content: '';
  position: relative;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 10px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  border: 0;
  overflow: hidden;
}

.top-inner-shadow {
  &:before {
    @extend .shadow;
    top: -11px;
  }
}

// .bottom-inner-shadow {
//   &:after {
//     @extend .shadow;
//     bottom: -15px;
//   }
// }
