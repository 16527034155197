@import './../common/_animations.scss';

.panel-left-body,
.panel-right-body {
  // overflow: auto;
  // max-height: 540px;
  overflow: hidden auto;
  height: auto;

  .tree-warning {
    background-color: $notification;
    color: $white;
    padding: 0.618rem;
  }

  .tree-nodes {
    overflow: hidden auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .tree {
    ul,
    .tree-nodes {
      @extend .fadein;
      overflow: hidden auto;
      padding: 0;
      margin: 0 0 0 15px;
      list-style: none;
    }

    .tree_link {
      // @extend .fadein;
      display: block;
      text-decoration: underline;
      line-height: 20px;
      padding: 5px 5px 5px 20px;
      position: relative;
      //
      display: block;
      width: 100%;
      background-color: transparent;
      position: relative;
      color: #505050;
      line-height: 20px;
      margin: 0px;
      padding: 5px 5px;
      text-align: left;
      overflow: visible;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background-clip: padding-box;
      -webkit-appearance: none;
      box-sizing: border-box;
      border: 0px solid;
      cursor: pointer;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 0.86rem;
      color: #2b495e;
      -webkit-tap-highlight-color: transparent;
      padding-left: 1rem;

      &:before {
        content: $chevron_right;
        font-family: 'empyrean_icons';
        font-size: 10px;
        height: 10px;
        width: 10px;
        position: absolute;
        top: 10px;
        left: 0px;
        line-height: 10px;
      }

      &.tree_link_active {
        &:before {
          @include rotate(90);
        }
      }
    }

    .tree_text {
      display: block;
      line-height: 20px;
      padding: 5px 5px 5px 20px;
      position: relative;

      &:before {
        content: '';
        height: 4px;
        width: 4px;
        background-color: $navy;
        @include border-radius(20px);
        position: absolute;
        top: 13px;
        left: 5px;
      }
    }
  }
}
