
// This structure is mainly used for Checkboxes and Radio Lists
fieldset.form_row {
	margin-bottom: 5px;
	position: relative;

	&.no_padding {
		margin-bottom: 0;
	}

	&.inline {
		position: relative;

		legend {
			width: 35%;
			position: absolute;
			left: 0;
			top: 0
		}

		>.form_group {
			width: 65%;
			margin-left: 35%;
		}

		&.auto_width {
			display: inline-block;

			legend {
				width: auto;
				margin-right: 10px;
				position: relative;
				left: auto;
				top: auto;
			}

			>.form_group {
				display: inline-block;
				width: auto;
				margin-left: auto;
				white-space: nowrap;
			}
		}

		&.split_50 {
			>.data_label {
				float: left;
				width: 50%;
				// padding: 5px 5px 5px 0;
			}

			>.data_value {
				float: left;
				width: 50%;
				// padding: 5px 5px 5px 0;
			}
		}
	}

	&.layout_horizontal {
		>.form_group {

			>.form_row {
				width: auto;
				display: inline-block;
				float: left;
				margin-right: 10px;
				
				&:last-of-type {
					margin-right:0;
				}
			}
		}
	}

	&.layout_stacked {
		>.form_group {
			&.split_2 {
				>.form_row {
					width: 50%;
					float: left;

					label {
						display: block;
					}
				}
			}

			>.form_row {
				width: auto;
				display: block;


			}
		}
	}

	.form_row {
		margin-bottom: 0;
	}
}


.form_row {
	margin-bottom: 5px;
	position: relative;

	&:after {
	    clear: both;
	    content: '';
	    display: block;
	    height: 0;
	    visibility: hidden;
	}

	&.no_padding {
		margin-bottom: 0;
	}

	&.align_center {
		text-align: center;
	}

	&.align_to_label {
		padding-left: 35%;
	}

	&.align_to_label_50 {
		padding-left: 50%;
	}

	&.inline {
		>.form_label {
			float: left;
			width: 35%;
		}

		>.form_input {
			float: left;
			width: 65%;
		}

		&.split_50 {
			>.form_label {
				float: left;
				width: 50%;
			}

			>.form_input {
				float: left;
				width: 50%;
			}
		}
	}

	>.form_label {
		display: block;

		&.hidden_label {
			position: absolute;
			font-size: 0;
			@include opacity (0);

			label {
				padding:0;
			}
		}

		label {
			line-height: 20px;
			padding: 5px 5px 5px 0;
			display: block;

			.label_right {
				float: right;

			}
		}
	}

	>.form_input {
		display: block;
	}

	

	.form_input {
		// Readonly Lock Icon
		&.readonly {
			position: relative;

			

			.inputbox {
				padding-right: 25px !important;
			}
		}

		// Has Inline Button 
		&.input_has_button {
			position: relative;

			.inputbox {
				padding-right: 35px !important;
			}

			.form_input_button {
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		// Has Helper Text
		&.input_has_helper {
			position: relative;
			padding-right: 30px;
			@include box-sizing(border-box);


			.form_input_helper {
				position: absolute;
				line-height: 20px;
				top: 5px;
				right: 0;
				min-width: 25px;
				text-align: left;
			}
		}

		.inputbox_inline_text {
			margin-left: 5px;
			display: inline-block;
		}
	}

	.form_error {
		display: block;
		color: $red;
		line-height: 20px;
		padding:5px 0;
	}

	&.auto_width {
		display: inline-block;
	}
}



.flex_group {
	display: flex;
	flex-direction: rows;
	-webkit-flex-direction: rows;
	width: 100%;
	justify-content: flex-start;
	flex-wrap:wrap;
	-webkit-flex-wrap: wrap;
	margin-top: 5px;

	&.flex_end {
		justify-content: flex-end;
	}

	&:first-of-type {
		margin-top: 0;
	}

	&.flex_no_wrap {
		flex-wrap:nowrap;
		-webkit-flex-wrap: nowrap;
	}
	

	.flex_row {
		&:after {
		    clear: both;
		    content: '';
		    display: block;
		    height: 0;
		    visibility: hidden;
		}

		>.form_label {
			width: auto;
		}

		>.form_input {
			width: auto;
		}

		&.flex_grow {
			flex-grow:1; // modify
			flex: 1 1 auto;
			-webkit-flex: 1 1 auto;
		}
	}

	.flex_row_action {
		margin-left: auto;
	}
}

.form_helper_txt {
	line-height: 20px;
	padding: 5px 0;
	display: block;
}

.form_supplementary_text {
	line-height: 20px;
	padding: 5px 0;
	display: block;
	font-style: italic;
	color:$mid_grey;
}


/* Data Rows */

.data_row {
	display: block;
	margin-bottom: 5px;
	position: relative;

	&:after {
	    clear: both;
	    content: '';
	    display: block;
	    height: 0;
	    visibility: hidden;
	}

	&.no_padding {
		margin-bottom: 0;
	}

	&.inline {
		>.data_label {
			float: left;
			width: 35%;
			// padding: 5px 5px 5px 0;
		}

		>.data_value {
			float: left;
			width: 65%;
			// padding: 5px 5px 5px 0;
		}

		&.split_50 {
			>.data_label {
				float: left;
				width: 50%;
				// padding: 5px 5px 5px 0;
			}

			>.data_value {
				float: left;
				width: 50%;
				// padding: 5px 5px 5px 0;
			}
		}
	}

	>.data_label {
		display: block;
		line-height: 20px;
		display: block;
		@include box-sizing(border-box);

		&.hidden_label {
			position: absolute;
			font-size: 0;
			@include opacity (0);
		}

		&.no_padding {}

	}

	>.data_value {
		display: block;
		line-height: 20px;
		display: block;
		@include box-sizing(border-box);
	}

	&.mimic_form_row {
		>.data_label {
			padding: 5px 0;
		}

		>.data_value {
			padding: 5px 0;
		}
	}
}