.panel_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  min-height: 0;
  width: 100%;
  -webkit-justify-content: flex-start;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: column;

  .panel_header {
    position: relative;
    padding-bottom: 10px;

    &.no_padding {
      padding-bottom: 0;
    }

    .panel_header_title {
    }

    .panel_header_actions {
      position: absolute;
      right: 0;
      top: 0;

      button {
      }
    }
  }

  .panel_controls {
    padding-bottom: 10px;
  }

  .panel_content {
    flex: 1;
    overflow: auto;

    .panel_content_inner {
      overflow: auto;
    }

    // &.spread_container {
    //   background-color: $lighter_grey;
    //   position: relative;
    //   min-height: 400px;

    //   &:before {
    //     content: $sheet_loader;
    //     font-family: 'empyrean_icons';
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     font-size: 80px;
    //     color: $grey;
    //     transform: translate(-50%, -50%);
    //     font-weight: 300;
    //   }

    //   &:after {
    //     content: 'Loading Sheet...';
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     font-size: 1rem;
    //     color: $grey;
    //     transform: translate(-50%, -50%);
    //     margin-top: 50px;
    //     text-align: center;
    //     font-weight: 300;
    //   }

    //   .panel_content_inner {
    //   }
    // }
  }
}
