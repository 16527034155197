ul#application_list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    float: left;
    width: calc(100% / 3);
    text-align: center;

    a.application_link {
      display: block;
      text-decoration: none;
      @include border-radius(4px);
      text-align: center;
      padding: 10px 4px;
      color: inherit;

      &:hover,
      &:focus {
        background-color: rgba($navy, 0.1);

        .application_icon {
          background-color: rgba($navy, 0.3);
          color: $navy;
        }

        .application_name,
        .application_subtitle {
          color: $navy;
        }
      }

      &.disabled {
        opacity: 0.3;
        transition: opacity 0.6s;
      }

      .application_icon {
        display: block;
        padding: 10px;
        @include border-radius(50px);
        background-color: rgba($black, 0.05);
        width: 50px;
        @include box-sizing(border-box);
        margin: 0 auto 10px;

        span {
          line-height: 30px;
          width: 30px;
          text-align: center;
          font-size: 24px;
          display: block;
          vertical-align: top;
        }
      }

      .application_name {
        text-align: center;
        display: block;
        line-height: 15px;
        min-height: 30px;
        font-size: 0.8rem;
      }

      .application_subtitle {
        text-align: center;
        display: block;
        font-size: 0.7rem;
      }
    }
  }
}
