@import '~angular2-toaster/toaster.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/cdk/overlay-prebuilt.css';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import 'app/styles/icons/fonts/source-sans-pro.css';
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mat-mdc-slider-ripple-color: #3f51b5;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mat-mdc-slider-ripple-color: #ff4081;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3f51b5;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

/****************************
*     GLOBAL STYLES         *
* for all the application   *
****************************/
/****** Spreadsheet ********/
/********** Base **********/
/********** Colours **********/
/********** Pages **********/
/********** Modal **********/
/********** Button **********/
/******* Icons ********/
/******* Icons ********/
/******* Media Queries ********/
html {
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  text-rendering: optimizelegibility;
}

body {
  font-size: 0.86rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 400;
  color: #505050;
  background: #ffffff;
  -webkit-text-size-adjust: none;
  margin: 0px;
  padding: 0;
}
body.no_scroll {
  height: 100%;
  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
}

abbr {
  text-decoration: none;
}

a:link,
a:visited {
  color: #2b495e;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  color: #72bf44;
}

p {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  line-height: 140%;
}

*:focus {
  outline: none;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

.overflow {
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
  overflow: auto;
}

.hidden_text {
  position: absolute !important;
  font-size: 0 !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.space_divider {
  height: 10px;
  display: block;
}
.space_divider.extra_padding {
  margin-top: 10px;
  margin-bottom: 10px;
}

.line_divider {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  display: block;
}
.line_divider.extra_padding {
  margin-top: 30px;
  margin-bottom: 30px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.outcome_icon {
  font-size: 80px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 20px auto 20px;
}
.outcome_icon.success {
  color: #72bf44;
}
.outcome_icon.error {
  color: #ea5441;
}

.outcome_title {
  font-size: 1.4rem;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
}
.outcome_title.success {
  color: #72bf44;
}
.outcome_title.error {
  color: #ea5441;
}

.outcome_text {
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #000000;
  border-color: #000000 transparent #000000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#vp {
  border-top: 0px solid #dfe0e1;
}

.chevron_toggle_icon {
  font-size: 2em;
  font-style: normal;
  cursor: pointer;
}
.chevron_toggle_icon:before {
  content: "\e900";
  font-family: "empyrean_icons";
  font-size: 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 10px;
  left: 0px;
  line-height: 10px;
}
.chevron_toggle_icon.expanded:before {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon_spinner {
  display: inline-block;
  margin-right: 5px;
  -webkit-animation: rotate-center 0.6s ease-in infinite both;
  animation: rotate-center 0.6s ease-in infinite both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*
* Global Styles goes here
*/
#page_container_outer {
  height: 100vh;
  overflow: hidden;
}

.page_inner_container {
  width: 100%;
  display: block;
  overflow: hidden;
}

#page_container {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
}
#page_container #page_header {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_container #page_content {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
  height: 100%;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
}
#page_container #page_content:after {
  content: "\e905";
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(43, 73, 94, 0.1);
  font-family: "empyrean_icons";
  font-size: 200px;
  z-index: -1;
}
#page_container #page_content.no_logo:after {
  display: none;
}
#page_container #page_content #page_content_inner {
  padding: 0px;
}
#page_container #page_content #page_content_inner .input-structure {
  height: calc(100vh - 153px);
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
}
#page_container #page_content #page_content_inner .input-structure #results {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
}
#page_container #page_footer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

a.text_link {
  cursor: pointer;
}

input,
button,
textarea {
  font-size: 1rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn_normal, .notification-banner-message .banner-actions a,
.notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions button, .btn_small, .success-button {
  width: auto;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 5px 10px;
  overflow: visible;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 0px solid;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.76rem;
}
.btn_normal:hover, .notification-banner-message .banner-actions a:hover,
.notification-banner-message .banner-actions .link:hover,
.notification-banner-message .banner-actions .banner-action-button:hover,
.notification-banner-message .banner-actions button:hover, .btn_small:hover, .success-button:hover, .btn_normal:focus, .notification-banner-message .banner-actions a:focus,
.notification-banner-message .banner-actions .link:focus,
.notification-banner-message .banner-actions .banner-action-button:focus,
.notification-banner-message .banner-actions button:focus, .btn_small:focus, .success-button:focus {
  color: #51738b;
}
.btn_normal:disabled, .notification-banner-message .banner-actions a:disabled,
.notification-banner-message .banner-actions .link:disabled,
.notification-banner-message .banner-actions .banner-action-button:disabled,
.notification-banner-message .banner-actions button:disabled, .btn_small:disabled, .success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal:disabled:hover, .notification-banner-message .banner-actions a:disabled:hover,
.notification-banner-message .banner-actions .link:disabled:hover,
.notification-banner-message .banner-actions .banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button:disabled:hover, .btn_small:disabled:hover, .success-button:disabled:hover, .btn_normal:disabled:focus, .notification-banner-message .banner-actions a:disabled:focus,
.notification-banner-message .banner-actions .link:disabled:focus,
.notification-banner-message .banner-actions .banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button:disabled:focus, .btn_small:disabled:focus, .success-button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_green, .notification-banner-message .banner-actions a.btn_green,
.notification-banner-message .banner-actions .btn_green.link,
.notification-banner-message .banner-actions .btn_green.banner-action-button,
.notification-banner-message .banner-actions button.btn_green, .btn_green.btn_small, .success-button {
  background-color: #72bf44;
  color: #ffffff;
}
.btn_normal.btn_green:focus, .notification-banner-message .banner-actions a.btn_green:focus,
.notification-banner-message .banner-actions .btn_green.link:focus,
.notification-banner-message .banner-actions .btn_green.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_green:focus, .btn_green.btn_small:focus, .success-button:focus, .btn_normal.btn_green:hover, .notification-banner-message .banner-actions a.btn_green:hover,
.notification-banner-message .banner-actions .btn_green.link:hover,
.notification-banner-message .banner-actions .btn_green.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_green:hover, .btn_green.btn_small:hover, .success-button:hover {
  background-color: #5b9b35;
  color: #ffffff;
}
.btn_normal.btn_green:disabled, .notification-banner-message .banner-actions a.btn_green:disabled,
.notification-banner-message .banner-actions .btn_green.link:disabled,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_green:disabled, .btn_green.btn_small:disabled, .success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_green:disabled:hover, .notification-banner-message .banner-actions a.btn_green:disabled:hover,
.notification-banner-message .banner-actions .btn_green.link:disabled:hover,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_green:disabled:hover, .btn_green.btn_small:disabled:hover, .success-button:disabled:hover, .btn_normal.btn_green:disabled:focus, .notification-banner-message .banner-actions a.btn_green:disabled:focus,
.notification-banner-message .banner-actions .btn_green.link:disabled:focus,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_green:disabled:focus, .btn_green.btn_small:disabled:focus, .success-button:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_blue, .notification-banner-message .banner-actions a.btn_blue,
.notification-banner-message .banner-actions .btn_blue.link,
.notification-banner-message .banner-actions .btn_blue.banner-action-button,
.notification-banner-message .banner-actions button.btn_blue, .btn_blue.btn_small, .btn_blue.success-button {
  background-color: #51738b;
  color: #ffffff;
}
.btn_normal.btn_blue:focus, .notification-banner-message .banner-actions a.btn_blue:focus,
.notification-banner-message .banner-actions .btn_blue.link:focus,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_blue:focus, .btn_blue.btn_small:focus, .btn_blue.success-button:focus, .btn_normal.btn_blue:hover, .notification-banner-message .banner-actions a.btn_blue:hover,
.notification-banner-message .banner-actions .btn_blue.link:hover,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_blue:hover, .btn_blue.btn_small:hover, .btn_blue.success-button:hover {
  background-color: #3e586b;
  color: #ffffff;
}
.btn_normal.btn_blue:disabled, .notification-banner-message .banner-actions a.btn_blue:disabled,
.notification-banner-message .banner-actions .btn_blue.link:disabled,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_blue:disabled, .btn_blue.btn_small:disabled, .btn_blue.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_blue:disabled:hover, .notification-banner-message .banner-actions a.btn_blue:disabled:hover,
.notification-banner-message .banner-actions .btn_blue.link:disabled:hover,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_blue:disabled:hover, .btn_blue.btn_small:disabled:hover, .btn_blue.success-button:disabled:hover, .btn_normal.btn_blue:disabled:focus, .notification-banner-message .banner-actions a.btn_blue:disabled:focus,
.notification-banner-message .banner-actions .btn_blue.link:disabled:focus,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_blue:disabled:focus, .btn_blue.btn_small:disabled:focus, .btn_blue.success-button:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_white, .notification-banner-message .banner-actions a.btn_white,
.notification-banner-message .banner-actions .btn_white.link,
.notification-banner-message .banner-actions .btn_white.banner-action-button,
.notification-banner-message .banner-actions button.btn_white, .btn_white.btn_small, .btn_white.success-button {
  background-color: transparent;
  color: #ffffff;
}
.btn_normal.btn_white:focus, .notification-banner-message .banner-actions a.btn_white:focus,
.notification-banner-message .banner-actions .btn_white.link:focus,
.notification-banner-message .banner-actions .btn_white.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_white:focus, .btn_white.btn_small:focus, .btn_white.success-button:focus, .btn_normal.btn_white:hover, .notification-banner-message .banner-actions a.btn_white:hover,
.notification-banner-message .banner-actions .btn_white.link:hover,
.notification-banner-message .banner-actions .btn_white.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_white:hover, .btn_white.btn_small:hover, .btn_white.success-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.btn_normal.btn_white:disabled, .notification-banner-message .banner-actions a.btn_white:disabled,
.notification-banner-message .banner-actions .btn_white.link:disabled,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_white:disabled, .btn_white.btn_small:disabled, .btn_white.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_white:disabled:hover, .notification-banner-message .banner-actions a.btn_white:disabled:hover,
.notification-banner-message .banner-actions .btn_white.link:disabled:hover,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_white:disabled:hover, .btn_white.btn_small:disabled:hover, .btn_white.success-button:disabled:hover, .btn_normal.btn_white:disabled:focus, .notification-banner-message .banner-actions a.btn_white:disabled:focus,
.notification-banner-message .banner-actions .btn_white.link:disabled:focus,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_white:disabled:focus, .btn_white.btn_small:disabled:focus, .btn_white.success-button:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_notification, .notification-banner-message .banner-actions a.btn_notification,
.notification-banner-message .banner-actions .btn_notification.link,
.notification-banner-message .banner-actions .btn_notification.banner-action-button,
.notification-banner-message .banner-actions button.btn_notification, .btn_notification.btn_small, .btn_notification.success-button {
  background-color: #ff9d00;
  color: #ffffff;
}
.btn_normal.btn_notification:focus, .notification-banner-message .banner-actions a.btn_notification:focus,
.notification-banner-message .banner-actions .btn_notification.link:focus,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_notification:focus, .btn_notification.btn_small:focus, .btn_notification.success-button:focus, .btn_normal.btn_notification:hover, .notification-banner-message .banner-actions a.btn_notification:hover,
.notification-banner-message .banner-actions .btn_notification.link:hover,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_notification:hover, .btn_notification.btn_small:hover, .btn_notification.success-button:hover {
  background-color: #cc7e00;
  color: #ffffff;
}
.btn_normal.btn_notification:disabled, .notification-banner-message .banner-actions a.btn_notification:disabled,
.notification-banner-message .banner-actions .btn_notification.link:disabled,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_notification:disabled, .btn_notification.btn_small:disabled, .btn_notification.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_notification:disabled:hover, .notification-banner-message .banner-actions a.btn_notification:disabled:hover,
.notification-banner-message .banner-actions .btn_notification.link:disabled:hover,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_notification:disabled:hover, .btn_notification.btn_small:disabled:hover, .btn_notification.success-button:disabled:hover, .btn_normal.btn_notification:disabled:focus, .notification-banner-message .banner-actions a.btn_notification:disabled:focus,
.notification-banner-message .banner-actions .btn_notification.link:disabled:focus,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_notification:disabled:focus, .btn_notification.btn_small:disabled:focus, .btn_notification.success-button:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_outline_white, .notification-banner-message .banner-actions a.btn_outline_white,
.notification-banner-message .banner-actions .btn_outline_white.link,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button,
.notification-banner-message .banner-actions button.btn_outline_white, .btn_outline_white.btn_small, .btn_outline_white.success-button {
  background-color: transparent;
  color: #ffffff;
  padding: 4px 9px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_outline_white:focus, .notification-banner-message .banner-actions a.btn_outline_white:focus,
.notification-banner-message .banner-actions .btn_outline_white.link:focus,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_outline_white:focus, .btn_outline_white.btn_small:focus, .btn_outline_white.success-button:focus, .btn_normal.btn_outline_white:hover, .notification-banner-message .banner-actions a.btn_outline_white:hover,
.notification-banner-message .banner-actions .btn_outline_white.link:hover,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_outline_white:hover, .btn_outline_white.btn_small:hover, .btn_outline_white.success-button:hover {
  background-color: #ffffff;
  color: #505050;
  border: 0px solid #ffffff;
  padding: 5px 10px;
}
.btn_normal.btn_outline_white.btn_no_border, .notification-banner-message .banner-actions a.btn_outline_white.btn_no_border,
.notification-banner-message .banner-actions .btn_outline_white.btn_no_border.link,
.notification-banner-message .banner-actions .btn_outline_white.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_outline_white.btn_no_border, .btn_outline_white.btn_no_border.btn_small, .btn_outline_white.btn_no_border.success-button {
  padding: 5px 10px;
}
.btn_normal.btn_outline_white:disabled, .notification-banner-message .banner-actions a.btn_outline_white:disabled,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_outline_white:disabled, .btn_outline_white.btn_small:disabled, .btn_outline_white.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_outline_white:disabled:hover, .notification-banner-message .banner-actions a.btn_outline_white:disabled:hover,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled:hover,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_outline_white:disabled:hover, .btn_outline_white.btn_small:disabled:hover, .btn_outline_white.success-button:disabled:hover, .btn_normal.btn_outline_white:disabled:focus, .notification-banner-message .banner-actions a.btn_outline_white:disabled:focus,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled:focus,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_outline_white:disabled:focus, .btn_outline_white.btn_small:disabled:focus, .btn_outline_white.success-button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_negative, .notification-banner-message .banner-actions a.btn_negative,
.notification-banner-message .banner-actions .btn_negative.link,
.notification-banner-message .banner-actions .btn_negative.banner-action-button,
.notification-banner-message .banner-actions button.btn_negative, .btn_negative.btn_small, .btn_negative.success-button {
  background-color: transparent;
  color: #2b495e;
  padding: 4px 9px;
  border: 1px solid #2b495e;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_negative:focus, .notification-banner-message .banner-actions a.btn_negative:focus,
.notification-banner-message .banner-actions .btn_negative.link:focus,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_negative:focus, .btn_negative.btn_small:focus, .btn_negative.success-button:focus, .btn_normal.btn_negative:hover, .notification-banner-message .banner-actions a.btn_negative:hover,
.notification-banner-message .banner-actions .btn_negative.link:hover,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_negative:hover, .btn_negative.btn_small:hover, .btn_negative.success-button:hover {
  background-color: #2b495e;
  color: #ffffff;
  border: 0px solid #2b495e;
  padding: 5px 10px;
}
.btn_normal.btn_negative:disabled, .notification-banner-message .banner-actions a.btn_negative:disabled,
.notification-banner-message .banner-actions .btn_negative.link:disabled,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_negative:disabled, .btn_negative.btn_small:disabled, .btn_negative.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_negative:disabled:hover, .notification-banner-message .banner-actions a.btn_negative:disabled:hover,
.notification-banner-message .banner-actions .btn_negative.link:disabled:hover,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_negative:disabled:hover, .btn_negative.btn_small:disabled:hover, .btn_negative.success-button:disabled:hover, .btn_normal.btn_negative:disabled:focus, .notification-banner-message .banner-actions a.btn_negative:disabled:focus,
.notification-banner-message .banner-actions .btn_negative.link:disabled:focus,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_negative:disabled:focus, .btn_negative.btn_small:disabled:focus, .btn_negative.success-button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_negative.no_focus:focus, .notification-banner-message .banner-actions a.btn_negative.no_focus:focus,
.notification-banner-message .banner-actions .btn_negative.no_focus.link:focus,
.notification-banner-message .banner-actions .btn_negative.no_focus.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_negative.no_focus:focus, .btn_negative.no_focus.btn_small:focus, .btn_negative.no_focus.success-button:focus {
  background-color: transparent;
  color: #2b495e;
  padding: 4px 9px;
  border: 1px solid #2b495e;
}
.btn_normal.btn_green_outline, .notification-banner-message .banner-actions a.btn_green_outline,
.notification-banner-message .banner-actions .btn_green_outline.link,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button,
.notification-banner-message .banner-actions button.btn_green_outline, .btn_green_outline.btn_small, .btn_green_outline.success-button {
  background-color: transparent;
  color: #72bf44;
  padding: 4px 9px;
  border: 1px solid #72bf44;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_green_outline:focus, .notification-banner-message .banner-actions a.btn_green_outline:focus,
.notification-banner-message .banner-actions .btn_green_outline.link:focus,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_green_outline:focus, .btn_green_outline.btn_small:focus, .btn_green_outline.success-button:focus, .btn_normal.btn_green_outline:hover, .notification-banner-message .banner-actions a.btn_green_outline:hover,
.notification-banner-message .banner-actions .btn_green_outline.link:hover,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_green_outline:hover, .btn_green_outline.btn_small:hover, .btn_green_outline.success-button:hover {
  background-color: #72bf44;
  color: #ffffff;
  border: 0px solid #72bf44;
  padding: 5px 10px;
}
.btn_normal.btn_green_outline:disabled, .notification-banner-message .banner-actions a.btn_green_outline:disabled,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_green_outline:disabled, .btn_green_outline.btn_small:disabled, .btn_green_outline.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_green_outline:disabled:hover, .notification-banner-message .banner-actions a.btn_green_outline:disabled:hover,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled:hover,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_green_outline:disabled:hover, .btn_green_outline.btn_small:disabled:hover, .btn_green_outline.success-button:disabled:hover, .btn_normal.btn_green_outline:disabled:focus, .notification-banner-message .banner-actions a.btn_green_outline:disabled:focus,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled:focus,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_green_outline:disabled:focus, .btn_green_outline.btn_small:disabled:focus, .btn_green_outline.success-button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_add_item, .notification-banner-message .banner-actions a.btn_add_item,
.notification-banner-message .banner-actions .btn_add_item.link,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button,
.notification-banner-message .banner-actions button.btn_add_item, .btn_add_item.btn_small, .btn_add_item.success-button {
  background-color: transparent;
  color: #2b495e;
  padding: 5px 0px;
  border: 0px solid #2b495e;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_add_item:focus, .notification-banner-message .banner-actions a.btn_add_item:focus,
.notification-banner-message .banner-actions .btn_add_item.link:focus,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_add_item:focus, .btn_add_item.btn_small:focus, .btn_add_item.success-button:focus, .btn_normal.btn_add_item:hover, .notification-banner-message .banner-actions a.btn_add_item:hover,
.notification-banner-message .banner-actions .btn_add_item.link:hover,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_add_item:hover, .btn_add_item.btn_small:hover, .btn_add_item.success-button:hover {
  background-color: transparent;
  color: #72bf44;
  border: 0px solid #72bf44;
  padding: 5px 0px;
}
.btn_normal.btn_add_item:disabled, .notification-banner-message .banner-actions a.btn_add_item:disabled,
.notification-banner-message .banner-actions .btn_add_item.link:disabled,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_add_item:disabled, .btn_add_item.btn_small:disabled, .btn_add_item.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0) !important;
  border: 0px solid !important;
  padding: 5px 0px;
}
.btn_normal.btn_add_item:disabled:hover, .notification-banner-message .banner-actions a.btn_add_item:disabled:hover,
.notification-banner-message .banner-actions .btn_add_item.link:disabled:hover,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_add_item:disabled:hover, .btn_add_item.btn_small:disabled:hover, .btn_add_item.success-button:disabled:hover, .btn_normal.btn_add_item:disabled:focus, .notification-banner-message .banner-actions a.btn_add_item:disabled:focus,
.notification-banner-message .banner-actions .btn_add_item.link:disabled:focus,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_add_item:disabled:focus, .btn_add_item.btn_small:disabled:focus, .btn_add_item.success-button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0) !important;
  color: #cccccc !important;
}
.btn_normal.btn_icon_only, .notification-banner-message .banner-actions a.btn_icon_only,
.notification-banner-message .banner-actions .btn_icon_only.link,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_only, .btn_icon_only.btn_small, .btn_icon_only.success-button {
  padding: 4px 4px;
}
.btn_normal.btn_icon_only.btn_no_border, .notification-banner-message .banner-actions a.btn_icon_only.btn_no_border,
.notification-banner-message .banner-actions .btn_icon_only.btn_no_border.link,
.notification-banner-message .banner-actions .btn_icon_only.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_only.btn_no_border, .btn_icon_only.btn_no_border.btn_small, .btn_icon_only.btn_no_border.success-button {
  padding: 5px 5px;
}
.btn_normal.btn_icon_only.btn_icon_small .btn_icon span, .notification-banner-message .banner-actions a.btn_icon_only.btn_icon_small .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.link .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.banner-action-button .btn_icon span,
.notification-banner-message .banner-actions button.btn_icon_only.btn_icon_small .btn_icon span, .btn_icon_only.btn_icon_small.btn_small .btn_icon span, .btn_icon_only.btn_icon_small.success-button .btn_icon span,
.btn_normal.btn_icon_only.btn_icon_small .btn_status span,
.notification-banner-message .banner-actions a.btn_icon_only.btn_icon_small .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.link .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.banner-action-button .btn_status span,
.notification-banner-message .banner-actions button.btn_icon_only.btn_icon_small .btn_status span,
.btn_icon_only.btn_icon_small.btn_small .btn_status span,
.btn_icon_only.btn_icon_small.success-button .btn_status span {
  font-size: 12px;
}
.btn_normal.btn_icon_only .btn_icon, .notification-banner-message .banner-actions a.btn_icon_only .btn_icon,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_icon,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_icon,
.notification-banner-message .banner-actions button.btn_icon_only .btn_icon, .btn_icon_only.btn_small .btn_icon, .btn_icon_only.success-button .btn_icon,
.btn_normal.btn_icon_only .btn_status,
.notification-banner-message .banner-actions a.btn_icon_only .btn_status,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_status,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_status,
.notification-banner-message .banner-actions button.btn_icon_only .btn_status,
.btn_icon_only.btn_small .btn_status,
.btn_icon_only.success-button .btn_status {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  display: block;
  vertical-align: text-bottom;
}
.btn_normal.btn_icon_only .btn_icon span, .notification-banner-message .banner-actions a.btn_icon_only .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_icon span,
.notification-banner-message .banner-actions button.btn_icon_only .btn_icon span, .btn_icon_only.btn_small .btn_icon span, .btn_icon_only.success-button .btn_icon span,
.btn_normal.btn_icon_only .btn_status span,
.notification-banner-message .banner-actions a.btn_icon_only .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_status span,
.notification-banner-message .banner-actions button.btn_icon_only .btn_status span,
.btn_icon_only.btn_small .btn_status span,
.btn_icon_only.success-button .btn_status span {
  display: block;
  line-height: 20px;
  width: 20px;
  font-size: 16px;
}
.btn_normal.btn_icon_small, .notification-banner-message .banner-actions a.btn_icon_small,
.notification-banner-message .banner-actions .btn_icon_small.link,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_small, .btn_icon_small.btn_small, .btn_icon_small.success-button {
  padding: 4px 4px;
}
.btn_normal.btn_icon_small.btn_no_border, .notification-banner-message .banner-actions a.btn_icon_small.btn_no_border,
.notification-banner-message .banner-actions .btn_icon_small.btn_no_border.link,
.notification-banner-message .banner-actions .btn_icon_small.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_small.btn_no_border, .btn_icon_small.btn_no_border.btn_small, .btn_icon_small.btn_no_border.success-button {
  padding: 5px 5px;
}
.btn_normal.btn_icon_small .btn_icon, .notification-banner-message .banner-actions a.btn_icon_small .btn_icon,
.notification-banner-message .banner-actions .btn_icon_small.link .btn_icon,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button .btn_icon,
.notification-banner-message .banner-actions button.btn_icon_small .btn_icon, .btn_icon_small.btn_small .btn_icon, .btn_icon_small.success-button .btn_icon,
.btn_normal.btn_icon_small .btn_status,
.notification-banner-message .banner-actions a.btn_icon_small .btn_status,
.notification-banner-message .banner-actions .btn_icon_small.link .btn_status,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button .btn_status,
.notification-banner-message .banner-actions button.btn_icon_small .btn_status,
.btn_icon_small.btn_small .btn_status,
.btn_icon_small.success-button .btn_status {
  font-size: 12px;
  height: 20px;
  width: 20px;
  text-align: center;
  display: block;
}
.btn_normal.btn_wide, .notification-banner-message .banner-actions a.btn_wide,
.notification-banner-message .banner-actions .btn_wide.link,
.notification-banner-message .banner-actions .btn_wide.banner-action-button,
.notification-banner-message .banner-actions button.btn_wide, .btn_wide.btn_small, .btn_wide.success-button {
  min-width: 100px;
}
.btn_normal.btn_select, .notification-banner-message .banner-actions a.btn_select,
.notification-banner-message .banner-actions .btn_select.link,
.notification-banner-message .banner-actions .btn_select.banner-action-button,
.notification-banner-message .banner-actions button.btn_select, .btn_select.btn_small, .btn_select.success-button {
  min-width: 100px;
  border: 1px solid #dfe0e1;
  padding: 4px 9px;
}
.btn_normal.btn_form_input, .notification-banner-message .banner-actions a.btn_form_input,
.notification-banner-message .banner-actions .btn_form_input.link,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button,
.notification-banner-message .banner-actions button.btn_form_input, .btn_form_input.btn_small, .btn_form_input.success-button {
  border: 1px solid #ababab;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  padding: 4px;
  background-color: #ffffff;
}
.btn_normal.btn_form_input:hover, .notification-banner-message .banner-actions a.btn_form_input:hover,
.notification-banner-message .banner-actions .btn_form_input.link:hover,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_form_input:hover, .btn_form_input.btn_small:hover, .btn_form_input.success-button:hover, .btn_normal.btn_form_input:focus, .notification-banner-message .banner-actions a.btn_form_input:focus,
.notification-banner-message .banner-actions .btn_form_input.link:focus,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_form_input:focus, .btn_form_input.btn_small:focus, .btn_form_input.success-button:focus {
  color: #72bf44;
  border-color: #72bf44;
}
.btn_normal.btn_no_border, .notification-banner-message .banner-actions a.btn_no_border,
.notification-banner-message .banner-actions .btn_no_border.link,
.notification-banner-message .banner-actions .btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_border, .btn_no_border.btn_small, .btn_no_border.success-button {
  border: 0 solid !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn_normal.btn_no_padding, .notification-banner-message .banner-actions a.btn_no_padding,
.notification-banner-message .banner-actions .btn_no_padding.link,
.notification-banner-message .banner-actions .btn_no_padding.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_padding, .btn_no_padding.btn_small, .btn_no_padding.success-button {
  padding: 4px 0px;
}
.btn_normal.btn_no_padding.btn_no_border, .notification-banner-message .banner-actions a.btn_no_padding.btn_no_border,
.notification-banner-message .banner-actions .btn_no_padding.btn_no_border.link,
.notification-banner-message .banner-actions .btn_no_padding.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_padding.btn_no_border, .btn_no_padding.btn_no_border.btn_small, .btn_no_padding.btn_no_border.success-button {
  padding: 5px 0;
}
.btn_normal.btn_extra_padding, .notification-banner-message .banner-actions a.btn_extra_padding,
.notification-banner-message .banner-actions .btn_extra_padding.link,
.notification-banner-message .banner-actions .btn_extra_padding.banner-action-button,
.notification-banner-message .banner-actions button.btn_extra_padding, .btn_extra_padding.btn_small, .btn_extra_padding.success-button {
  padding: 4px 9px;
}
.btn_normal.btn_extra_padding.btn_no_border, .notification-banner-message .banner-actions a.btn_extra_padding.btn_no_border,
.notification-banner-message .banner-actions .btn_extra_padding.btn_no_border.link,
.notification-banner-message .banner-actions .btn_extra_padding.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_extra_padding.btn_no_border, .btn_extra_padding.btn_no_border.btn_small, .btn_extra_padding.btn_no_border.success-button {
  padding: 5px 10px;
}
.btn_normal.btn_offset_margin, .notification-banner-message .banner-actions a.btn_offset_margin,
.notification-banner-message .banner-actions .btn_offset_margin.link,
.notification-banner-message .banner-actions .btn_offset_margin.banner-action-button,
.notification-banner-message .banner-actions button.btn_offset_margin, .btn_offset_margin.btn_small, .btn_offset_margin.success-button {
  margin: -5px 0;
}
.btn_normal.btn_min_width, .notification-banner-message .banner-actions a.btn_min_width,
.notification-banner-message .banner-actions .btn_min_width.link,
.notification-banner-message .banner-actions .btn_min_width.banner-action-button,
.notification-banner-message .banner-actions button.btn_min_width, .btn_min_width.btn_small, .btn_min_width.success-button {
  display: inline-block;
  width: 80px;
}
.btn_normal.btn_table, .notification-banner-message .banner-actions a.btn_table,
.notification-banner-message .banner-actions .btn_table.link,
.notification-banner-message .banner-actions .btn_table.banner-action-button,
.notification-banner-message .banner-actions button.btn_table, .btn_table.btn_small, .btn_table.success-button {
  margin-top: -5px;
  margin-bottom: -5px;
}
.btn_normal.btn_hidden, .notification-banner-message .banner-actions a.btn_hidden,
.notification-banner-message .banner-actions .btn_hidden.link,
.notification-banner-message .banner-actions .btn_hidden.banner-action-button,
.notification-banner-message .banner-actions button.btn_hidden, .btn_hidden.btn_small, .btn_hidden.success-button {
  display: none;
}
.btn_normal > .btn_text, .notification-banner-message .banner-actions a > .btn_text,
.notification-banner-message .banner-actions .link > .btn_text,
.notification-banner-message .banner-actions .banner-action-button > .btn_text,
.notification-banner-message .banner-actions button > .btn_text, .btn_small > .btn_text, .success-button > .btn_text {
  line-height: 20px;
  display: inline-block;
}
.btn_normal > .btn_text.hidden_text, .notification-banner-message .banner-actions a > .btn_text.hidden_text,
.notification-banner-message .banner-actions .link > .btn_text.hidden_text,
.notification-banner-message .banner-actions .banner-action-button > .btn_text.hidden_text,
.notification-banner-message .banner-actions button > .btn_text.hidden_text, .btn_small > .btn_text.hidden_text, .success-button > .btn_text.hidden_text {
  position: absolute;
  font-size: 0 !important;
}
.btn_normal > .btn_icon, .notification-banner-message .banner-actions a > .btn_icon,
.notification-banner-message .banner-actions .link > .btn_icon,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon,
.notification-banner-message .banner-actions button > .btn_icon, .btn_small > .btn_icon, .success-button > .btn_icon,
.btn_normal > .btn_status,
.notification-banner-message .banner-actions a > .btn_status,
.notification-banner-message .banner-actions .link > .btn_status,
.notification-banner-message .banner-actions .banner-action-button > .btn_status,
.notification-banner-message .banner-actions button > .btn_status,
.btn_small > .btn_status,
.success-button > .btn_status {
  position: relative;
  pointer-events: none;
  display: inline-block;
  vertical-align: text-bottom;
  color: inherit;
  line-height: 20px;
}
.btn_normal > .btn_icon span, .notification-banner-message .banner-actions a > .btn_icon span,
.notification-banner-message .banner-actions .link > .btn_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon span,
.notification-banner-message .banner-actions button > .btn_icon span, .btn_small > .btn_icon span, .success-button > .btn_icon span,
.btn_normal > .btn_status span,
.notification-banner-message .banner-actions a > .btn_status span,
.notification-banner-message .banner-actions .link > .btn_status span,
.notification-banner-message .banner-actions .banner-action-button > .btn_status span,
.notification-banner-message .banner-actions button > .btn_status span,
.btn_small > .btn_status span,
.success-button > .btn_status span {
  font-size: 16px;
  display: block;
}
.btn_normal > .btn_icon.big_icon span, .notification-banner-message .banner-actions a > .btn_icon.big_icon span,
.notification-banner-message .banner-actions .link > .btn_icon.big_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon.big_icon span,
.notification-banner-message .banner-actions button > .btn_icon.big_icon span, .btn_small > .btn_icon.big_icon span, .success-button > .btn_icon.big_icon span,
.btn_normal > .btn_status.big_icon span,
.notification-banner-message .banner-actions a > .btn_status.big_icon span,
.notification-banner-message .banner-actions .link > .btn_status.big_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_status.big_icon span,
.notification-banner-message .banner-actions button > .btn_status.big_icon span,
.btn_small > .btn_status.big_icon span,
.success-button > .btn_status.big_icon span {
  font-size: 20px;
}
.btn_normal > .btn_status.status_invalid, .notification-banner-message .banner-actions a > .btn_status.status_invalid,
.notification-banner-message .banner-actions .link > .btn_status.status_invalid,
.notification-banner-message .banner-actions .banner-action-button > .btn_status.status_invalid,
.notification-banner-message .banner-actions button > .btn_status.status_invalid, .btn_small > .btn_status.status_invalid, .success-button > .btn_status.status_invalid {
  color: #ea5441;
}
.btn_normal > .btn_helper, .notification-banner-message .banner-actions a > .btn_helper,
.notification-banner-message .banner-actions .link > .btn_helper,
.notification-banner-message .banner-actions .banner-action-button > .btn_helper,
.notification-banner-message .banner-actions button > .btn_helper, .btn_small > .btn_helper, .success-button > .btn_helper {
  float: right;
  margin-left: 5px;
  line-height: 20px;
}
.btn_normal > .btn_helper span, .notification-banner-message .banner-actions a > .btn_helper span,
.notification-banner-message .banner-actions .link > .btn_helper span,
.notification-banner-message .banner-actions .banner-action-button > .btn_helper span,
.notification-banner-message .banner-actions button > .btn_helper span, .btn_small > .btn_helper span, .success-button > .btn_helper span {
  font-size: 10px;
  display: block;
  line-height: 20px;
}
.btn_normal > .btn_icon_arrow, .notification-banner-message .banner-actions a > .btn_icon_arrow,
.notification-banner-message .banner-actions .link > .btn_icon_arrow,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon_arrow,
.notification-banner-message .banner-actions button > .btn_icon_arrow, .btn_small > .btn_icon_arrow, .success-button > .btn_icon_arrow {
  float: right;
  margin-left: 5px;
  line-height: 20px;
}
.btn_normal > .btn_icon_arrow span, .notification-banner-message .banner-actions a > .btn_icon_arrow span,
.notification-banner-message .banner-actions .link > .btn_icon_arrow span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon_arrow span,
.notification-banner-message .banner-actions button > .btn_icon_arrow span, .btn_small > .btn_icon_arrow span, .success-button > .btn_icon_arrow span {
  font-size: 16px;
  display: block;
  line-height: 20px;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.btn_group .btn_group_left {
  float: left;
  padding: 4px;
  border-right: 0px solid !important;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
.btn_group .btn_group_left.btn_no_border {
  padding: 5px;
}
.btn_group .btn_group_left.btn_grey {
  padding: 4px;
  border: 1px solid #eceef3 !important;
}
.btn_group .btn_group_left.btn_grey:focus, .btn_group .btn_group_left.btn_grey:hover {
  border: 1px solid #525b6b !important;
}
.btn_group .btn_group_left.btn_grey:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: #f5f6f7 !important;
  border-color: #f5f6f7 !important;
}
.btn_group .btn_group_left.btn_grey:disabled:hover, .btn_group .btn_group_left.btn_grey:disabled:focus {
  border-color: #f5f6f7 !important;
  background-color: #f5f6f7 !important;
  color: #cccccc !important;
}
.btn_group .btn_group_right {
  float: left;
  padding: 4px;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
.btn_group .btn_group_right.btn_no_border {
  padding: 5px;
}
.btn_group .btn_group_right.btn_grey {
  padding: 4px;
  border: 1px solid #eceef3 !important;
}
.btn_group .btn_group_right.btn_grey:focus, .btn_group .btn_group_right.btn_grey:hover {
  border: 1px solid #525b6b !important;
}
.btn_group .btn_group_right.btn_grey:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: #f5f6f7 !important;
  border-color: #f5f6f7 !important;
}
.btn_group .btn_group_right.btn_grey:disabled:hover, .btn_group .btn_group_right.btn_grey:disabled:focus {
  border-color: #f5f6f7 !important;
  background-color: #f5f6f7 !important;
  color: #cccccc !important;
}

.btn_small {
  font-size: 10px;
  padding: 0 !important;
}

fieldset.form_row {
  margin-bottom: 5px;
  position: relative;
}
fieldset.form_row.no_padding {
  margin-bottom: 0;
}
fieldset.form_row.inline {
  position: relative;
}
fieldset.form_row.inline legend {
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
}
fieldset.form_row.inline > .form_group {
  width: 65%;
  margin-left: 35%;
}
fieldset.form_row.inline.auto_width {
  display: inline-block;
}
fieldset.form_row.inline.auto_width legend {
  width: auto;
  margin-right: 10px;
  position: relative;
  left: auto;
  top: auto;
}
fieldset.form_row.inline.auto_width > .form_group {
  display: inline-block;
  width: auto;
  margin-left: auto;
  white-space: nowrap;
}
fieldset.form_row.inline.split_50 > .data_label {
  float: left;
  width: 50%;
}
fieldset.form_row.inline.split_50 > .data_value {
  float: left;
  width: 50%;
}
fieldset.form_row.layout_horizontal > .form_group > .form_row {
  width: auto;
  display: inline-block;
  float: left;
  margin-right: 10px;
}
fieldset.form_row.layout_horizontal > .form_group > .form_row:last-of-type {
  margin-right: 0;
}
fieldset.form_row.layout_stacked > .form_group.split_2 > .form_row {
  width: 50%;
  float: left;
}
fieldset.form_row.layout_stacked > .form_group.split_2 > .form_row label {
  display: block;
}
fieldset.form_row.layout_stacked > .form_group > .form_row {
  width: auto;
  display: block;
}
fieldset.form_row .form_row {
  margin-bottom: 0;
}

.form_row {
  margin-bottom: 5px;
  position: relative;
}
.form_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.form_row.no_padding {
  margin-bottom: 0;
}
.form_row.align_center {
  text-align: center;
}
.form_row.align_to_label {
  padding-left: 35%;
}
.form_row.align_to_label_50 {
  padding-left: 50%;
}
.form_row.inline > .form_label {
  float: left;
  width: 35%;
}
.form_row.inline > .form_input {
  float: left;
  width: 65%;
}
.form_row.inline.split_50 > .form_label {
  float: left;
  width: 50%;
}
.form_row.inline.split_50 > .form_input {
  float: left;
  width: 50%;
}
.form_row > .form_label {
  display: block;
}
.form_row > .form_label.hidden_label {
  position: absolute;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.form_row > .form_label.hidden_label label {
  padding: 0;
}
.form_row > .form_label label {
  line-height: 20px;
  padding: 5px 5px 5px 0;
  display: block;
}
.form_row > .form_label label .label_right {
  float: right;
}
.form_row > .form_input {
  display: block;
}
.form_row .form_input.readonly {
  position: relative;
}
.form_row .form_input.readonly .inputbox {
  padding-right: 25px !important;
}
.form_row .form_input.input_has_button {
  position: relative;
}
.form_row .form_input.input_has_button .inputbox {
  padding-right: 35px !important;
}
.form_row .form_input.input_has_button .form_input_button {
  position: absolute;
  right: 0;
  top: 0;
}
.form_row .form_input.input_has_helper {
  position: relative;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form_row .form_input.input_has_helper .form_input_helper {
  position: absolute;
  line-height: 20px;
  top: 5px;
  right: 0;
  min-width: 25px;
  text-align: left;
}
.form_row .form_input .inputbox_inline_text {
  margin-left: 5px;
  display: inline-block;
}
.form_row .form_error {
  display: block;
  color: #ea5441;
  line-height: 20px;
  padding: 5px 0;
}
.form_row.auto_width {
  display: inline-block;
}

.flex_group {
  display: flex;
  flex-direction: rows;
  -webkit-flex-direction: rows;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-top: 5px;
}
.flex_group.flex_end {
  justify-content: flex-end;
}
.flex_group:first-of-type {
  margin-top: 0;
}
.flex_group.flex_no_wrap {
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}
.flex_group .flex_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.flex_group .flex_row > .form_label {
  width: auto;
}
.flex_group .flex_row > .form_input {
  width: auto;
}
.flex_group .flex_row.flex_grow {
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
}
.flex_group .flex_row_action {
  margin-left: auto;
}

.form_helper_txt {
  line-height: 20px;
  padding: 5px 0;
  display: block;
}

.form_supplementary_text {
  line-height: 20px;
  padding: 5px 0;
  display: block;
  font-style: italic;
  color: #7e8c99;
}

/* Data Rows */
.data_row {
  display: block;
  margin-bottom: 5px;
  position: relative;
}
.data_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.data_row.no_padding {
  margin-bottom: 0;
}
.data_row.inline > .data_label {
  float: left;
  width: 35%;
}
.data_row.inline > .data_value {
  float: left;
  width: 65%;
}
.data_row.inline.split_50 > .data_label {
  float: left;
  width: 50%;
}
.data_row.inline.split_50 > .data_value {
  float: left;
  width: 50%;
}
.data_row > .data_label {
  display: block;
  line-height: 20px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.data_row > .data_label.hidden_label {
  position: absolute;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.data_row > .data_value {
  display: block;
  line-height: 20px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.data_row.mimic_form_row > .data_label {
  padding: 5px 0;
}
.data_row.mimic_form_row > .data_value {
  padding: 5px 0;
}

.panel_title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
}
.panel_title.inline {
  display: inline-block;
}

.login_title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
}

.context_title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
  color: #2b495e;
  text-transform: capitalize;
}

.dropdown_title {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 20px;
  padding: 5px 0;
  margin-bottom: 10px;
}

.notification_title {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 0.8rem;
}

#page_header {
  background-color: #2b495e;
  color: #ffffff;
}
#page_header .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_header .page_inner_container #header_left {
  float: left;
}
#page_header .page_inner_container #header_left #header_logo {
  float: left;
  line-height: 30px;
  margin-right: 20px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_icon {
  float: left;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_icon a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  line-height: 30px;
  font-size: 30px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_product {
  float: left;
  margin-left: 10px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_product span {
  display: block;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #ffffff;
}
#page_header .page_inner_container #header_left #header_create_simulation {
  float: left;
}
#page_header .page_inner_container #header_left #header_create_simulation button {
  margin-right: 5px;
  float: left;
}
#page_header .page_inner_container #header_left #header_create_simulation #save_status {
  float: left;
  margin-left: 10px;
  line-height: 20px;
  padding: 5px 0;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#page_header .page_inner_container #header_right {
  float: right;
}
#page_header .page_inner_container #header_right #header_model_data {
  float: left;
  margin-right: 10px;
}
#page_header .page_inner_container #header_right #header_model_structure {
  float: left;
  margin-right: 20px;
}
#page_header .page_inner_container #header_right #header_settings {
  float: left;
  margin-right: 20px;
}
#page_header .page_inner_container #header_right #header_quick_links {
  float: left;
  margin-right: 10px;
}
#page_header .page_inner_container #header_right #header_user {
  float: left;
}

.header33 {
  width: 50%;
}

#page_header_alt {
  text-align: center;
}
#page_header_alt .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_header_alt .page_inner_container #header_logo_center {
  margin: 40px auto 0;
  height: 100px;
}
#page_header_alt .page_inner_container #header_logo_center img {
  height: 100%;
}

#page_footer {
  background-color: #eceef3;
  color: #7e8c99;
}
#page_footer .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_footer .page_inner_container.no_padding {
  padding: 0;
}
#page_footer .page_inner_container .footer_text {
  text-align: center;
  line-height: 20px;
  padding: 5px;
}

.col_group:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.col_group .col_item {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  position: relative;
}
.col_group .col_item.no_padding {
  padding: 0;
}
.col_group.split_4 .col_item {
  width: 25%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .col_group.split_4 .col_item {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .col_group.split_4 .col_item {
    width: 100%;
  }
}
.col_group.split_4.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_4.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_4.max_height .col_item:nth-child(2) {
  left: 25%;
}
.col_group.split_4.max_height .col_item:nth-child(3) {
  left: 50%;
}
.col_group.split_4.max_height .col_item:nth-child(4) {
  left: 75%;
}
.col_group.split_3 .col_item {
  width: 33.3333333333%;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .col_group.split_3 .col_item {
    width: 33.3333333333%;
  }
}
@media (min-width: 0px) and (max-width: 767px), (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .col_group.split_3 .col_item {
    width: 100%;
  }
}
.col_group.split_3.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_3.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_3.max_height .col_item:nth-child(2) {
  left: 33.3333333333%;
}
.col_group.split_3.max_height .col_item:nth-child(3) {
  left: 66.6666666667%;
}
.col_group.split_2 .col_item {
  width: 50%;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .col_group.split_2 .col_item {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 767px), (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .col_group.split_2 .col_item {
    width: 100%;
  }
}
.col_group.split_2 .col_item.extra_space {
  padding-right: 15px;
}
.col_group.split_2 .col_item.extra_space:last-of-type {
  padding-right: 0;
  padding-left: 15px;
}
.col_group.split_2.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_2.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_2.max_height .col_item:nth-child(2) {
  left: 50%;
}
.col_group.max_height {
  position: fixed;
  left: 65px;
  top: 65px;
  bottom: 5px;
  right: 5px;
}
@media all and (-ms-high-contrast: none) {
  .column_content.column_content_inner.column_section {
    display: flex;
    flex-direction: column;
    min-height: 1px;
  } /* IE10 */
  *::-ms-backdrop,
  .column_content.column_content_inner.column_section {
    display: flex;
    flex-direction: column;
    min-height: 1px;
  } /* IE11 */
}
.grid_container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-flow: wrap;
  flex-direction: row;
  height: 100%;
  max-width: 1440px;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  -webkit-flex-flow: wrap;
  -webkit-flex-direction: row;
}
.grid_container.auto_height {
  height: auto;
}
.grid_container.fixed_height {
  height: 100%;
  display: flex;
}
@media (max-width: 1279px) {
  .grid_container.fixed_height {
    display: flex;
    height: 100%;
  }
}
@media (max-width: 1279px) {
  .grid_container {
    display: block;
    height: auto;
  }
}
.grid_container.columns_3 .col {
  width: calc(33.3333333333% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_3 .col {
    width: calc(100% - 20px);
  }
}
.grid_container.columns_2 .col {
  width: calc(50% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_2 .col {
    width: calc(100% - 20px);
  }
}
.grid_container.columns_1 .col {
  width: calc(100% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_1 .col {
    width: calc(100% - 20px);
  }
}
.grid_container .col {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px auto;
  height: 100%;
}
@media (max-width: 1279px) {
  .grid_container .col {
    width: inherit;
    margin: 10px auto;
  }
}

/****** Spreadsheet ********/
/********** Base **********/
/********** Colours **********/
/********** Pages **********/
/********** Modal **********/
/********** Button **********/
/******* Icons ********/
/******* Icons ********/
/******* Media Queries ********/
.fadein, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes, .periodequations-tree .has_changes,
.lookup-tree .has_changes, .periodequations-tree .star,
.lookup-tree .star {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*

  Modal Dialog Layout

*/
mat-dialog-container,
.mat-dialog-container {
  padding: 0 !important;
}

.dialog-model {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  max-width: 100vw;
  border-radius: 5px;
}
.dialog-model *[class$=-left],
.dialog-model *[class$=-center],
.dialog-model *[class$=-right] {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 auto;
  align-items: stretch;
}
.dialog-model *[class$=-left] {
  justify-content: flex-start;
}
.dialog-model *[class$=-center] {
  justify-content: center;
}
.dialog-model *[class$=-right] {
  justify-content: flex-end;
}
.dialog-model .scrollable-y {
  overflow: hidden;
  overflow-y: auto;
}
.dialog-model .scrollable-x {
  overflow: hidden;
  overflow-x: auto;
}
.dialog-model .scrollable:not(-y):not(-x) {
  overflow: auto;
}
.dialog-model .panel-left,
.dialog-model .panel-right {
  position: relative;
}
.dialog-model .panel-left.with_dragbar:before,
.dialog-model .panel-right.with_dragbar:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  width: 10px;
  height: 30px;
  margin-top: -15px;
  border: 1px solid #dfe0e1;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dialog-model .panel-left .dragbar,
.dialog-model .panel-right .dragbar {
  background-color: black;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  cursor: col-resize;
  opacity: 0;
  filter: alpha(opacity=0);
}
.dialog-model .panel-left .dragbar:hover, .dialog-model .panel-left .dragbar:focus,
.dialog-model .panel-right .dragbar:hover,
.dialog-model .panel-right .dragbar:focus {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dialog-model .panel-right {
  border-left: 1px solid #dfe0e1;
}
.dialog-model .panel-left {
  border-right: 1px solid #dfe0e1;
}
.dialog-model .panel-left-actions,
.dialog-model .panel-right-actions {
  padding: 15px;
  border-bottom: 1px solid #dfe0e1;
}

.modal-header {
  background-color: #51738b;
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 40px;
}

.modal-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.modal-body .component-header,
.modal-body .component-body,
.modal-body .panel-main-header,
.modal-body .panel-left,
.modal-body .panel-main,
.modal-body .panel-right,
.modal-body .panel-main-header-left,
.modal-body .panel-main-header-center,
.modal-body .panel-main-header-right,
.modal-body .modal-header-left,
.modal-body .modal-header-center,
.modal-body .modal-header-right,
.modal-body .modal-footer-left,
.modal-body .modal-footer-center,
.modal-body .modal-footer-right,
.modal-body .modal-header-left,
.modal-body .modal-header-center,
.modal-body .modal-header-right {
  position: relative;
  display: flex;
  flex-direction: row;
}
.modal-body .component-header,
.modal-body .panel-main-header {
  position: relative;
  min-height: fit-content;
}
.modal-body .panel-main-header.min-height {
  min-height: 48px;
}
.modal-body .panel-main-header-center,
.modal-body .modal-header-center,
.modal-body .modal-footer-center {
  margin: 0 auto;
  align-items: center;
}
.modal-body .modal-header-right,
.modal-body .panel-main-header-right,
.modal-body .modal-footer-right {
  margin: 0 0 0 auto;
  align-items: flex-end;
}
.modal-body .panel-left,
.modal-body .modal-header-left,
.modal-body .panel-main-header-left,
.modal-body .modal-footer-left {
  order: 0;
}
.modal-body .panel-main,
.modal-body .modal-header-center,
.modal-body .panel-main-header-center,
.modal-body .modal-footer-center {
  order: 1;
}
.modal-body .panel-right,
.modal-body .modal-header-right,
.modal-body .panel-main-header-right,
.modal-body .modal-footer-right {
  order: 2;
}
.modal-body .panel-main-header-left,
.modal-body .panel-main-header-center,
.modal-body .panel-main-header-right {
  padding: 0.5rem;
}
.modal-body .panel-main-header-left:empty,
.modal-body .panel-main-header-center:empty,
.modal-body .panel-main-header-right:empty {
  padding: 0;
}
.modal-body .panel-left-actions:empty,
.modal-body .panel-right-actions:empty {
  padding: 0;
}
.modal-body .component-header {
  position: relative;
  height: auto;
  width: 100%;
}
.modal-body .component-body {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
  flex-flow: nowrap;
  -webkit-flex-flow: nowrap;
  margin: 0px;
}
.modal-body .component-body .panel-left,
.modal-body .component-body .panel-right {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  min-height: 0;
  flex-grow: 1;
}
.modal-body .component-body .panel-main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
}
.modal-body .component-body .panel-main-body,
.modal-body .component-body .panel-left-body,
.modal-body .component-body .panel-right-body {
  flex-grow: 1;
}
.modal-body .component-body .panel-left:empty,
.modal-body .component-body .panel-left-header:empty,
.modal-body .component-body .panel-left-body:empty,
.modal-body .component-body .panel-left-footer:empty,
.modal-body .component-body .panel-right:empty,
.modal-body .component-body .panel-right-header:empty,
.modal-body .component-body .panel-right-body:empty,
.modal-body .component-body .panel-right-footer:empty {
  width: 0;
  height: 0;
}
.modal-body .component-body .panel-left-body,
.modal-body .component-body .panel-right-body {
  margin: 0;
  padding: 1rem;
}
.modal-body .component-body .panel-main-body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
}

.modal-footer, .dialog_actions {
  position: relative;
  padding: 15px;
  border-top: 1px solid #dfe0e1;
  display: flex;
  flex-direction: row;
}
.modal-footer .modal-footer-right, .dialog_actions .modal-footer-right {
  margin: 0 0 0 auto;
}
.modal-footer .modal-footer-right .btn_normal, .modal-footer .modal-footer-right .notification-banner-message .banner-actions a, .notification-banner-message .banner-actions .modal-footer .modal-footer-right a,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .link,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .banner-action-button,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions button,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right button, .dialog_actions .modal-footer-right .btn_normal, .dialog_actions .modal-footer-right .notification-banner-message .banner-actions a, .notification-banner-message .banner-actions .dialog_actions .modal-footer-right a,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .link,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .banner-action-button,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions button,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right button, .modal-footer .modal-footer-right .success-button, .dialog_actions .modal-footer-right .success-button, .modal-footer .modal-footer-right .btn_small, .dialog_actions .modal-footer-right .btn_small {
  margin: 0 0 0 1rem;
}
.modal-footer .modal-footer-right .btn_normal:first-of-type, .modal-footer .modal-footer-right .notification-banner-message .banner-actions a:first-of-type, .notification-banner-message .banner-actions .modal-footer .modal-footer-right a:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .link:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .link:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .banner-action-button:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .banner-action-button:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions button:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right button:first-of-type, .dialog_actions .modal-footer-right .btn_normal:first-of-type, .dialog_actions .modal-footer-right .notification-banner-message .banner-actions a:first-of-type, .notification-banner-message .banner-actions .dialog_actions .modal-footer-right a:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .link:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .link:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .banner-action-button:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .banner-action-button:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions button:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right button:first-of-type, .modal-footer .modal-footer-right .success-button:first-of-type, .dialog_actions .modal-footer-right .success-button:first-of-type, .modal-footer .modal-footer-right .btn_small:first-of-type, .dialog_actions .modal-footer-right .btn_small:first-of-type {
  margin: 0;
}

.content_container {
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.content_container.padding--all {
  padding: 15px;
}
.content_container.padding--left {
  padding-left: 15px;
}
.content_container.padding--right {
  padding-right: 15px;
}
.content_container.padding--top {
  padding-top: 15px;
}
.content_container.padding--bottom {
  padding-bottom: 15px;
}
.content_container.padding--vertical {
  padding-top: 15px;
  padding-bottom: 15px;
}
.content_container.padding--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.structure-col {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
}
.structure-col .structure-col--left {
  flex-grow: 1;
  text-align: left;
  font-size: 0;
}
.structure-col .structure-col--left * {
  font-size: 0.86rem;
}
.structure-col .structure-col--left button,
.structure-col .structure-col--left input[type=button] {
  display: inline-block;
  margin-right: 5px;
}
.structure-col .structure-col--left button .btn_text,
.structure-col .structure-col--left input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--left button:last-of-type,
.structure-col .structure-col--left input[type=button]:last-of-type {
  margin-right: 0;
}
.structure-col .structure-col--middle {
  flex-grow: 1;
  text-align: center;
  font-size: 0;
}
.structure-col .structure-col--middle * {
  font-size: 0.86rem;
}
.structure-col .structure-col--middle button,
.structure-col .structure-col--middle input[type=button] {
  display: inline-block;
  margin-right: 5px;
}
.structure-col .structure-col--middle button .btn_text,
.structure-col .structure-col--middle input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--middle button:last-of-type,
.structure-col .structure-col--middle input[type=button]:last-of-type {
  margin-right: 0;
}
.structure-col .structure-col--right {
  flex-grow: 1;
  text-align: right;
  font-size: 0;
}
.structure-col .structure-col--right * {
  font-size: 0.86rem;
}
.structure-col .structure-col--right button,
.structure-col .structure-col--right input[type=button] {
  display: inline-block;
  font-size: 0;
  margin-left: 5px;
}
.structure-col .structure-col--right button .btn_text,
.structure-col .structure-col--right input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--right button:first-of-type,
.structure-col .structure-col--right input[type=button]:first-of-type {
  margin-left: 0;
}

.show_labels .page-container,
.show_labels .modal-header,
.show_labels .modal-body,
.show_labels .modal-footer,
.show_labels .dialog_actions,
.show_labels .component-header,
.show_labels .component-body,
.show_labels .panel-left,
.show_labels .panel-left-header,
.show_labels .panel-left-actions,
.show_labels .panel-left-body,
.show_labels .panel-right,
.show_labels .panel-right-header,
.show_labels .panel-right-actions,
.show_labels .panel-right-body,
.show_labels .panel-main-header-left,
.show_labels .panel-main-header-center,
.show_labels .panel-main-header-right,
.show_labels .modal-header-left,
.show_labels .modal-header-center,
.show_labels .modal-header-right,
.show_labels .modal-footer-left,
.show_labels .modal-footer-center,
.show_labels .modal-footer-right,
.show_labels .panel-main,
.show_labels .panel-main-header,
.show_labels .panel-main-actions,
.show_labels .panel-main-body {
  border-width: 2px;
  border-style: solid;
  position: relative;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.show_labels .page-container:before,
.show_labels .modal-header:before,
.show_labels .modal-body:before,
.show_labels .modal-footer:before,
.show_labels .dialog_actions:before,
.show_labels .component-header:before,
.show_labels .component-body:before,
.show_labels .panel-left:before,
.show_labels .panel-left-header:before,
.show_labels .panel-left-actions:before,
.show_labels .panel-left-body:before,
.show_labels .panel-right:before,
.show_labels .panel-right-header:before,
.show_labels .panel-right-actions:before,
.show_labels .panel-right-body:before,
.show_labels .panel-main-header-left:before,
.show_labels .panel-main-header-center:before,
.show_labels .panel-main-header-right:before,
.show_labels .modal-header-left:before,
.show_labels .modal-header-center:before,
.show_labels .modal-header-right:before,
.show_labels .modal-footer-left:before,
.show_labels .modal-footer-center:before,
.show_labels .modal-footer-right:before,
.show_labels .panel-main:before,
.show_labels .panel-main-header:before,
.show_labels .panel-main-actions:before,
.show_labels .panel-main-body:before {
  content: attr(id);
  background-color: attr(border-color);
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}
.show_labels > div {
  border-color: red;
}
.show_labels > div:before {
  color: white;
  background-color: red;
}
.show_labels > div > div {
  border-color: blue;
}
.show_labels > div > div:before {
  color: white;
  background-color: blue;
}
.show_labels > div > div > div {
  border-color: #ff00fb;
}
.show_labels > div > div > div:before {
  color: white;
  background-color: #ff00fb;
}
.show_labels > div > div > div > div {
  border-color: #7cbd22;
}
.show_labels > div > div > div > div:before {
  color: white;
  background-color: #7cbd22;
}

.modal-body {
  overflow: hidden;
  height: 100%;
}

/* Lookup tables tree & Period Equations */
.tree {
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: auto;
}
.tree button {
  cursor: pointer;
  display: inline-block;
}
.tree .drawer_container .drawer_header {
  padding: 0;
}
.tree .tree-node-parent .drawer_container {
  padding-top: 0.5rem;
}
.tree .drawer_content {
  display: block;
}

.view_all_tables.node_all .tree_link {
  border: 0;
}

.drawer_container {
  /* Drawer Content (Hidden by Default) */
}
.drawer_container .drawer_header {
  position: relative;
  padding-left: 30px;
}
.drawer_container .drawer_header .drawer_header_toggle {
  position: absolute;
  left: 0;
  top: 0;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content {
  margin-left: 30px;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content .tree_link {
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.drawer_container .drawer_header .drawer_header_title a {
  font-weight: 600;
}
.drawer_container .drawer_content {
  display: none;
  position: relative;
}
.drawer_container .drawer_content.drawer_active {
  display: block;
}

.periodequations-tree .star,
.lookup-tree .star {
  color: darkorange;
  filter: blur(0.5px);
  transition: all 200ms;
  margin-left: 0.382em;
  text-decoration: none;
  line-height: 0.5rem;
}
.periodequations-tree .has_changes,
.lookup-tree .has_changes {
  color: darkorange;
  filter: blur(0.5px);
  transition: all 200ms;
  margin-left: 0.382em;
  text-decoration: none;
  line-height: 0.5rem;
}
.periodequations-tree .tree-node-parent .drawer_content,
.lookup-tree .tree-node-parent .drawer_content {
  display: block;
}
.periodequations-tree .tree_link,
.lookup-tree .tree_link {
  text-decoration: none !important;
}
.periodequations-tree .tree_link:before,
.lookup-tree .tree_link:before {
  display: none;
}
.periodequations-tree .tree_link.category,
.lookup-tree .tree_link.category {
  text-decoration: none;
  font-weight: bold;
  color: #72bf44;
  padding-left: 0;
}

.dashboard_grid_item {
  margin-bottom: 40px;
  position: relative;
  background-color: #ffffff;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row {
  color: #505050;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row:hover {
  background-color: transparent;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row .user_data .cell_header {
  padding: 10px 5px;
}
.dashboard_grid_item .dashboard_grid_content .user_row:last-of-type .cell_row {
  border-bottom: 1px solid #dfe0e1;
}
.dashboard_grid_item .dashboard_grid_total .footer_row .cell_row {
  border-bottom: 0px solid #525b6b;
  background-color: #f7fbf5;
}
.dashboard_grid_item .dashboard_grid_total .footer_row .cell_row .user_data .user_name {
  padding-left: 20px;
}

.cell_row {
  border-bottom: 1px solid #dfe0e1;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  width: 100%;
  min-height: 0;
}
.cell_row .overflow-text {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell_row .overflow {
  position: relative;
}
.cell_row .overflow:hover {
  overflow: visible;
  background-color: #f7fbf5;
  z-index: 1;
}
.cell_row .overflow:hover div {
  position: absolute;
  background-color: #f7fbf5;
  z-index: 1;
  padding-right: 20px;
}
.cell_row:hover {
  background-color: #f7fbf5;
}
.cell_row:hover .user_data .user_name a {
  color: #72bf44;
  text-decoration: underline;
}
.cell_row:hover .user_data .user_name a.toggle_row_details {
  display: inline-block;
  text-decoration: underline;
  position: relative;
  padding-left: 0.6rem;
  margin-left: 0.3rem;
}
.cell_row:hover .user_data .user_name a.toggle_row_details:before {
  content: " - ";
  position: absolute;
  left: 0;
  top: 0;
}
.cell_row:hover .user_data .user_name a:hover {
  text-decoration: underline;
}
.cell_row .user_data {
  line-height: 20px;
}
.cell_row .user_data.cell_width_1 {
  flex: 0 0 calc(6.25% * 1);
}
.cell_row .user_data.cell_width_2 {
  flex: 0 0 calc(6.25% * 2);
}
.cell_row .user_data.cell_width_3 {
  flex: 0 0 calc(6.25% * 3);
}
.cell_row .user_data.cell_width_4 {
  flex: 0 0 calc(6.25% * 4);
}
.cell_row .user_data.cell_width_5 {
  flex: 0 0 calc(6.25% * 5);
}
.cell_row .user_data.cell_width_6 {
  flex: 0 0 calc(6.25% * 6);
}
.cell_row .user_data.cell_width_7 {
  flex: 0 0 calc(6.25% * 7);
}
.cell_row .user_data.cell_width_8 {
  flex: 0 0 calc(6.25% * 8);
}
.cell_row .user_data.cell_width_9 {
  flex: 0 0 calc(6.25% * 9);
}
.cell_row .user_data.cell_width_10 {
  flex: 0 0 calc(6.25% * 10);
}
.cell_row .user_data.cell_width_11 {
  flex: 0 0 calc(6.25% * 11);
}
.cell_row .user_data.cell_width_12 {
  flex: 0 0 calc(6.25% * 12);
}
.cell_row .user_data.cell_width_13 {
  flex: 0 0 calc(6.25% * 13);
}
.cell_row .user_data.cell_width_14 {
  flex: 0 0 calc(6.25% * 14);
}
.cell_row .user_data.cell_width_15 {
  flex: 0 0 calc(6.25% * 15);
}
.cell_row .user_data.cell_width_16 {
  flex: 0 0 calc(6.25% * 16);
}
.cell_row .user_data .cell_header {
  padding: 5px 5px;
  font-weight: 600;
}
.cell_row .user_data .cell_header h3 {
  line-height: 20px;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 400;
}
.cell_row .user_data .user_name {
  padding: 5px 5px;
}
.cell_row .user_data .user_name a {
  color: #72bf44;
  text-decoration: underline;
}
.cell_row .user_data .user_name a.toggle_row_details {
  text-decoration: underline;
  display: none;
}
.cell_row .user_data .user_name a.toggle_row_details:before {
  content: " - ";
}
.cell_row .user_data .user_name a:hover {
  text-decoration: underline;
}
.cell_row .user_data .value {
  padding: 5px 5px;
}
.cell_row .user_data .value.negative {
  color: #ea5441;
}

app-lookup-setup .panel-main-header,
app-liquidity-sources .panel-main-header,
app-exogenous-lines .panel-main-header {
  min-height: 50px !important;
  height: 50px !important;
}

app-equation-bar kendo-window,
app-balance-sheet kendo-window {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
}
app-equation-bar kendo-window-titlebar,
app-equation-bar .k-dialog-titlebar,
app-equation-bar .k-window-titlebar,
app-balance-sheet kendo-window-titlebar,
app-balance-sheet .k-dialog-titlebar,
app-balance-sheet .k-window-titlebar {
  background-color: #51738b !important;
  padding: 0.25rem 0.5rem !important;
}

app-input-structure select,
app-lookup-setup select,
app-liquidity-sources select,
app-exogenous-lines select {
  animation: fadein 0.3s;
}

#data_set_container {
  position: relative;
  height: 100%;
  min-width: 9.37rem;
  padding: 0 1rem;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: right;
  z-index: 1;
}
#data_set_container #data_set_filename a {
  color: #ffffff;
  display: inline_block;
  font-weight: 300;
}
#data_set_container #data_set_filename a:hover, #data_set_container #data_set_filename a:focus {
  text-decoration: none;
}
#data_set_container #data_set_timestamp {
  display: block;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-weight: 300;
}

.fadein, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes, .periodequations-tree .star,
.lookup-tree .star, .periodequations-tree .has_changes,
.lookup-tree .has_changes {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.panel-left-body,
.panel-right-body {
  overflow: hidden auto;
  height: auto;
}
.panel-left-body .tree-warning,
.panel-right-body .tree-warning {
  background-color: #ff9d00;
  color: #ffffff;
  padding: 0.618rem;
}
.panel-left-body .tree-nodes,
.panel-right-body .tree-nodes {
  overflow: hidden auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  overflow: hidden auto;
  padding: 0;
  margin: 0 0 0 15px;
  list-style: none;
}
.panel-left-body .tree .tree_link,
.panel-right-body .tree .tree_link {
  display: block;
  text-decoration: underline;
  line-height: 20px;
  padding: 5px 5px 5px 20px;
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 5px 5px;
  text-align: left;
  overflow: visible;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 0px solid;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.86rem;
  color: #2b495e;
  -webkit-tap-highlight-color: transparent;
  padding-left: 1rem;
}
.panel-left-body .tree .tree_link:before,
.panel-right-body .tree .tree_link:before {
  content: "\e902";
  font-family: "empyrean_icons";
  font-size: 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 10px;
  left: 0px;
  line-height: 10px;
}
.panel-left-body .tree .tree_link.tree_link_active:before,
.panel-right-body .tree .tree_link.tree_link_active:before {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.panel-left-body .tree .tree_text,
.panel-right-body .tree .tree_text {
  display: block;
  line-height: 20px;
  padding: 5px 5px 5px 20px;
  position: relative;
}
.panel-left-body .tree .tree_text:before,
.panel-right-body .tree .tree_text:before {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #2b495e;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  position: absolute;
  top: 13px;
  left: 5px;
}

#sim_group,
.sim_group {
  width: calc(100% - 30px);
  padding: 15px;
}
#sim_group #sim_header,
.sim_group #sim_header {
  border-bottom: 1px solid #505050;
}
#sim_group #sim_header > div .sim_header_label,
.sim_group #sim_header > div .sim_header_label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
#sim_group .sim_scenario,
#sim_group .sim_mnemonic,
#sim_group .sim_description,
.sim_group .sim_scenario,
.sim_group .sim_mnemonic,
.sim_group .sim_description {
  float: left;
  min-height: 30px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_scenario .is_invalid_mnemonic,
#sim_group .sim_mnemonic .is_invalid_mnemonic,
#sim_group .sim_description .is_invalid_mnemonic,
.sim_group .sim_scenario .is_invalid_mnemonic,
.sim_group .sim_mnemonic .is_invalid_mnemonic,
.sim_group .sim_description .is_invalid_mnemonic {
  line-height: 20px;
  margin: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_scenario .is_invalid_mnemonic .idle-component,
#sim_group .sim_mnemonic .is_invalid_mnemonic .idle-component,
#sim_group .sim_description .is_invalid_mnemonic .idle-component,
.sim_group .sim_scenario .is_invalid_mnemonic .idle-component,
.sim_group .sim_mnemonic .is_invalid_mnemonic .idle-component,
.sim_group .sim_description .is_invalid_mnemonic .idle-component {
  border: 1px solid #ea5441;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
#sim_group .sim_scenario .is_invalid_mnemonic .invalid-message,
#sim_group .sim_mnemonic .is_invalid_mnemonic .invalid-message,
#sim_group .sim_description .is_invalid_mnemonic .invalid-message,
.sim_group .sim_scenario .is_invalid_mnemonic .invalid-message,
.sim_group .sim_mnemonic .is_invalid_mnemonic .invalid-message,
.sim_group .sim_description .is_invalid_mnemonic .invalid-message {
  white-space: normal;
}
#sim_group .sim_scenario:hover .inner_cell_text .inline-edit,
#sim_group .sim_mnemonic:hover .inner_cell_text .inline-edit,
#sim_group .sim_description:hover .inner_cell_text .inline-edit,
.sim_group .sim_scenario:hover .inner_cell_text .inline-edit,
.sim_group .sim_mnemonic:hover .inner_cell_text .inline-edit,
.sim_group .sim_description:hover .inner_cell_text .inline-edit {
  text-decoration: underline;
}
#sim_group .sim_scenario .inner_cell_text,
#sim_group .sim_mnemonic .inner_cell_text,
#sim_group .sim_description .inner_cell_text,
.sim_group .sim_scenario .inner_cell_text,
.sim_group .sim_mnemonic .inner_cell_text,
.sim_group .sim_description .inner_cell_text {
  line-height: 20px;
  padding: 0px 0px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
#sim_group .sim_scenario .inner_cell_text .inline-edit,
#sim_group .sim_mnemonic .inner_cell_text .inline-edit,
#sim_group .sim_description .inner_cell_text .inline-edit,
.sim_group .sim_scenario .inner_cell_text .inline-edit,
.sim_group .sim_mnemonic .inner_cell_text .inline-edit,
.sim_group .sim_description .inner_cell_text .inline-edit {
  line-height: 20px;
  padding: 5px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
#sim_group .sim_scenario .inner_cell_text .inline-edit.force_multiline_text,
#sim_group .sim_mnemonic .inner_cell_text .inline-edit.force_multiline_text,
#sim_group .sim_description .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_scenario .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_mnemonic .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_description .inner_cell_text .inline-edit.force_multiline_text {
  white-space: normal !important;
}
#sim_group .sim_scenario .inner_cell_text input,
#sim_group .sim_mnemonic .inner_cell_text input,
#sim_group .sim_description .inner_cell_text input,
.sim_group .sim_scenario .inner_cell_text input,
.sim_group .sim_mnemonic .inner_cell_text input,
.sim_group .sim_description .inner_cell_text input {
  line-height: 20px;
  margin: 0;
  padding: 4px 5px;
  border: 1px solid #ababab;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_row_toggle,
#sim_group .sim_last_updated,
#sim_group .sim_dataset,
#sim_group .sim_delete,
#sim_group .sim_notifications,
#sim_group .sim_action,
.sim_group .sim_row_toggle,
.sim_group .sim_last_updated,
.sim_group .sim_dataset,
.sim_group .sim_delete,
.sim_group .sim_notifications,
.sim_group .sim_action {
  float: left;
  min-height: 30px;
  padding: 5px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .inner_cell_text,
.sim_group .inner_cell_text {
  line-height: 20px;
  padding: 5px 0px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
#sim_group .inner_cell_text a,
.sim_group .inner_cell_text a {
  color: #2b495e;
}
#sim_group .inner_cell_text a:hover, #sim_group .inner_cell_text a:focus,
.sim_group .inner_cell_text a:hover,
.sim_group .inner_cell_text a:focus {
  color: #72bf44;
}
#sim_group .sim_scenario .inner_cell_text,
.sim_group .sim_scenario .inner_cell_text {
  font-weight: 600;
}
#sim_group .sim_row_toggle,
.sim_group .sim_row_toggle {
  width: 40px;
}
#sim_group .sim_scenario,
.sim_group .sim_scenario {
  width: 15%;
}
#sim_group .sim_mnemonic,
.sim_group .sim_mnemonic {
  width: 15%;
}
#sim_group .sim_description,
.sim_group .sim_description {
  width: calc(70% - 370px);
}
#sim_group .manage_resultsets_table .sim_description,
.sim_group .manage_resultsets_table .sim_description {
  width: calc(70% - 370px + 40px);
}
#sim_group .sim_last_updated,
.sim_group .sim_last_updated {
  width: 130px;
}
#sim_group .sim_dataset,
.sim_group .sim_dataset {
  width: 160px;
}
#sim_group .sim_delete,
.sim_group .sim_delete {
  width: 40px;
}
#sim_group .sim_notifications,
.sim_group .sim_notifications {
  width: 40px;
}
#sim_group .sim_action,
.sim_group .sim_action {
  width: 120px;
}
#sim_group .sim_notifications .icon_notification,
.sim_group .sim_notifications .icon_notification {
  color: #ff9d00;
}
#sim_group .sim_delete button,
.sim_group .sim_delete button {
  opacity: 0.1;
  filter: alpha(opacity=10);
}
#sim_group .sim_description .inner_cell_text.wrap_text,
.sim_group .sim_description .inner_cell_text.wrap_text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 30px);
}
#sim_group .sim_description .more_link,
#sim_group .sim_description .less_link,
.sim_group .sim_description .more_link,
.sim_group .sim_description .less_link {
  line-height: 20px;
  padding: 5px 0px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
#sim_group .sim_description .more_link a,
#sim_group .sim_description .less_link a,
.sim_group .sim_description .more_link a,
.sim_group .sim_description .less_link a {
  color: #2b495e;
}
#sim_group .sim_description .more_link a:hover, #sim_group .sim_description .more_link a:focus,
#sim_group .sim_description .less_link a:hover,
#sim_group .sim_description .less_link a:focus,
.sim_group .sim_description .more_link a:hover,
.sim_group .sim_description .more_link a:focus,
.sim_group .sim_description .less_link a:hover,
.sim_group .sim_description .less_link a:focus {
  text-decoration: underline;
}
#sim_group .sim_row_container,
.sim_group .sim_row_container {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
#sim_group .sim_row_container:last-of-type,
.sim_group .sim_row_container:last-of-type {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
#sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button,
.sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button {
  font-size: 12px;
  position: relative;
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button .btn_icon span,
.sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button .btn_icon span {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row .sim_row_toggle button,
.sim_group .sim_row_container .sim_row .sim_row_toggle button {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row .sim_row_toggle button .btn_icon span,
.sim_group .sim_row_container .sim_row .sim_row_toggle button .btn_icon span {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row:hover,
.sim_group .sim_row_container .sim_row:hover {
  background-color: #f4f6f7;
}
#sim_group .sim_row_container .sim_row:hover .sim_delete button,
.sim_group .sim_row_container .sim_row:hover .sim_delete button {
  opacity: 1;
  filter: alpha(opacity=100);
}
#sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario,
.sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario {
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario .color-dot,
.sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario .color-dot {
  margin: auto 0;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #cccccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
#sim_group .sim_row_container .sim_row.sim_stress .sim_scenario,
.sim_group .sim_row_container .sim_row.sim_stress .sim_scenario {
  padding-left: 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_row.sim_stress .sim_scenario .color-line,
.sim_group .sim_row_container .sim_row.sim_stress .sim_scenario .color-line {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  width: 4px;
  height: 100%;
  margin-left: -1em;
  background-color: #cccccc;
}
#sim_group .sim_row_container .sim_stress_add .sim_scenario,
.sim_group .sim_row_container .sim_stress_add .sim_scenario {
  padding-left: 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_baseline_add .sim_scenario,
.sim_group .sim_row_container .sim_baseline_add .sim_scenario {
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_baseline_add .sim_scenario .color-dot,
.sim_group .sim_row_container .sim_baseline_add .sim_scenario .color-dot {
  margin: auto 0;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #cccccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.is_invalid_dataset {
  border: 1px solid red !important;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.8rem;
}

#assumption_container {
  position: relative;
  height: calc(100vh - 242px);
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;
}
#assumption_container #assumption_lookup_list {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  padding: 15px;
}
#assumption_container #assumption_lookup_list:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #dfe0e1;
}
#assumption_container #assumption_lookup_viewer {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  flex: 1;
  margin-left: 270px;
  display: block;
}
#assumption_container #assumption_lookup_viewer #toggle_list_btn_container {
  position: absolute;
  left: -15px;
  top: 0;
  display: none;
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;
}
#assumption_container #assumption_lookup_viewer #toggle_list_btn_container button {
  background-color: #ffffff;
}

#footer_bar {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
  position: relative;
}
#footer_bar:before {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_result_set {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  padding-right: 0;
}
#footer_bar #footer_quick_link {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 0;
  position: relative;
}
#footer_bar #footer_report_summary {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
  padding-left: 0;
}
#footer_bar #footer_report_summary button.btn_sheet {
  min-width: 3rem;
  background-color: #ffffff;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  padding: 10px;
  position: relative;
  color: rgba(80, 80, 80, 0.5);
  text-transform: inherit;
  font-size: 0.86rem;
  font-weight: 400;
}
#footer_bar #footer_report_summary button.btn_sheet:before {
  content: "";
  height: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_report_summary button.btn_sheet:after {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_report_summary button.btn_sheet:hover, #footer_bar #footer_report_summary button.btn_sheet:focus {
  color: #2b495e;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active {
  color: #505050;
  font-weight: 600;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active:before {
  display: none;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active:after {
  display: none;
}
#footer_bar #footer_tabs {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 5px;
}
#footer_bar #footer_controls {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}
#footer_bar #footer_controls.hide_controls {
  display: none;
}
#footer_bar #footer_controls #pan_controls {
  width: 60px;
}
#footer_bar #footer_controls #pan_controls button {
  float: left;
}
#footer_bar #footer_export {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

#result_tab_container > ul {
  margin: 0px 0 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  font-size: 0;
  white-space: nowrap;
}
#result_tab_container > ul > li {
  display: inline-block;
  font-size: 0;
  margin-right: 10px;
  position: relative;
}
#result_tab_container > ul > li:last-child {
  margin-right: 0;
}
#result_tab_container > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  font-size: 0;
}
#result_tab_container > ul > li > ul > li {
  display: inline-block;
  font-size: 0;
  margin-right: 2px;
  position: relative;
  overflow: hidden;
}
#result_tab_container > ul > li > ul > li button.btn_sheet {
  min-width: 6rem;
  background-color: #ffffff;
  background: linear-gradient(to bottom, rgb(246, 246, 246) 10%, white 50%, white);
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  padding: 10px 10px 7px 10px;
  position: relative;
  border-width: 0 0 3px 0;
  border-style: solid;
  color: rgba(80, 80, 80, 0.5);
  text-transform: inherit;
  font-size: 0.86rem;
  font-weight: 600;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:before {
  content: "";
  height: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:after {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:hover, #result_tab_container > ul > li > ul > li button.btn_sheet:focus {
  color: #2b495e;
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active {
  color: #505050;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active:before {
  display: none;
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active:after {
  display: none;
}
#result_tab_container > ul > li > ul > li button.btn_sheet .btn_status span {
  color: #ff9d00;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide {
  position: absolute;
  right: -40px;
  top: 5px;
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;
  margin-left: 5px;
  padding-left: 0;
  padding-right: 5px;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide .btn_icon span {
  font-size: 10px;
  background-color: #f5f6f7;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  padding: 3px;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 10px;
  background: rgb(255, 255, 255);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
#result_tab_container > ul > li > ul > li:hover button.btn_sheet_hide {
  right: 0;
}

#context_bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-between;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;
  background-color: #f5f6f7;
}
#context_bar #context_bar_left {
  flex: 1;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#context_bar #context_bar_left #context_source {
  float: left;
  line-height: 30px;
  padding-right: 15px;
  margin-right: 5px;
  position: relative;
}
#context_bar #context_bar_left #context_source:after {
  content: "\e910";
  font-family: "empyrean_icons";
  line-height: 30px;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
#context_bar #context_bar_left #context_source .source_file span {
  display: block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 30px;
}
#context_bar #context_bar_left #context_title {
  float: left;
  padding-right: 10px;
}
#context_bar #context_bar_left #context_edit_assumptions {
  float: left;
  padding-right: 10px;
  cursor: pointer;
}
#context_bar #context_bar_left #context_edit_assumptions .edit_asumptions_link {
  display: block;
  line-height: 20px;
  padding: 5px 0px 5px 0;
}
#context_bar #context_bar_middle {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
#context_bar #context_bar_middle #view_controller {
  display: inline-block;
  width: 160px;
}
#context_bar #context_bar_middle #view_controller .toggle_switch_option {
  width: 80px;
}
#context_bar #context_bar_middle #access_controller {
  display: inline-block;
  color: dark_grey;
  line-height: 20px;
  padding: 5px 0;
  margin-left: 10px;
}
#context_bar #context_bar_middle #access_controller .access_text {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
#context_bar #context_bar_right {
  text-align: right;
  flex: 1;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#context_bar #context_bar_right #sheet_status {
  display: inline-block;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#context_bar #context_bar_right #notifications_container {
  display: inline-block;
  margin-left: 10px;
}

#sheet_list {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 400px;
  z-index: 1;
  background: #ffffff;
  padding: 15px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sheet_list > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#sheet_list > ul > li {
  margin-bottom: 20px;
}
#sheet_list > ul > li:last-child {
  margin-bottom: 0;
}
#sheet_list > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#sheet_list > ul > li > ul > li a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0;
}
#sheet_list > ul > li > ul > li a:hover, #sheet_list > ul > li > ul > li a:focus {
  background-color: #f5f6f7;
  color: #505050;
}
#sheet_list > ul > li > ul > li a:hover .sheet_name, #sheet_list > ul > li > ul > li a:focus .sheet_name {
  text-decoration: underline;
}
#sheet_list > ul > li > ul > li a:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
#sheet_list > ul > li > ul > li a .sheet_name {
  width: 70%;
  display: block;
  vertical-align: top;
  float: left;
  word-wrap: break-word;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0 5px 20px;
  line-height: 20px;
  position: relative;
}
#sheet_list > ul > li > ul > li a .sheet_dataset {
  padding: 5px 0;
  line-height: 20px;
  width: 30%;
  display: block;
  vertical-align: right;
  float: left;
  text-align: right;
  word-wrap: break-word;
}
#sheet_list > ul > li > ul > li a.sheet_baseline .sheet_name:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
#sheet_list > ul > li > ul > li a.sheet_stress .sheet_name {
  padding-left: 40px;
}
#sheet_list > ul > li > ul > li a.sheet_stress .sheet_name:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 4px;
}

ul#notifications_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#notifications_list > li {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
ul#notifications_list > li:last-child {
  border-bottom: 0px solid #dfe0e1;
}
ul#notifications_list > li p {
  line-height: 20px;
  color: #525b6b;
}
ul#notifications_list > li p:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}
ul#notifications_list > li p a {
  color: #ff9d00;
  text-decoration: none;
}
ul#notifications_list > li p a:hover, ul#notifications_list > li p a:focus {
  text-decoration: underline;
}

#notification_container {
  font-size: 0.76rem;
  background-color: #ff9d00;
  color: #525b6b;
  padding: 5px;
  text-align: center;
  display: block;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
}
#notification_container p {
  line-height: 15px;
}
#notification_container p:last-child {
  padding: 0;
  margin: 0;
}
#notification_container [class^=icon_], #notification_container [class*=" icon_"] {
  vertical-align: middle;
}

app-hover-notification {
  display: contents;
}

.hover-notification-container {
  background-color: white;
  color: #525b6b;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  display: grid;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.86rem;
  grid-template-rows: auto 1fr;
  grid-gap: 0.4rem;
  min-width: 100px;
  max-width: 300px;
  padding: 0.7rem;
  position: absolute;
  top: 0.5rem;
  width: auto;
  z-index: 999;
  border-left: 6px solid;
  border-left-color: #ff9d00;
}
.hover-notification-container.notificationright {
  right: 0.5rem;
}
.hover-notification-container.notificationleft {
  left: 0.5rem;
}
.hover-notification-container.info {
  border-left-color: #51738b !important;
}
.hover-notification-container.info .title,
.hover-notification-container.info .value {
  color: #51738b !important;
}
.hover-notification-container.success {
  border-left-color: #72bf44 !important;
}
.hover-notification-container.success .title,
.hover-notification-container.success .value {
  color: #72bf44 !important;
}
.hover-notification-container.error {
  border-left-color: #ea5441 !important;
}
.hover-notification-container.error .title,
.hover-notification-container.error .value {
  color: #ea5441 !important;
}
.hover-notification-container.warning {
  border-left-color: #ff9d00 !important;
}
.hover-notification-container.warning .title,
.hover-notification-container.warning .value {
  color: #ff9d00 !important;
}
.hover-notification-container .header {
  display: grid;
  grid-template-columns: 1fr auto;
}
.hover-notification-container .header .title,
.hover-notification-container .header .value {
  font-weight: 600;
}
.hover-notification-container .header .value {
  margin-left: 1rem;
}
.hover-notification-container .body {
  white-space: pre-wrap;
  font-weight: 400;
}

app-multi-notification {
  margin: auto !important;
  padding: 0.5rem;
  width: auto;
  max-width: 90%;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(3, auto);
  grid-gap: 0.5rem;
}

.multi-notification-container {
  padding: 0.5rem;
  border: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 6px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 1fr auto;
}
.multi-notification-container .title {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 0.8rem;
  color: #525b6b;
}
.multi-notification-container .body {
  color: #525b6b;
  font-size: 0.86rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 400;
}
.multi-notification-container .actions {
  margin-top: auto;
}
.multi-notification-container .actions button {
  margin-left: 0.5rem;
}

.component-header {
  overflow: hidden;
  transition: all 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: max-height 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  height: auto;
  max-height: 600px;
}

.component-header.slide-up {
  max-height: 0;
  display: none;
}

ul#application_list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#application_list > li {
  float: left;
  width: 33.3333333333%;
  text-align: center;
}
ul#application_list > li a.application_link {
  display: block;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  text-align: center;
  padding: 10px 4px;
  color: inherit;
}
ul#application_list > li a.application_link:hover, ul#application_list > li a.application_link:focus {
  background-color: rgba(43, 73, 94, 0.1);
}
ul#application_list > li a.application_link:hover .application_icon, ul#application_list > li a.application_link:focus .application_icon {
  background-color: rgba(43, 73, 94, 0.3);
  color: #2b495e;
}
ul#application_list > li a.application_link:hover .application_name,
ul#application_list > li a.application_link:hover .application_subtitle, ul#application_list > li a.application_link:focus .application_name,
ul#application_list > li a.application_link:focus .application_subtitle {
  color: #2b495e;
}
ul#application_list > li a.application_link.disabled {
  opacity: 0.3;
  transition: opacity 0.6s;
}
ul#application_list > li a.application_link .application_icon {
  display: block;
  padding: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: rgba(0, 0, 0, 0.05);
  width: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto 10px;
}
ul#application_list > li a.application_link .application_icon span {
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  display: block;
  vertical-align: top;
}
ul#application_list > li a.application_link .application_name {
  text-align: center;
  display: block;
  line-height: 15px;
  min-height: 30px;
  font-size: 0.8rem;
}
ul#application_list > li a.application_link .application_subtitle {
  text-align: center;
  display: block;
  font-size: 0.7rem;
}

.notification-banner-message {
  position: relative;
  width: 100%;
  color: #000000;
  background: #ffffff;
  cursor: default;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 157, 0, 0.25);
  padding-bottom: 1px;
}
.notification-banner-message .banner-title,
.notification-banner-message .banner-body,
.notification-banner-message .banner-actions {
  width: auto;
  text-align: center;
  max-width: 800px;
}
.notification-banner-message .banner-title,
.notification-banner-message .banner-body {
  padding: 0.618rem auto;
}
.notification-banner-message .banner-actions {
  padding-bottom: 0.618rem;
}
.notification-banner-message .banner-actions {
  display: flex;
  justify-content: center;
}
.notification-banner-message .banner-actions a,
.notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions button {
  background: unset;
  color: unset;
  border: unset;
  flex: 1 auto;
  flex-wrap: wrap;
  text-transform: uppercase;
  color: #72bf44;
  width: auto;
  min-width: 7rem;
  min-height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.notification-banner-message .banner-actions a :first-of-type(),
.notification-banner-message .banner-actions .link :first-of-type(),
.notification-banner-message .banner-actions .banner-action-button :first-of-type(),
.notification-banner-message .banner-actions button :first-of-type() {
  margin-left: 0;
}
.notification-banner-message .banner-actions a.primary-action,
.notification-banner-message .banner-actions .link.primary-action,
.notification-banner-message .banner-actions .banner-action-button.primary-action,
.notification-banner-message .banner-actions button.primary-action {
  background: #2b495e;
  color: #ffffff;
}
.notification-banner-message .banner-actions a.secondary-action,
.notification-banner-message .banner-actions .link.secondary-action,
.notification-banner-message .banner-actions .banner-action-button.secondary-action,
.notification-banner-message .banner-actions button.secondary-action {
  background: transparent;
  border: 1px solid #2b495e;
  color: #2b495e;
}

app-notification-banner,
.app-notification-banner,
.notification-banner-message {
  width: 100%;
  padding: 0;
}

/*

  Spreadsheets

*/
.spread-container {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 8;
}
.spread-container .spreadsheet {
  z-index: 9;
  position: relative;
  height: 100%;
  width: 100%;
}
.spread-container .spreadsheet--loading, .spread-container .spreadsheet--empty {
  z-index: 10;
  background-color: #f5f6f7;
  background-image: linear-gradient(to bottom, #f5f6f7 calc(100% - 40px), white);
  position: relative;
  min-height: 100%;
}
.spread-container .spreadsheet--loading:before, .spread-container .spreadsheet--empty:before {
  content: "\e90d";
  font-family: "empyrean_icons";
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 80px;
  color: #cccccc;
  transform: translate(-50%, -50%);
  font-weight: 300;
}
.spread-container .spreadsheet--loading:after, .spread-container .spreadsheet--empty:after {
  content: "Loading Sheet...";
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1rem;
  color: #cccccc;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  text-align: center;
  font-weight: 300;
}
.spread-container .spreadsheet--empty:after {
  content: "No Data Available.";
}
.spread-container .spreadsheet--empty.empty_results:after {
  content: "No Results Available.";
}
.spread-container .spreadsheet--empty.empty_period_equations:after {
  content: "No Period Equations Available.";
}

.sheet-status .error {
  line-height: 20px;
  padding: 5px 0;
  color: #ea5441;
}
.sheet-status .error:after {
  margin-left: 5px;
  content: "\e91f";
  font-family: "empyrean_icons";
  vertical-align: top;
}
.sheet-status .valid {
  line-height: 20px;
  padding: 5px 0;
}
.sheet-status .valid:after {
  color: #ea5441;
  margin-left: 5px;
  content: "\e91f";
  font-family: "empyrean_icons";
  vertical-align: top;
}

.fadein, .periodequations-tree .star,
.lookup-tree .star, .periodequations-tree .has_changes,
.lookup-tree .has_changes, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
form {
  margin: 0;
  padding: 0;
}

input,
select,
button {
  font-size: 1em;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  font-size: 1em;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
fieldset legend {
  display: block;
  box-sizing: border-box;
  line-height: 20px;
  padding: 5px 0 5px 0px;
}
fieldset legend.hidden_legend {
  position: absolute;
  font-size: 0;
}

/* Text Area */
textarea.inputbox {
  width: 100%;
  resize: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  border: 1px solid #ababab;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 5px 5px;
  line-height: 20px;
  vertical-align: bottom;
  background: #ffffff none 0 0 no-repeat;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  color: #505050;
}
textarea.inputbox.no_border {
  border: 1px solid transparent;
}
textarea.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
  padding-right: 25px;
}
textarea.inputbox:focus {
  border: 1px solid #505050;
}
textarea.inputbox:focus[readonly] {
  border: 1px solid #f5f6f7;
}
textarea.inputbox:focus[readonly][disabled] {
  border: 1px solid transparent;
}
textarea.inputbox.auto_width {
  width: initial;
}

/* Inputbox */
input.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 5px;
  border: 1px solid #ababab;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff none 0 0 no-repeat;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: "";
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  color: #505050;
}
input.inputbox.no_border {
  border: 1px solid transparent;
}
input.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
}
input.inputbox:focus {
  border: 1px solid #505050;
}
input.inputbox:focus[readonly] {
  border: 1px solid #ababab;
}
input.inputbox:focus[readonly][disabled] {
  border: 1px solid transparent;
}
input.inputbox.auto_width {
  width: initial;
}

input[type=date] {
  -webkit-appearance: none;
}

input[type=number] {
  -webkit-appearance: none;
}

/* Select */
select.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 25px 4px 5px;
  border: 1px solid #ababab;
  color: #505050;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff url("/assets/images/select_dropdown.png") no-repeat;
  background-position: right center;
  -webkit-padding-end: 25px;
  -moz-padding-end: 25px;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: "";
  padding-right: 25px\9 ;
  cursor: pointer;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  select.inputbox {
    /* on retina, use image that's scaled by 2 */
    background-image: url("/assets/images/select_dropdown@2x.png");
    background-size: 20px 20px;
  }
}
select.inputbox.no_border {
  border: 1px solid transparent;
}
select.inputbox.no_dropdown {
  background: none !important;
  background-color: #ffffff !important;
  padding: 10px !important;
}
select.inputbox.add_item_select_box {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
select.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
}
select.inputbox.no_dropdown {
  -webkit-padding-end: 0px;
  -moz-padding-end: 0px;
  padding-right: 0px\9 ;
  padding: 10px 0px 9px 0px;
}
select.inputbox:focus {
  border: 1px solid #505050;
  opacity: 1;
  filter: alpha(opacity=100);
}
select.inputbox.auto_width {
  width: initial;
}
select.inputbox option {
  color: #505050;
}
select.inputbox option[disabled] {
  color: #cccccc;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select::-ms-expand {
  display: none;
  text-indent: 0.01px;
  text-overflow: "";
}

select::-moz-focus-inner {
  padding: 0;
  border: 0;
  margin: 0px;
  background-image: none !important;
}

@-moz-document url-prefix() {
  select.inputbox {
    padding: 6px 25px 7px 5px;
  }
}
/* Disabled States */
input[disabled],
textarea[disabled],
select[disabled] {
  -webkit-text-fill-color: #cccccc;
  -webkit-opacity: 1;
  color: #cccccc;
  background: transparent;
}

input.inputbox[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

select.inputbox[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

textarea[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

/* Placeholder Text */
.inputbox::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:placeholder-shown {
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Error States */
/* Datepicker */
.btn_icon_date {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border: 0;
  padding: 7px;
  -webkit-border-radius: 0px 4px 4px 0px;
  border-radius: 0px 4px 4px 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  cursor: pointer;
}

.btn_icon_date:focus,
.btn_icon_date:hover {
  color: #ffffff;
  background-color: #72bf44;
}

/* Inputbox Widths */
input.inputbox.width_x-small,
select.inputbox.width_x-small,
textarea.inputbox.width_x-small {
  width: 60px;
  display: inline-block;
}
input.inputbox.width_small,
select.inputbox.width_small,
textarea.inputbox.width_small {
  width: 80px;
  display: inline-block;
}

.inputbox.invalid {
  border-color: #ea5441;
}

.invalid_message {
  display: inline-block;
  line-height: 20px;
  clear: both;
  color: #ea5441;
}

@media all and (-ms-high-contrast: none) {
  input.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  input.inputbox {
    height: 30px;
  } /* IE11 */
  select.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  select.inputbox {
    height: 30px;
  } /* IE11 */
  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  }
  *::-ms-backdrop,
  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  } /* IE11 */
}
.inputbox[type=checkbox]:checked,
.inputbox[type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.inputbox[type=checkbox]:checked + label,
.inputbox[type=checkbox]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px !important;
}

.inputbox[type=checkbox]:checked + label:before,
.inputbox[type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #ababab;
  background: #ffffff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

.inputbox[type=checkbox]:checked + label:after,
.inputbox[type=checkbox]:not(:checked) + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid #2b495e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.inputbox[type=checkbox]:hover + label,
.inputbox[type=checkbox]:focus + label {
  color: #505050;
}

.inputbox[type=checkbox]:hover + label:before,
.inputbox[type=checkbox]:focus + label:before {
  border: 1px solid #505050;
}

.inputbox[type=checkbox]:disabled:checked + label,
.inputbox[type=checkbox]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 5px 0 5px 20px;
}

.inputbox[type=checkbox]:disabled:checked + label:before,
.inputbox[type=checkbox]:disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  background: #eceef3;
}

.inputbox[type=checkbox]:disabled:checked + label:after,
.inputbox[type=checkbox]:disabled:not(:checked) + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid #525b6b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.inputbox[type=checkbox]:not(:checked) + label:after {
  opacity: 0;
}

.inputbox[type=checkbox]:checked + label:after {
  opacity: 1;
  border-color: #ffffff;
}

.inputbox[type=checkbox]:checked + label:before {
  border-color: #2b495e;
  border-width: 0;
  height: 16px;
  width: 16px;
  background-color: #2b495e;
}

.inputbox[type=radio]:checked,
.inputbox[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.inputbox[type=radio]:checked + label,
.inputbox[type=radio]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px !important;
}

.inputbox[type=radio]:checked + label:before,
.inputbox[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #ababab;
  border-radius: 100%;
  background: #ffffff;
}

.inputbox[type=radio]:checked + label:after,
.inputbox[type=radio]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #2b495e;
  position: absolute;
  top: 9px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.inputbox[type=radio]:focus + label,
.inputbox[type=radio]:hover + label {
  color: #505050;
}

.inputbox[type=radio]:focus + label:before,
.inputbox[type=radio]:hover + label:before {
  border: 1px solid #505050;
}

.inputbox[type=radio]:disabled:checked + label,
.inputbox[type=radio]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px;
}

.inputbox[type=radio]:disabled:checked + label:before,
.inputbox[type=radio]:disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  border-radius: 100%;
  background: #eceef3;
}

.inputbox[type=radio]:disabled:checked + label:after,
.inputbox[type=radio]:disabled:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #525b6b;
  position: absolute;
  top: 9px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.inputbox[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.inputbox[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.inputbox[type=radio]:checked + label:before {
  border-color: #2b495e;
}

/* Button Group */
ul.button_group {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.button_group > li {
  float: left;
  margin: 0 !important;
}
ul.button_group > li:first-child button {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  background-clip: padding-box;
  display: block;
  margin-left: 0;
}
ul.button_group > li:last-child button {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  display: block;
}

button.btn_group {
  width: auto;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 4px 10px;
  overflow: visible;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #505050;
  cursor: pointer;
  min-width: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin-left: -1px;
  min-width: 60px;
}
button.btn_group:hover, button.btn_group:focus {
  border-color: #72bf44;
  color: #72bf44;
  z-index: 1;
}
button.btn_group.btn_selected {
  border-color: #505050;
  background-color: #505050;
  color: #ffffff;
}
button.btn_group.btn_selected:hover, button.btn_group.btn_selected:focus {
  border-color: #505050;
  background-color: #505050;
  color: #ffffff;
}

button.btn_dropdown {
  border: none;
  text-align: left;
  border-color: #ababab;
}
button.btn_dropdown .btn_text,
button.btn_dropdown .btn_icon_arrow,
button.btn_dropdown .btn_icon,
button.btn_dropdown .pkg_icon,
button.btn_dropdown .pkg_name,
button.btn_dropdown .btn_indicator {
  pointer-events: none;
}
button.btn_dropdown .btn_text span,
button.btn_dropdown .btn_text div,
button.btn_dropdown .btn_icon_arrow span,
button.btn_dropdown .btn_icon_arrow div,
button.btn_dropdown .btn_icon span,
button.btn_dropdown .btn_icon div,
button.btn_dropdown .pkg_icon span,
button.btn_dropdown .pkg_icon div,
button.btn_dropdown .pkg_name span,
button.btn_dropdown .pkg_name div,
button.btn_dropdown .btn_indicator span,
button.btn_dropdown .btn_indicator div {
  pointer-events: none;
}
.dropdown_container {
  position: relative;
  display: inline-block;
}
.dropdown_container.full_width {
  display: block;
}
.dropdown_container.inline {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom;
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
  display: none;
  min-width: 180px;
  background: #ffffff;
  padding: 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: calc(50vh + 50px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  width: 340px;
  z-index: 1000001;
  color: #505050;
  text-align: left;
}
.dropdown_content.no_scroll_bar {
  max-height: inherit;
}
.dropdown_content.has_padding {
  padding: 10px;
}
.dropdown_content.position_right {
  right: 0;
}
.dropdown_content.full_width {
  left: 0;
  right: 0;
  min-width: inherit;
}
.dropdown_content.min_width {
  min-width: 320px;
}
.dropdown_content .dropdown_content_body {
  padding: 10px;
}
.dropdown_content .dropdown_content_body p:last-of-type {
  margin: 0;
  padding: 0;
}
.dropdown_content .dropdown_footer {
  display: block;
  padding: 10px 10px;
  border-top: 1px solid #dfe0e1;
}
.dropdown_content .dropdown_footer button {
  width: 100%;
}
.dropdown_content .dropdown_footer.align_center {
  text-align: center;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown_content container when the user clicks on the dropdown button) */
.dropdown_content.show {
  display: block;
}

.btn_drawer {
  border: none;
  display: block;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  padding: 5px 0 5px 20px;
  width: 100%;
  text-align: left;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: transparent;
  color: #505050;
  margin: 0px;
  overflow: visible;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 0px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn_drawer .btn_icon {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.btn_drawer:hover, .btn_drawer:focus {
  color: #51738b;
}
.btn_drawer:hover {
  background-color: #f5f6f7;
}
.btn_drawer.btn_drawer_active {
  color: #505050;
}
.btn_drawer.btn_drawer_active .btn_icon {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.drawer_container {
  /* Drawer Content (Hidden by Default) */
}
.drawer_container .drawer_header {
  position: relative;
  padding-left: 30px;
}
.drawer_container .drawer_header .drawer_header_toggle {
  position: absolute;
  left: 0;
  top: 0;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content {
  margin-left: 30px;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content .tree_link {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.drawer_container .drawer_header .drawer_header_title a {
  font-weight: 600;
}
.drawer_container .drawer_content {
  display: none;
  position: relative;
}
.drawer_container .drawer_content.drawer_active {
  display: block;
}

.drawer_active {
  font-weight: bold !important;
  color: #72bf44 !important;
}

.margin_r-5 {
  margin-right: 5px;
}

.margin_r-10 {
  margin-right: 10px;
}

.margin_r-15 {
  margin-right: 15px;
}

.margin_r-20 {
  margin-right: 20px;
}

.margin_r-25 {
  margin-right: 25px;
}

.margin_r-30 {
  margin-right: 30px;
}

.margin_l-5 {
  margin-left: 5px;
}

.margin_l-10 {
  margin-left: 10px;
}

.margin_l-15 {
  margin-left: 15px;
}

.margin_l-20 {
  margin-left: 20px;
}

.margin_l-25 {
  margin-left: 25px;
}

.margin_l-30 {
  margin-left: 30px;
}

.margin_t-5 {
  margin-top: 5px;
}

.margin_t-10 {
  margin-top: 10px;
}

.margin_t-15 {
  margin-top: 15px;
}

.margin_t-20 {
  margin-top: 20px;
}

.margin_t-25 {
  margin-top: 25px;
}

.margin_t-30 {
  margin-top: 30px;
}

.margin_b-5 {
  margin-bottom: 5px;
}

.margin_b-10 {
  margin-bottom: 10px;
}

.margin_b-15 {
  margin-bottom: 15px;
}

.margin_b-20 {
  margin-bottom: 20px;
}

.margin_b-25 {
  margin-bottom: 25px;
}

.margin_b-30 {
  margin-bottom: 30px;
}

.margin_5 {
  margin: 5px;
}

.margin_10 {
  margin: 10px;
}

.margin_15 {
  margin: 15px;
}

.margin_20 {
  margin: 20px;
}

.margin_25 {
  margin: 25px;
}

.margin_30 {
  margin: 30px;
}

.margin_h-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.margin_h-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.margin_h-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.margin_h-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.margin_h-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.margin_h-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.margin_v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin_v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin_v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin_v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin_v-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin_v-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.padding_r-5 {
  padding-right: 5px;
}

.padding_r-10 {
  padding-right: 10px;
}

.padding_r-15 {
  padding-right: 15px;
}

.padding_r-20 {
  padding-right: 20px;
}

.padding_r-25 {
  padding-right: 25px;
}

.padding_r-30 {
  padding-right: 30px;
}

.padding_l-5 {
  padding-left: 5px;
}

.padding_l-10 {
  padding-left: 10px;
}

.padding_l-15 {
  padding-left: 15px;
}

.padding_l-20 {
  padding-left: 20px;
}

.padding_l-25 {
  padding-left: 25px;
}

.padding_l-30 {
  padding-left: 30px;
}

.padding_t-5 {
  padding-top: 5px;
}

.padding_t-10 {
  padding-top: 10px;
}

.padding_t-15 {
  padding-top: 15px;
}

.padding_t-20 {
  padding-top: 20px;
}

.padding_t-25 {
  padding-top: 25px;
}

.padding_t-30 {
  padding-top: 30px;
}

.padding_b-5 {
  padding-bottom: 5px;
}

.padding_b-10 {
  padding-bottom: 10px;
}

.padding_b-15 {
  padding-bottom: 15px;
}

.padding_b-20 {
  padding-bottom: 20px;
}

.padding_b-25 {
  padding-bottom: 25px;
}

.padding_b-30 {
  padding-bottom: 30px;
}

.padding_5 {
  padding: 5px;
}

.padding_10 {
  padding: 10px;
}

.padding_15 {
  padding: 15px;
}

.padding_20 {
  padding: 20px;
}

.padding_25 {
  padding: 25px;
}

.padding_30 {
  padding: 30px;
}

.padding_h-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.padding_h-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.padding_h-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.padding_h-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.padding_h-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.padding_h-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.padding_v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding_v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding_v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding_v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding_v-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding_v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

table.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  empty-cells: show;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
table.dataTable.hidden_header thead {
  position: absolute;
  font-size: 0;
  padding: 0;
  height: 0;
  width: 0;
  display: block;
}
table.dataTable.hidden_header thead tr td, table.dataTable.hidden_header thead tr th {
  font-size: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0;
  filter: alpha(opacity=0);
}
table.dataTable.hidden_header tbody tr:first-child th, table.dataTable.hidden_header tbody tr:first-child td {
  border-top: 1px solid #dfe0e1;
}
table.dataTable thead tr th, table.dataTable thead tr td {
  text-align: left;
  border-bottom: 1px solid #dfe0e1;
  background-color: #eceef3;
  vertical-align: top;
  padding: 5px;
  line-height: 20px;
  min-height: 20px;
}
table.dataTable thead tr th.col_chbox, table.dataTable thead tr td.col_chbox {
  width: 20px;
  padding: 0 5px !important;
}
table.dataTable thead tr th.col_icon, table.dataTable thead tr td.col_icon {
  width: 20px;
}
table.dataTable thead tr th.col_coldchain, table.dataTable thead tr td.col_coldchain {
  width: 50px;
}
table.dataTable thead tr th [class^=icon_],
table.dataTable thead tr th [class*=" icon_"], table.dataTable thead tr td [class^=icon_],
table.dataTable thead tr td [class*=" icon_"] {
  font-size: 16px;
  display: inline-block;
  vertical-align: text-bottom;
}
table.dataTable thead tr th:empty, table.dataTable thead tr td:empty {
  height: 20px;
}
table.dataTable thead tr th.nowrap, table.dataTable thead tr td.nowrap {
  white-space: nowrap;
}
table.dataTable tbody tr th, table.dataTable tbody tr td {
  border-bottom: 1px solid #dfe0e1;
  vertical-align: top;
  padding: 5px;
  line-height: 20px;
  min-height: 20px;
}
table.dataTable tbody tr th.col_chbox, table.dataTable tbody tr td.col_chbox {
  width: 20px;
  padding: 0 5px !important;
}
table.dataTable tbody tr th.col_icon, table.dataTable tbody tr td.col_icon {
  width: 20px;
}
table.dataTable tbody tr th.col_coldchain, table.dataTable tbody tr td.col_coldchain {
  width: 50px;
}
table.dataTable tbody tr th:empty, table.dataTable tbody tr td:empty {
  height: 20px;
}
table.dataTable tbody tr th.nowrap, table.dataTable tbody tr td.nowrap {
  white-space: nowrap;
}
table.dataTable tbody tr th .table_btn_container, table.dataTable tbody tr td .table_btn_container {
  display: block;
  float: left;
  margin: 0px 0;
  vertical-align: top;
}
table.dataTable tbody tr th .table_btn_container button, table.dataTable tbody tr td .table_btn_container button {
  height: 30px;
  vertical-align: top;
}
table.dataTable tbody tr th [class^=icon_],
table.dataTable tbody tr th [class*=" icon_"], table.dataTable tbody tr td [class^=icon_],
table.dataTable tbody tr td [class*=" icon_"] {
  font-size: 16px;
  display: inline-block;
  vertical-align: text-bottom;
}
table.dataTable tbody tr:hover > td,
table.dataTable tbody tr:hover > th {
  background-color: rgba(81, 115, 139, 0.1);
}
table.dataTable tbody tr.row_swog > th:first-child,
table.dataTable tbody tr.row_swog > td:first-child {
  background-color: #f5f6f7;
}
table.dataTable tbody tr.row_multipiece > th,
table.dataTable tbody tr.row_multipiece > td {
  background-color: #ffffff;
}
table.dataTable tbody tr.selected_row > th,
table.dataTable tbody tr.selected_row > td {
  background-color: rgba(81, 115, 139, 0.2) !important;
}
table.dataTable tbody tr.selected_row > th a,
table.dataTable tbody tr.selected_row > td a {
  color: inherit;
}
table.dataTable tbody tr.row_hidden {
  display: none;
}
table.dataTable tbody tr.row_hidden.row_visible {
  display: table-row;
}
table.dataTable tbody tr.row_visible {
  display: table-row;
}

#table_selection_options {
  float: left;
  display: none;
  margin-left: 10px;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
}

.ui-widget {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

.hide_title_bar .ui-dialog-titlebar {
  display: none;
}

.ui-dialog {
  border: 0px solid !important;
  -webkit-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  padding: 0;
}
.ui-dialog .ui-dialog-content {
  padding: 0;
}
.ui-dialog .ui-dialog-content.maximized {
  width: 100% !important;
  height: calc(100% - 50px) !important;
  top: 50px !important;
  position: absolute !important;
}

.ui-widget-overlay {
  background: rgba(43, 73, 94, 0.3) !important;
  opacity: 1;
  filter: alpha(opacity=100);
}

.ui-widget button {
  font-size: 0.76rem;
}

.dialog_header {
  border-bottom: 0px solid #dfe0e1;
  padding: 15px 15px 0;
}
.dialog_header .dialog_back {
  position: absolute;
  left: 10px;
  top: 10px;
}
.dialog_header .dialog_close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  background-color: #ffffff;
}
.dialog_header h1 {
  font-size: 1.2em;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  color: #2b495e;
}
.dialog_header .dialog_desc {
  text-align: center;
  color: #525b6b;
  margin: 10px 0;
  padding: 0;
}

/* Dialog Content */
.dialog_content {
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* Dialog Actions */
}
.dialog_content.padding--all {
  padding: 15px;
}
.dialog_content.padding--left {
  padding-left: 15px;
}
.dialog_content.padding--right {
  padding-right: 15px;
}
.dialog_content.padding--top {
  padding-top: 15px;
}
.dialog_content.padding--bottom {
  padding-bottom: 15px;
}
.dialog_content.padding--vertical {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dialog_content.padding--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}
.dialog_content .dialog_content_inner {
  padding: 15px;
  overflow: auto;
  position: relative;
}
.dialog_content .dialog_content_inner.no_padding {
  padding: 0;
}
@media (max-width: 767px) {
  .dialog_content .dialog_content_inner {
    padding: 15px 15px 10px;
  }
}
.dialog_content .dialog_content_inner:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.dialog_content .dialog_inner_heading_below {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1em;
  text-align: center;
}
.dialog_content .dialog_actions {
  padding: 15px;
  border-top: 1px solid #dfe0e1;
}
.dialog_content .dialog_actions.fixed_width {
  min-width: 480px;
  max-width: 480px;
}
@media (max-width: 767px) {
  .dialog_content .dialog_actions.fixed_width {
    min-width: 290px;
    max-width: 290px;
  }
}
.dialog_content .dialog_actions .actions_left {
  float: left;
}
.dialog_content .dialog_actions .actions_left button,
.dialog_content .dialog_actions .actions_left input[type=button] {
  margin-right: 10px;
  float: left;
}
.dialog_content .dialog_actions .actions_left button:last-of-type,
.dialog_content .dialog_actions .actions_left input[type=button]:last-of-type {
  margin-right: 0;
}
.dialog_content .dialog_actions .actions_right {
  float: right;
}
.dialog_content .dialog_actions .actions_right button,
.dialog_content .dialog_actions .actions_right input[type=button] {
  margin-left: 10px;
  float: left;
}
.dialog_content .dialog_actions .actions_right button:first-of-type,
.dialog_content .dialog_actions .actions_right input[type=button]:first-of-type {
  margin-left: 0;
}
.dialog_content .dialog_actions .actions_center {
  float: none;
  text-align: center;
}
.dialog_content .dialog_actions .actions_center button,
.dialog_content .dialog_actions .actions_center input[type=button] {
  margin: 0 5px;
}

/* Dialog Actions */
.dialog_actions {
  padding: 15px;
  position: relative;
}
.dialog_actions:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  position: absolute;
}
.dialog_actions.fixed_width {
  min-width: 480px;
  max-width: 480px;
}
@media (max-width: 767px) {
  .dialog_actions.fixed_width {
    min-width: 290px;
    max-width: 290px;
  }
}
.dialog_actions .actions_left {
  float: left;
}
.dialog_actions .actions_left button,
.dialog_actions .actions_left input[type=button] {
  margin-right: 10px;
  float: left;
}
.dialog_actions .actions_left button:last-of-type,
.dialog_actions .actions_left input[type=button]:last-of-type {
  margin-right: 0;
}
.dialog_actions .actions_right {
  float: right;
}
.dialog_actions .actions_right button,
.dialog_actions .actions_right input[type=button] {
  margin-left: 10px;
  float: left;
}
.dialog_actions .actions_right button:first-of-type,
.dialog_actions .actions_right input[type=button]:first-of-type {
  margin-left: 0;
}
.dialog_actions .actions_center {
  float: none;
  text-align: center;
}
.dialog_actions .actions_center button,
.dialog_actions .actions_center input[type=button] {
  margin: 0 5px;
}

/* Button Group for Adding Order */
.button_group > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
}
.button_group > ul > li {
  float: left;
  width: 33.3333333333%;
  margin: 0px 0;
}

.modal_footer_total {
  position: relative;
}
.modal_footer_total .modal_footer_total_value {
  line-height: 30px;
  font-size: 1.4rem;
  font-weight: 600;
  top: 0px;
  position: relative;
}

.dialog_tabs {
  position: relative;
}
.dialog_tabs .dialog_close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
.dialog_tabs .dialog_close button {
  color: #ffffff;
}

#tab_nav {
  padding: 0px 0px 0;
  position: relative;
}
#tab_nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  height: 40px;
}
#tab_nav > ul:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
#tab_nav > ul > li {
  display: block;
  float: left;
  height: 100%;
}

button.btn_tab {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: transparent;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.03));
  position: relative;
  color: #ffffff;
  border-bottom: 0px solid #cccccc;
  text-transform: inherit;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
}
button.btn_tab.btn_text {
  line-height: 0;
}
button.btn_tab:hover, button.btn_tab:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
button.btn_tab.btn_tab_active {
  background: transparent;
  background-color: #ffffff !important;
  color: #2b495e;
}
button.btn_tab.btn_tab_active:hover, button.btn_tab.btn_tab_active:focus {
  background: transparent;
  background-color: #ffffff !important;
  color: #2b495e;
}
button.btn_tab.btn_tab_active:after {
  display: none;
}

.tab_content {
  display: none;
  padding: 0px;
}
.tab_content.tab_content_active {
  display: block;
}

.panel_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  min-height: 0;
  width: 100%;
  -webkit-justify-content: flex-start;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: column;
}
.panel_container .panel_header {
  position: relative;
  padding-bottom: 10px;
}
.panel_container .panel_header.no_padding {
  padding-bottom: 0;
}
.panel_container .panel_header .panel_header_actions {
  position: absolute;
  right: 0;
  top: 0;
}
.panel_container .panel_controls {
  padding-bottom: 10px;
}
.panel_container .panel_content {
  flex: 1;
  overflow: auto;
}
.panel_container .panel_content .panel_content_inner {
  overflow: auto;
}

.toggle_switch_radio[type=radio]:checked,
.toggle_switch_radio[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.toggle_switch_radio[type=radio]:checked + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #505050;
  background-color: #ffffff;
  padding: 4px 10px 4px 10px !important;
  text-align: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dfe0e1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggle_switch_radio[type=radio]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: rgba(80, 80, 80, 0.5);
  padding: 4px 10px 4px 10px !important;
  text-align: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eceef3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggle_switch_radio[type=radio]:focus + label,
.toggle_switch_radio[type=radio]:hover + label {
  color: #505050;
}

.toggle_switch_radio[type=radio]:disabled:checked + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 4px 0 4px 0px;
}

.toggle_switch_radio[type=radio]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 4px 0 4px 0px;
}

.toggle_switch {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.toggle_switch .toggle_switch_option {
  flex-grow: 1;
}

.dropzone {
  padding-top: 5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4.5rem 1rem;
  position: relative;
  border: 3px dashed #dfe0e1;
}
.dropzone .dz-message {
  text-align: center;
}
.dropzone .dz-message .dz_msg_icon {
  color: #2b495e;
}
.dropzone .dz-message .dz_msg_icon span {
  vertical-align: text-bottom;
}
.dropzone .dz-message .dz_msg_instruction {
  color: #2b495e;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
}
.dropzone .dz-message .dz_msg_helper {
  line-height: 140%;
  margin-bottom: 10px;
}
.dropzone .dz-preview .dz-details {
  text-align: center;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: inline-block;
}
.dropzone .dz-preview .dz-details .dz-size {
  display: inline-block;
}
.dropzone .dz-preview .dz-details img {
  display: none;
}
.dropzone .dz-preview .dz-success-mark {
  display: inline-block;
  text-align: center;
  display: none;
}
.dropzone .dz-preview .dz-error-mark {
  display: inline-block;
  text-align: center;
  display: none;
}
.dropzone .dz-preview .dz-error-message {
  color: #ea5441;
  line-height: 140%;
  margin-top: 20px;
  text-align: center;
}

[tooltip] {
  position: relative;
}

[tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  z-index: 100;
  opacity: 0;
  filter: alpha(opacity=0);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  color: #ffffff;
  padding: 5px 5px;
  font-size: 0.8rem;
  line-height: 140%;
  min-width: 140px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 101;
}

[tooltip-position=left]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}

[tooltip-position=top]::before {
  left: 50%;
}

[tooltip-position=bottom]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip-position=right]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip-position=left]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

[tooltip-position=top]::after {
  left: 50%;
}

[tooltip-position=bottom]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

[tooltip-position=right]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  filter: alpha(opacity=100);
}

/*

    HELPERS

*/
.link {
  cursor: pointer;
}

.full-size, .modal-body .component-body .panel-left,
.modal-body .component-body .panel-right, app-account-tree,
app-equation-bar,
app-exogenous-lines-equations,
app-exogenous-lines,
app-hover-notification,
app-import-alm-dataset,
app-input-structure,
app-invalid-rows,
app-liquidity-sources,
app-liquity-equations,
app-lookup-data,
app-lookup-setup,
app-lookup-tree,
app-root,
app-balance-sheet,
app-scenario-list,
app-simulation-set,
app-template,
gc-spread-sheets {
  height: 100%;
  width: 100%;
}

.display-content, app-account-tree,
app-lookup-tree,
app-manage-results {
  display: contents;
}

.page-container,
.fill {
  height: 100%;
  width: 100%;
  display: contents;
}

.bold {
  font-weight: bold;
}

.hidden,
.invisible {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.flex {
  display: flex;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.border--left {
  border-left: 1px solid #dfe0e1;
}

.border--right {
  border-right: 1px solid #dfe0e1;
}

.border--bottom, .notification-banner-message {
  border-bottom: 1px solid #dfe0e1;
}

.border--top {
  border-top: 1px solid #dfe0e1;
}

.border--all {
  border: 1px solid #dfe0e1;
}

.shadow, .top-inner-shadow:before, .notification-banner-message:before {
  content: "";
  position: relative;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 10px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  border: 0;
  overflow: hidden;
}

.top-inner-shadow:before, .notification-banner-message:before {
  top: -11px;
}

#login_container {
  max-width: 400px;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 40px auto;
}

.product_suite_container {
  text-align: center;
  margin-top: 20px;
}

.product_suit_options {
  max-width: 720px;
  margin: 0 auto;
}

ul#product_suite {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#product_suite > li {
  float: left;
  width: 25%;
  text-align: center;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul#product_suite > li .btn_product {
  display: block;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  text-align: center;
  padding: 20px;
  color: inherit;
  width: 160px;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
ul#product_suite > li .btn_product:hover, ul#product_suite > li .btn_product:focus {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
}
ul#product_suite > li .btn_product:hover .application_icon, ul#product_suite > li .btn_product:focus .application_icon {
  background-color: #51738b;
  color: #ffffff;
}
ul#product_suite > li .btn_product:hover .application_name, ul#product_suite > li .btn_product:focus .application_name {
  color: #51738b;
}
ul#product_suite > li .btn_product.btn_normal:disabled, ul#product_suite > li .btn_product.success-button:disabled, ul#product_suite > li .btn_product.btn_small:disabled, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled {
  background-color: #f5f6f7 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  box-shadow: none !important;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover, ul#product_suite > li .btn_product.success-button:disabled:hover, ul#product_suite > li .btn_product.btn_small:disabled:hover, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover, ul#product_suite > li .btn_product.btn_normal:disabled:focus, ul#product_suite > li .btn_product.success-button:disabled:focus, ul#product_suite > li .btn_product.btn_small:disabled:focus, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus {
  background-color: #f5f6f7 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  box-shadow: none !important;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover .application_icon, ul#product_suite > li .btn_product.success-button:disabled:hover .application_icon, ul#product_suite > li .btn_product.btn_small:disabled:hover .application_icon, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover .application_icon, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover .application_icon, ul#product_suite > li .btn_product.btn_normal:disabled:focus .application_icon, ul#product_suite > li .btn_product.success-button:disabled:focus .application_icon, ul#product_suite > li .btn_product.btn_small:disabled:focus .application_icon, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus .application_icon, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus .application_icon {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover .application_name, ul#product_suite > li .btn_product.success-button:disabled:hover .application_name, ul#product_suite > li .btn_product.btn_small:disabled:hover .application_name, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover .application_name, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover .application_name, ul#product_suite > li .btn_product.btn_normal:disabled:focus .application_name, ul#product_suite > li .btn_product.success-button:disabled:focus .application_name, ul#product_suite > li .btn_product.btn_small:disabled:focus .application_name, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus .application_name, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus .application_name {
  color: inherit;
}
ul#product_suite > li .btn_product .application_icon {
  display: block;
  padding: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: rgba(0, 0, 0, 0.05);
  width: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto 10px;
}
ul#product_suite > li .btn_product .application_icon span {
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  display: block;
  vertical-align: top;
}
ul#product_suite > li .btn_product .application_name {
  text-align: center;
  display: block;
  line-height: 15px;
  min-height: 30px;
  font-size: 0.8rem;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: scroll; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #000000;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 8px;
  height: 3px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

/****** Spreadsheet ********/
/********** Base **********/
/********** Colours **********/
/********** Pages **********/
/********** Modal **********/
/********** Button **********/
/******* Icons ********/
/******* Icons ********/
/******* Media Queries ********/
html {
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  text-rendering: optimizelegibility;
}

body {
  font-size: 0.86rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 400;
  color: #505050;
  background: #ffffff;
  -webkit-text-size-adjust: none;
  margin: 0px;
  padding: 0;
}
body.no_scroll {
  height: 100%;
  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
}

abbr {
  text-decoration: none;
}

a:link,
a:visited {
  color: #2b495e;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  color: #72bf44;
}

p {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  line-height: 140%;
}

*:focus {
  outline: none;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

.overflow {
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
  overflow: auto;
}

.hidden_text {
  position: absolute !important;
  font-size: 0 !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.space_divider {
  height: 10px;
  display: block;
}
.space_divider.extra_padding {
  margin-top: 10px;
  margin-bottom: 10px;
}

.line_divider {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  display: block;
}
.line_divider.extra_padding {
  margin-top: 30px;
  margin-bottom: 30px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.outcome_icon {
  font-size: 80px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 20px auto 20px;
}
.outcome_icon.success {
  color: #72bf44;
}
.outcome_icon.error {
  color: #ea5441;
}

.outcome_title {
  font-size: 1.4rem;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
}
.outcome_title.success {
  color: #72bf44;
}
.outcome_title.error {
  color: #ea5441;
}

.outcome_text {
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #000000;
  border-color: #000000 transparent #000000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#vp {
  border-top: 0px solid #dfe0e1;
}

.chevron_toggle_icon {
  font-size: 2em;
  font-style: normal;
  cursor: pointer;
}
.chevron_toggle_icon:before {
  content: "\e900";
  font-family: "empyrean_icons";
  font-size: 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 10px;
  left: 0px;
  line-height: 10px;
}
.chevron_toggle_icon.expanded:before {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon_spinner {
  display: inline-block;
  margin-right: 5px;
  -webkit-animation: rotate-center 0.6s ease-in infinite both;
  animation: rotate-center 0.6s ease-in infinite both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*
* Global Styles goes here
*/
#page_container_outer {
  height: 100vh;
  overflow: hidden;
}

.page_inner_container {
  width: 100%;
  display: block;
  overflow: hidden;
}

#page_container {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
}
#page_container #page_header {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_container #page_content {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
  height: 100%;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
}
#page_container #page_content:after {
  content: "\e905";
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(43, 73, 94, 0.1);
  font-family: "empyrean_icons";
  font-size: 200px;
  z-index: -1;
}
#page_container #page_content.no_logo:after {
  display: none;
}
#page_container #page_content #page_content_inner {
  padding: 0px;
}
#page_container #page_content #page_content_inner .input-structure {
  height: calc(100vh - 153px);
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
}
#page_container #page_content #page_content_inner .input-structure #results {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
}
#page_container #page_footer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

a.text_link {
  cursor: pointer;
}

input,
button,
textarea {
  font-size: 1rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn_normal, .success-button, .btn_small, .notification-banner-message .banner-actions a,
.notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions button {
  width: auto;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 5px 10px;
  overflow: visible;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 0px solid;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.76rem;
}
.btn_normal:hover, .success-button:hover, .btn_small:hover, .notification-banner-message .banner-actions a:hover,
.notification-banner-message .banner-actions .link:hover,
.notification-banner-message .banner-actions .banner-action-button:hover,
.notification-banner-message .banner-actions button:hover, .btn_normal:focus, .success-button:focus, .btn_small:focus, .notification-banner-message .banner-actions a:focus,
.notification-banner-message .banner-actions .link:focus,
.notification-banner-message .banner-actions .banner-action-button:focus,
.notification-banner-message .banner-actions button:focus {
  color: #51738b;
}
.btn_normal:disabled, .success-button:disabled, .btn_small:disabled, .notification-banner-message .banner-actions a:disabled,
.notification-banner-message .banner-actions .link:disabled,
.notification-banner-message .banner-actions .banner-action-button:disabled,
.notification-banner-message .banner-actions button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal:disabled:hover, .success-button:disabled:hover, .btn_small:disabled:hover, .notification-banner-message .banner-actions a:disabled:hover,
.notification-banner-message .banner-actions .link:disabled:hover,
.notification-banner-message .banner-actions .banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button:disabled:hover, .btn_normal:disabled:focus, .success-button:disabled:focus, .btn_small:disabled:focus, .notification-banner-message .banner-actions a:disabled:focus,
.notification-banner-message .banner-actions .link:disabled:focus,
.notification-banner-message .banner-actions .banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_green, .btn_green.btn_small, .notification-banner-message .banner-actions a.btn_green,
.notification-banner-message .banner-actions .btn_green.link,
.notification-banner-message .banner-actions .btn_green.banner-action-button,
.notification-banner-message .banner-actions button.btn_green, .success-button, .notification-banner-message .banner-actions a.success-button,
.notification-banner-message .banner-actions .link.success-button,
.notification-banner-message .banner-actions .banner-action-button.success-button,
.notification-banner-message .banner-actions button.success-button {
  background-color: #72bf44;
  color: #ffffff;
}
.btn_normal.btn_green:focus, .btn_green.btn_small:focus, .notification-banner-message .banner-actions a.btn_green:focus,
.notification-banner-message .banner-actions .btn_green.link:focus,
.notification-banner-message .banner-actions .btn_green.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_green:focus, .success-button:focus, .notification-banner-message .banner-actions a.success-button:focus,
.notification-banner-message .banner-actions .link.success-button:focus,
.notification-banner-message .banner-actions .banner-action-button.success-button:focus,
.notification-banner-message .banner-actions button.success-button:focus, .btn_normal.btn_green:hover, .btn_green.btn_small:hover, .notification-banner-message .banner-actions a.btn_green:hover,
.notification-banner-message .banner-actions .btn_green.link:hover,
.notification-banner-message .banner-actions .btn_green.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_green:hover, .success-button:hover, .notification-banner-message .banner-actions a.success-button:hover,
.notification-banner-message .banner-actions .link.success-button:hover,
.notification-banner-message .banner-actions .banner-action-button.success-button:hover,
.notification-banner-message .banner-actions button.success-button:hover {
  background-color: #5b9b35;
  color: #ffffff;
}
.btn_normal.btn_green:disabled, .btn_green.btn_small:disabled, .notification-banner-message .banner-actions a.btn_green:disabled,
.notification-banner-message .banner-actions .btn_green.link:disabled,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_green:disabled, .success-button:disabled, .notification-banner-message .banner-actions a.success-button:disabled,
.notification-banner-message .banner-actions .link.success-button:disabled,
.notification-banner-message .banner-actions .banner-action-button.success-button:disabled,
.notification-banner-message .banner-actions button.success-button:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_green:disabled:hover, .btn_green.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_green:disabled:hover,
.notification-banner-message .banner-actions .btn_green.link:disabled:hover,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_green:disabled:hover, .success-button:disabled:hover, .btn_normal.btn_green:disabled:focus, .btn_green.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_green:disabled:focus,
.notification-banner-message .banner-actions .btn_green.link:disabled:focus,
.notification-banner-message .banner-actions .btn_green.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_green:disabled:focus, .success-button:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_blue, .btn_blue.success-button, .btn_blue.btn_small, .notification-banner-message .banner-actions a.btn_blue,
.notification-banner-message .banner-actions .btn_blue.link,
.notification-banner-message .banner-actions .btn_blue.banner-action-button,
.notification-banner-message .banner-actions button.btn_blue {
  background-color: #51738b;
  color: #ffffff;
}
.btn_normal.btn_blue:focus, .btn_blue.success-button:focus, .btn_blue.btn_small:focus, .notification-banner-message .banner-actions a.btn_blue:focus,
.notification-banner-message .banner-actions .btn_blue.link:focus,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_blue:focus, .btn_normal.btn_blue:hover, .btn_blue.success-button:hover, .btn_blue.btn_small:hover, .notification-banner-message .banner-actions a.btn_blue:hover,
.notification-banner-message .banner-actions .btn_blue.link:hover,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_blue:hover {
  background-color: #3e586b;
  color: #ffffff;
}
.btn_normal.btn_blue:disabled, .btn_blue.success-button:disabled, .btn_blue.btn_small:disabled, .notification-banner-message .banner-actions a.btn_blue:disabled,
.notification-banner-message .banner-actions .btn_blue.link:disabled,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_blue:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_blue:disabled:hover, .btn_blue.success-button:disabled:hover, .btn_blue.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_blue:disabled:hover,
.notification-banner-message .banner-actions .btn_blue.link:disabled:hover,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_blue:disabled:hover, .btn_normal.btn_blue:disabled:focus, .btn_blue.success-button:disabled:focus, .btn_blue.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_blue:disabled:focus,
.notification-banner-message .banner-actions .btn_blue.link:disabled:focus,
.notification-banner-message .banner-actions .btn_blue.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_blue:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_white, .btn_white.success-button, .btn_white.btn_small, .notification-banner-message .banner-actions a.btn_white,
.notification-banner-message .banner-actions .btn_white.link,
.notification-banner-message .banner-actions .btn_white.banner-action-button,
.notification-banner-message .banner-actions button.btn_white {
  background-color: transparent;
  color: #ffffff;
}
.btn_normal.btn_white:focus, .btn_white.success-button:focus, .btn_white.btn_small:focus, .notification-banner-message .banner-actions a.btn_white:focus,
.notification-banner-message .banner-actions .btn_white.link:focus,
.notification-banner-message .banner-actions .btn_white.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_white:focus, .btn_normal.btn_white:hover, .btn_white.success-button:hover, .btn_white.btn_small:hover, .notification-banner-message .banner-actions a.btn_white:hover,
.notification-banner-message .banner-actions .btn_white.link:hover,
.notification-banner-message .banner-actions .btn_white.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_white:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.btn_normal.btn_white:disabled, .btn_white.success-button:disabled, .btn_white.btn_small:disabled, .notification-banner-message .banner-actions a.btn_white:disabled,
.notification-banner-message .banner-actions .btn_white.link:disabled,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_white:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_white:disabled:hover, .btn_white.success-button:disabled:hover, .btn_white.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_white:disabled:hover,
.notification-banner-message .banner-actions .btn_white.link:disabled:hover,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_white:disabled:hover, .btn_normal.btn_white:disabled:focus, .btn_white.success-button:disabled:focus, .btn_white.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_white:disabled:focus,
.notification-banner-message .banner-actions .btn_white.link:disabled:focus,
.notification-banner-message .banner-actions .btn_white.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_white:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_notification, .btn_notification.success-button, .btn_notification.btn_small, .notification-banner-message .banner-actions a.btn_notification,
.notification-banner-message .banner-actions .btn_notification.link,
.notification-banner-message .banner-actions .btn_notification.banner-action-button,
.notification-banner-message .banner-actions button.btn_notification {
  background-color: #ff9d00;
  color: #ffffff;
}
.btn_normal.btn_notification:focus, .btn_notification.success-button:focus, .btn_notification.btn_small:focus, .notification-banner-message .banner-actions a.btn_notification:focus,
.notification-banner-message .banner-actions .btn_notification.link:focus,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_notification:focus, .btn_normal.btn_notification:hover, .btn_notification.success-button:hover, .btn_notification.btn_small:hover, .notification-banner-message .banner-actions a.btn_notification:hover,
.notification-banner-message .banner-actions .btn_notification.link:hover,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_notification:hover {
  background-color: #cc7e00;
  color: #ffffff;
}
.btn_normal.btn_notification:disabled, .btn_notification.success-button:disabled, .btn_notification.btn_small:disabled, .notification-banner-message .banner-actions a.btn_notification:disabled,
.notification-banner-message .banner-actions .btn_notification.link:disabled,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_notification:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
}
.btn_normal.btn_notification:disabled:hover, .btn_notification.success-button:disabled:hover, .btn_notification.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_notification:disabled:hover,
.notification-banner-message .banner-actions .btn_notification.link:disabled:hover,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_notification:disabled:hover, .btn_normal.btn_notification:disabled:focus, .btn_notification.success-button:disabled:focus, .btn_notification.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_notification:disabled:focus,
.notification-banner-message .banner-actions .btn_notification.link:disabled:focus,
.notification-banner-message .banner-actions .btn_notification.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_notification:disabled:focus {
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_outline_white, .btn_outline_white.success-button, .btn_outline_white.btn_small, .notification-banner-message .banner-actions a.btn_outline_white,
.notification-banner-message .banner-actions .btn_outline_white.link,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button,
.notification-banner-message .banner-actions button.btn_outline_white {
  background-color: transparent;
  color: #ffffff;
  padding: 4px 9px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_outline_white:focus, .btn_outline_white.success-button:focus, .btn_outline_white.btn_small:focus, .notification-banner-message .banner-actions a.btn_outline_white:focus,
.notification-banner-message .banner-actions .btn_outline_white.link:focus,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_outline_white:focus, .btn_normal.btn_outline_white:hover, .btn_outline_white.success-button:hover, .btn_outline_white.btn_small:hover, .notification-banner-message .banner-actions a.btn_outline_white:hover,
.notification-banner-message .banner-actions .btn_outline_white.link:hover,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_outline_white:hover {
  background-color: #ffffff;
  color: #505050;
  border: 0px solid #ffffff;
  padding: 5px 10px;
}
.btn_normal.btn_outline_white.btn_no_border, .btn_outline_white.btn_no_border.success-button, .btn_outline_white.btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_outline_white.btn_no_border,
.notification-banner-message .banner-actions .btn_outline_white.btn_no_border.link,
.notification-banner-message .banner-actions .btn_outline_white.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_outline_white.btn_no_border {
  padding: 5px 10px;
}
.btn_normal.btn_outline_white:disabled, .btn_outline_white.success-button:disabled, .btn_outline_white.btn_small:disabled, .notification-banner-message .banner-actions a.btn_outline_white:disabled,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_outline_white:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_outline_white:disabled:hover, .btn_outline_white.success-button:disabled:hover, .btn_outline_white.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_outline_white:disabled:hover,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled:hover,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_outline_white:disabled:hover, .btn_normal.btn_outline_white:disabled:focus, .btn_outline_white.success-button:disabled:focus, .btn_outline_white.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_outline_white:disabled:focus,
.notification-banner-message .banner-actions .btn_outline_white.link:disabled:focus,
.notification-banner-message .banner-actions .btn_outline_white.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_outline_white:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_negative, .btn_negative.success-button, .btn_negative.btn_small, .notification-banner-message .banner-actions a.btn_negative,
.notification-banner-message .banner-actions .btn_negative.link,
.notification-banner-message .banner-actions .btn_negative.banner-action-button,
.notification-banner-message .banner-actions button.btn_negative {
  background-color: transparent;
  color: #2b495e;
  padding: 4px 9px;
  border: 1px solid #2b495e;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_negative:focus, .btn_negative.success-button:focus, .btn_negative.btn_small:focus, .notification-banner-message .banner-actions a.btn_negative:focus,
.notification-banner-message .banner-actions .btn_negative.link:focus,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_negative:focus, .btn_normal.btn_negative:hover, .btn_negative.success-button:hover, .btn_negative.btn_small:hover, .notification-banner-message .banner-actions a.btn_negative:hover,
.notification-banner-message .banner-actions .btn_negative.link:hover,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_negative:hover {
  background-color: #2b495e;
  color: #ffffff;
  border: 0px solid #2b495e;
  padding: 5px 10px;
}
.btn_normal.btn_negative:disabled, .btn_negative.success-button:disabled, .btn_negative.btn_small:disabled, .notification-banner-message .banner-actions a.btn_negative:disabled,
.notification-banner-message .banner-actions .btn_negative.link:disabled,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_negative:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_negative:disabled:hover, .btn_negative.success-button:disabled:hover, .btn_negative.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_negative:disabled:hover,
.notification-banner-message .banner-actions .btn_negative.link:disabled:hover,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_negative:disabled:hover, .btn_normal.btn_negative:disabled:focus, .btn_negative.success-button:disabled:focus, .btn_negative.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_negative:disabled:focus,
.notification-banner-message .banner-actions .btn_negative.link:disabled:focus,
.notification-banner-message .banner-actions .btn_negative.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_negative:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_negative.no_focus:focus, .btn_negative.no_focus.success-button:focus, .btn_negative.no_focus.btn_small:focus, .notification-banner-message .banner-actions a.btn_negative.no_focus:focus,
.notification-banner-message .banner-actions .btn_negative.no_focus.link:focus,
.notification-banner-message .banner-actions .btn_negative.no_focus.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_negative.no_focus:focus {
  background-color: transparent;
  color: #2b495e;
  padding: 4px 9px;
  border: 1px solid #2b495e;
}
.btn_normal.btn_green_outline, .btn_green_outline.success-button, .btn_green_outline.btn_small, .notification-banner-message .banner-actions a.btn_green_outline,
.notification-banner-message .banner-actions .btn_green_outline.link,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button,
.notification-banner-message .banner-actions button.btn_green_outline {
  background-color: transparent;
  color: #72bf44;
  padding: 4px 9px;
  border: 1px solid #72bf44;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_green_outline:focus, .btn_green_outline.success-button:focus, .btn_green_outline.btn_small:focus, .notification-banner-message .banner-actions a.btn_green_outline:focus,
.notification-banner-message .banner-actions .btn_green_outline.link:focus,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_green_outline:focus, .btn_normal.btn_green_outline:hover, .btn_green_outline.success-button:hover, .btn_green_outline.btn_small:hover, .notification-banner-message .banner-actions a.btn_green_outline:hover,
.notification-banner-message .banner-actions .btn_green_outline.link:hover,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_green_outline:hover {
  background-color: #72bf44;
  color: #ffffff;
  border: 0px solid #72bf44;
  padding: 5px 10px;
}
.btn_normal.btn_green_outline:disabled, .btn_green_outline.success-button:disabled, .btn_green_outline.btn_small:disabled, .notification-banner-message .banner-actions a.btn_green_outline:disabled,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_green_outline:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  border: 0px solid !important;
  padding: 5px 10px;
}
.btn_normal.btn_green_outline:disabled:hover, .btn_green_outline.success-button:disabled:hover, .btn_green_outline.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_green_outline:disabled:hover,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled:hover,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_green_outline:disabled:hover, .btn_normal.btn_green_outline:disabled:focus, .btn_green_outline.success-button:disabled:focus, .btn_green_outline.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_green_outline:disabled:focus,
.notification-banner-message .banner-actions .btn_green_outline.link:disabled:focus,
.notification-banner-message .banner-actions .btn_green_outline.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_green_outline:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0.2) !important;
  color: #cccccc !important;
}
.btn_normal.btn_add_item, .btn_add_item.success-button, .btn_add_item.btn_small, .notification-banner-message .banner-actions a.btn_add_item,
.notification-banner-message .banner-actions .btn_add_item.link,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button,
.notification-banner-message .banner-actions button.btn_add_item {
  background-color: transparent;
  color: #2b495e;
  padding: 5px 0px;
  border: 0px solid #2b495e;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease;
}
.btn_normal.btn_add_item:focus, .btn_add_item.success-button:focus, .btn_add_item.btn_small:focus, .notification-banner-message .banner-actions a.btn_add_item:focus,
.notification-banner-message .banner-actions .btn_add_item.link:focus,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_add_item:focus, .btn_normal.btn_add_item:hover, .btn_add_item.success-button:hover, .btn_add_item.btn_small:hover, .notification-banner-message .banner-actions a.btn_add_item:hover,
.notification-banner-message .banner-actions .btn_add_item.link:hover,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_add_item:hover {
  background-color: transparent;
  color: #72bf44;
  border: 0px solid #72bf44;
  padding: 5px 0px;
}
.btn_normal.btn_add_item:disabled, .btn_add_item.success-button:disabled, .btn_add_item.btn_small:disabled, .notification-banner-message .banner-actions a.btn_add_item:disabled,
.notification-banner-message .banner-actions .btn_add_item.link:disabled,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled,
.notification-banner-message .banner-actions button.btn_add_item:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: rgba(245, 246, 247, 0) !important;
  border: 0px solid !important;
  padding: 5px 0px;
}
.btn_normal.btn_add_item:disabled:hover, .btn_add_item.success-button:disabled:hover, .btn_add_item.btn_small:disabled:hover, .notification-banner-message .banner-actions a.btn_add_item:disabled:hover,
.notification-banner-message .banner-actions .btn_add_item.link:disabled:hover,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions button.btn_add_item:disabled:hover, .btn_normal.btn_add_item:disabled:focus, .btn_add_item.success-button:disabled:focus, .btn_add_item.btn_small:disabled:focus, .notification-banner-message .banner-actions a.btn_add_item:disabled:focus,
.notification-banner-message .banner-actions .btn_add_item.link:disabled:focus,
.notification-banner-message .banner-actions .btn_add_item.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions button.btn_add_item:disabled:focus {
  border-color: #dfe0e1 !important;
  background-color: rgba(245, 246, 247, 0) !important;
  color: #cccccc !important;
}
.btn_normal.btn_icon_only, .btn_icon_only.success-button, .btn_icon_only.btn_small, .notification-banner-message .banner-actions a.btn_icon_only,
.notification-banner-message .banner-actions .btn_icon_only.link,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_only {
  padding: 4px 4px;
}
.btn_normal.btn_icon_only.btn_no_border, .btn_icon_only.btn_no_border.success-button, .btn_icon_only.btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_icon_only.btn_no_border,
.notification-banner-message .banner-actions .btn_icon_only.btn_no_border.link,
.notification-banner-message .banner-actions .btn_icon_only.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_only.btn_no_border {
  padding: 5px 5px;
}
.btn_normal.btn_icon_only.btn_icon_small .btn_icon span, .btn_icon_only.btn_icon_small.success-button .btn_icon span, .btn_icon_only.btn_icon_small.btn_small .btn_icon span, .notification-banner-message .banner-actions a.btn_icon_only.btn_icon_small .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.link .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.banner-action-button .btn_icon span,
.notification-banner-message .banner-actions button.btn_icon_only.btn_icon_small .btn_icon span,
.btn_normal.btn_icon_only.btn_icon_small .btn_status span,
.btn_icon_only.btn_icon_small.success-button .btn_status span,
.btn_icon_only.btn_icon_small.btn_small .btn_status span,
.notification-banner-message .banner-actions a.btn_icon_only.btn_icon_small .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.link .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.btn_icon_small.banner-action-button .btn_status span,
.notification-banner-message .banner-actions button.btn_icon_only.btn_icon_small .btn_status span {
  font-size: 12px;
}
.btn_normal.btn_icon_only .btn_icon, .btn_icon_only.success-button .btn_icon, .btn_icon_only.btn_small .btn_icon, .notification-banner-message .banner-actions a.btn_icon_only .btn_icon,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_icon,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_icon,
.notification-banner-message .banner-actions button.btn_icon_only .btn_icon,
.btn_normal.btn_icon_only .btn_status,
.btn_icon_only.success-button .btn_status,
.btn_icon_only.btn_small .btn_status,
.notification-banner-message .banner-actions a.btn_icon_only .btn_status,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_status,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_status,
.notification-banner-message .banner-actions button.btn_icon_only .btn_status {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  display: block;
  vertical-align: text-bottom;
}
.btn_normal.btn_icon_only .btn_icon span, .btn_icon_only.success-button .btn_icon span, .btn_icon_only.btn_small .btn_icon span, .notification-banner-message .banner-actions a.btn_icon_only .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_icon span,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_icon span,
.notification-banner-message .banner-actions button.btn_icon_only .btn_icon span,
.btn_normal.btn_icon_only .btn_status span,
.btn_icon_only.success-button .btn_status span,
.btn_icon_only.btn_small .btn_status span,
.notification-banner-message .banner-actions a.btn_icon_only .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.link .btn_status span,
.notification-banner-message .banner-actions .btn_icon_only.banner-action-button .btn_status span,
.notification-banner-message .banner-actions button.btn_icon_only .btn_status span {
  display: block;
  line-height: 20px;
  width: 20px;
  font-size: 16px;
}
.btn_normal.btn_icon_small, .btn_icon_small.success-button, .btn_icon_small.btn_small, .notification-banner-message .banner-actions a.btn_icon_small,
.notification-banner-message .banner-actions .btn_icon_small.link,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_small {
  padding: 4px 4px;
}
.btn_normal.btn_icon_small.btn_no_border, .btn_icon_small.btn_no_border.success-button, .btn_icon_small.btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_icon_small.btn_no_border,
.notification-banner-message .banner-actions .btn_icon_small.btn_no_border.link,
.notification-banner-message .banner-actions .btn_icon_small.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_icon_small.btn_no_border {
  padding: 5px 5px;
}
.btn_normal.btn_icon_small .btn_icon, .btn_icon_small.success-button .btn_icon, .btn_icon_small.btn_small .btn_icon, .notification-banner-message .banner-actions a.btn_icon_small .btn_icon,
.notification-banner-message .banner-actions .btn_icon_small.link .btn_icon,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button .btn_icon,
.notification-banner-message .banner-actions button.btn_icon_small .btn_icon,
.btn_normal.btn_icon_small .btn_status,
.btn_icon_small.success-button .btn_status,
.btn_icon_small.btn_small .btn_status,
.notification-banner-message .banner-actions a.btn_icon_small .btn_status,
.notification-banner-message .banner-actions .btn_icon_small.link .btn_status,
.notification-banner-message .banner-actions .btn_icon_small.banner-action-button .btn_status,
.notification-banner-message .banner-actions button.btn_icon_small .btn_status {
  font-size: 12px;
  height: 20px;
  width: 20px;
  text-align: center;
  display: block;
}
.btn_normal.btn_wide, .btn_wide.success-button, .btn_wide.btn_small, .notification-banner-message .banner-actions a.btn_wide,
.notification-banner-message .banner-actions .btn_wide.link,
.notification-banner-message .banner-actions .btn_wide.banner-action-button,
.notification-banner-message .banner-actions button.btn_wide {
  min-width: 100px;
}
.btn_normal.btn_select, .btn_select.success-button, .btn_select.btn_small, .notification-banner-message .banner-actions a.btn_select,
.notification-banner-message .banner-actions .btn_select.link,
.notification-banner-message .banner-actions .btn_select.banner-action-button,
.notification-banner-message .banner-actions button.btn_select {
  min-width: 100px;
  border: 1px solid #dfe0e1;
  padding: 4px 9px;
}
.btn_normal.btn_form_input, .btn_form_input.success-button, .btn_form_input.btn_small, .notification-banner-message .banner-actions a.btn_form_input,
.notification-banner-message .banner-actions .btn_form_input.link,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button,
.notification-banner-message .banner-actions button.btn_form_input {
  border: 1px solid #ababab;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  padding: 4px;
  background-color: #ffffff;
}
.btn_normal.btn_form_input:hover, .btn_form_input.success-button:hover, .btn_form_input.btn_small:hover, .notification-banner-message .banner-actions a.btn_form_input:hover,
.notification-banner-message .banner-actions .btn_form_input.link:hover,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button:hover,
.notification-banner-message .banner-actions button.btn_form_input:hover, .btn_normal.btn_form_input:focus, .btn_form_input.success-button:focus, .btn_form_input.btn_small:focus, .notification-banner-message .banner-actions a.btn_form_input:focus,
.notification-banner-message .banner-actions .btn_form_input.link:focus,
.notification-banner-message .banner-actions .btn_form_input.banner-action-button:focus,
.notification-banner-message .banner-actions button.btn_form_input:focus {
  color: #72bf44;
  border-color: #72bf44;
}
.btn_normal.btn_no_border, .btn_no_border.success-button, .btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_no_border,
.notification-banner-message .banner-actions .btn_no_border.link,
.notification-banner-message .banner-actions .btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_border {
  border: 0 solid !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn_normal.btn_no_padding, .btn_no_padding.success-button, .btn_no_padding.btn_small, .notification-banner-message .banner-actions a.btn_no_padding,
.notification-banner-message .banner-actions .btn_no_padding.link,
.notification-banner-message .banner-actions .btn_no_padding.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_padding {
  padding: 4px 0px;
}
.btn_normal.btn_no_padding.btn_no_border, .btn_no_padding.btn_no_border.success-button, .btn_no_padding.btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_no_padding.btn_no_border,
.notification-banner-message .banner-actions .btn_no_padding.btn_no_border.link,
.notification-banner-message .banner-actions .btn_no_padding.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_no_padding.btn_no_border {
  padding: 5px 0;
}
.btn_normal.btn_extra_padding, .btn_extra_padding.success-button, .btn_extra_padding.btn_small, .notification-banner-message .banner-actions a.btn_extra_padding,
.notification-banner-message .banner-actions .btn_extra_padding.link,
.notification-banner-message .banner-actions .btn_extra_padding.banner-action-button,
.notification-banner-message .banner-actions button.btn_extra_padding {
  padding: 4px 9px;
}
.btn_normal.btn_extra_padding.btn_no_border, .btn_extra_padding.btn_no_border.success-button, .btn_extra_padding.btn_no_border.btn_small, .notification-banner-message .banner-actions a.btn_extra_padding.btn_no_border,
.notification-banner-message .banner-actions .btn_extra_padding.btn_no_border.link,
.notification-banner-message .banner-actions .btn_extra_padding.btn_no_border.banner-action-button,
.notification-banner-message .banner-actions button.btn_extra_padding.btn_no_border {
  padding: 5px 10px;
}
.btn_normal.btn_offset_margin, .btn_offset_margin.success-button, .btn_offset_margin.btn_small, .notification-banner-message .banner-actions a.btn_offset_margin,
.notification-banner-message .banner-actions .btn_offset_margin.link,
.notification-banner-message .banner-actions .btn_offset_margin.banner-action-button,
.notification-banner-message .banner-actions button.btn_offset_margin {
  margin: -5px 0;
}
.btn_normal.btn_min_width, .btn_min_width.success-button, .btn_min_width.btn_small, .notification-banner-message .banner-actions a.btn_min_width,
.notification-banner-message .banner-actions .btn_min_width.link,
.notification-banner-message .banner-actions .btn_min_width.banner-action-button,
.notification-banner-message .banner-actions button.btn_min_width {
  display: inline-block;
  width: 80px;
}
.btn_normal.btn_table, .btn_table.success-button, .btn_table.btn_small, .notification-banner-message .banner-actions a.btn_table,
.notification-banner-message .banner-actions .btn_table.link,
.notification-banner-message .banner-actions .btn_table.banner-action-button,
.notification-banner-message .banner-actions button.btn_table {
  margin-top: -5px;
  margin-bottom: -5px;
}
.btn_normal.btn_hidden, .btn_hidden.success-button, .btn_hidden.btn_small, .notification-banner-message .banner-actions a.btn_hidden,
.notification-banner-message .banner-actions .btn_hidden.link,
.notification-banner-message .banner-actions .btn_hidden.banner-action-button,
.notification-banner-message .banner-actions button.btn_hidden {
  display: none;
}
.btn_normal > .btn_text, .success-button > .btn_text, .btn_small > .btn_text, .notification-banner-message .banner-actions a > .btn_text,
.notification-banner-message .banner-actions .link > .btn_text,
.notification-banner-message .banner-actions .banner-action-button > .btn_text,
.notification-banner-message .banner-actions button > .btn_text {
  line-height: 20px;
  display: inline-block;
}
.btn_normal > .btn_text.hidden_text, .success-button > .btn_text.hidden_text, .btn_small > .btn_text.hidden_text, .notification-banner-message .banner-actions a > .btn_text.hidden_text,
.notification-banner-message .banner-actions .link > .btn_text.hidden_text,
.notification-banner-message .banner-actions .banner-action-button > .btn_text.hidden_text,
.notification-banner-message .banner-actions button > .btn_text.hidden_text {
  position: absolute;
  font-size: 0 !important;
}
.btn_normal > .btn_icon, .success-button > .btn_icon, .btn_small > .btn_icon, .notification-banner-message .banner-actions a > .btn_icon,
.notification-banner-message .banner-actions .link > .btn_icon,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon,
.notification-banner-message .banner-actions button > .btn_icon,
.btn_normal > .btn_status,
.success-button > .btn_status,
.btn_small > .btn_status,
.notification-banner-message .banner-actions a > .btn_status,
.notification-banner-message .banner-actions .link > .btn_status,
.notification-banner-message .banner-actions .banner-action-button > .btn_status,
.notification-banner-message .banner-actions button > .btn_status {
  position: relative;
  pointer-events: none;
  display: inline-block;
  vertical-align: text-bottom;
  color: inherit;
  line-height: 20px;
}
.btn_normal > .btn_icon span, .success-button > .btn_icon span, .btn_small > .btn_icon span, .notification-banner-message .banner-actions a > .btn_icon span,
.notification-banner-message .banner-actions .link > .btn_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon span,
.notification-banner-message .banner-actions button > .btn_icon span,
.btn_normal > .btn_status span,
.success-button > .btn_status span,
.btn_small > .btn_status span,
.notification-banner-message .banner-actions a > .btn_status span,
.notification-banner-message .banner-actions .link > .btn_status span,
.notification-banner-message .banner-actions .banner-action-button > .btn_status span,
.notification-banner-message .banner-actions button > .btn_status span {
  font-size: 16px;
  display: block;
}
.btn_normal > .btn_icon.big_icon span, .success-button > .btn_icon.big_icon span, .btn_small > .btn_icon.big_icon span, .notification-banner-message .banner-actions a > .btn_icon.big_icon span,
.notification-banner-message .banner-actions .link > .btn_icon.big_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon.big_icon span,
.notification-banner-message .banner-actions button > .btn_icon.big_icon span,
.btn_normal > .btn_status.big_icon span,
.success-button > .btn_status.big_icon span,
.btn_small > .btn_status.big_icon span,
.notification-banner-message .banner-actions a > .btn_status.big_icon span,
.notification-banner-message .banner-actions .link > .btn_status.big_icon span,
.notification-banner-message .banner-actions .banner-action-button > .btn_status.big_icon span,
.notification-banner-message .banner-actions button > .btn_status.big_icon span {
  font-size: 20px;
}
.btn_normal > .btn_status.status_invalid, .success-button > .btn_status.status_invalid, .btn_small > .btn_status.status_invalid, .notification-banner-message .banner-actions a > .btn_status.status_invalid,
.notification-banner-message .banner-actions .link > .btn_status.status_invalid,
.notification-banner-message .banner-actions .banner-action-button > .btn_status.status_invalid,
.notification-banner-message .banner-actions button > .btn_status.status_invalid {
  color: #ea5441;
}
.btn_normal > .btn_helper, .success-button > .btn_helper, .btn_small > .btn_helper, .notification-banner-message .banner-actions a > .btn_helper,
.notification-banner-message .banner-actions .link > .btn_helper,
.notification-banner-message .banner-actions .banner-action-button > .btn_helper,
.notification-banner-message .banner-actions button > .btn_helper {
  float: right;
  margin-left: 5px;
  line-height: 20px;
}
.btn_normal > .btn_helper span, .success-button > .btn_helper span, .btn_small > .btn_helper span, .notification-banner-message .banner-actions a > .btn_helper span,
.notification-banner-message .banner-actions .link > .btn_helper span,
.notification-banner-message .banner-actions .banner-action-button > .btn_helper span,
.notification-banner-message .banner-actions button > .btn_helper span {
  font-size: 10px;
  display: block;
  line-height: 20px;
}
.btn_normal > .btn_icon_arrow, .success-button > .btn_icon_arrow, .btn_small > .btn_icon_arrow, .notification-banner-message .banner-actions a > .btn_icon_arrow,
.notification-banner-message .banner-actions .link > .btn_icon_arrow,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon_arrow,
.notification-banner-message .banner-actions button > .btn_icon_arrow {
  float: right;
  margin-left: 5px;
  line-height: 20px;
}
.btn_normal > .btn_icon_arrow span, .success-button > .btn_icon_arrow span, .btn_small > .btn_icon_arrow span, .notification-banner-message .banner-actions a > .btn_icon_arrow span,
.notification-banner-message .banner-actions .link > .btn_icon_arrow span,
.notification-banner-message .banner-actions .banner-action-button > .btn_icon_arrow span,
.notification-banner-message .banner-actions button > .btn_icon_arrow span {
  font-size: 16px;
  display: block;
  line-height: 20px;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.btn_group .btn_group_left {
  float: left;
  padding: 4px;
  border-right: 0px solid !important;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
.btn_group .btn_group_left.btn_no_border {
  padding: 5px;
}
.btn_group .btn_group_left.btn_grey {
  padding: 4px;
  border: 1px solid #eceef3 !important;
}
.btn_group .btn_group_left.btn_grey:focus, .btn_group .btn_group_left.btn_grey:hover {
  border: 1px solid #525b6b !important;
}
.btn_group .btn_group_left.btn_grey:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: #f5f6f7 !important;
  border-color: #f5f6f7 !important;
}
.btn_group .btn_group_left.btn_grey:disabled:hover, .btn_group .btn_group_left.btn_grey:disabled:focus {
  border-color: #f5f6f7 !important;
  background-color: #f5f6f7 !important;
  color: #cccccc !important;
}
.btn_group .btn_group_right {
  float: left;
  padding: 4px;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
.btn_group .btn_group_right.btn_no_border {
  padding: 5px;
}
.btn_group .btn_group_right.btn_grey {
  padding: 4px;
  border: 1px solid #eceef3 !important;
}
.btn_group .btn_group_right.btn_grey:focus, .btn_group .btn_group_right.btn_grey:hover {
  border: 1px solid #525b6b !important;
}
.btn_group .btn_group_right.btn_grey:disabled {
  color: #cccccc !important;
  cursor: default !important;
  background-color: #f5f6f7 !important;
  border-color: #f5f6f7 !important;
}
.btn_group .btn_group_right.btn_grey:disabled:hover, .btn_group .btn_group_right.btn_grey:disabled:focus {
  border-color: #f5f6f7 !important;
  background-color: #f5f6f7 !important;
  color: #cccccc !important;
}

.btn_small {
  font-size: 10px;
  padding: 0 !important;
}

fieldset.form_row {
  margin-bottom: 5px;
  position: relative;
}
fieldset.form_row.no_padding {
  margin-bottom: 0;
}
fieldset.form_row.inline {
  position: relative;
}
fieldset.form_row.inline legend {
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
}
fieldset.form_row.inline > .form_group {
  width: 65%;
  margin-left: 35%;
}
fieldset.form_row.inline.auto_width {
  display: inline-block;
}
fieldset.form_row.inline.auto_width legend {
  width: auto;
  margin-right: 10px;
  position: relative;
  left: auto;
  top: auto;
}
fieldset.form_row.inline.auto_width > .form_group {
  display: inline-block;
  width: auto;
  margin-left: auto;
  white-space: nowrap;
}
fieldset.form_row.inline.split_50 > .data_label {
  float: left;
  width: 50%;
}
fieldset.form_row.inline.split_50 > .data_value {
  float: left;
  width: 50%;
}
fieldset.form_row.layout_horizontal > .form_group > .form_row {
  width: auto;
  display: inline-block;
  float: left;
  margin-right: 10px;
}
fieldset.form_row.layout_horizontal > .form_group > .form_row:last-of-type {
  margin-right: 0;
}
fieldset.form_row.layout_stacked > .form_group.split_2 > .form_row {
  width: 50%;
  float: left;
}
fieldset.form_row.layout_stacked > .form_group.split_2 > .form_row label {
  display: block;
}
fieldset.form_row.layout_stacked > .form_group > .form_row {
  width: auto;
  display: block;
}
fieldset.form_row .form_row {
  margin-bottom: 0;
}

.form_row {
  margin-bottom: 5px;
  position: relative;
}
.form_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.form_row.no_padding {
  margin-bottom: 0;
}
.form_row.align_center {
  text-align: center;
}
.form_row.align_to_label {
  padding-left: 35%;
}
.form_row.align_to_label_50 {
  padding-left: 50%;
}
.form_row.inline > .form_label {
  float: left;
  width: 35%;
}
.form_row.inline > .form_input {
  float: left;
  width: 65%;
}
.form_row.inline.split_50 > .form_label {
  float: left;
  width: 50%;
}
.form_row.inline.split_50 > .form_input {
  float: left;
  width: 50%;
}
.form_row > .form_label {
  display: block;
}
.form_row > .form_label.hidden_label {
  position: absolute;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.form_row > .form_label.hidden_label label {
  padding: 0;
}
.form_row > .form_label label {
  line-height: 20px;
  padding: 5px 5px 5px 0;
  display: block;
}
.form_row > .form_label label .label_right {
  float: right;
}
.form_row > .form_input {
  display: block;
}
.form_row .form_input.readonly {
  position: relative;
}
.form_row .form_input.readonly .inputbox {
  padding-right: 25px !important;
}
.form_row .form_input.input_has_button {
  position: relative;
}
.form_row .form_input.input_has_button .inputbox {
  padding-right: 35px !important;
}
.form_row .form_input.input_has_button .form_input_button {
  position: absolute;
  right: 0;
  top: 0;
}
.form_row .form_input.input_has_helper {
  position: relative;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form_row .form_input.input_has_helper .form_input_helper {
  position: absolute;
  line-height: 20px;
  top: 5px;
  right: 0;
  min-width: 25px;
  text-align: left;
}
.form_row .form_input .inputbox_inline_text {
  margin-left: 5px;
  display: inline-block;
}
.form_row .form_error {
  display: block;
  color: #ea5441;
  line-height: 20px;
  padding: 5px 0;
}
.form_row.auto_width {
  display: inline-block;
}

.flex_group {
  display: flex;
  flex-direction: rows;
  -webkit-flex-direction: rows;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-top: 5px;
}
.flex_group.flex_end {
  justify-content: flex-end;
}
.flex_group:first-of-type {
  margin-top: 0;
}
.flex_group.flex_no_wrap {
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}
.flex_group .flex_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.flex_group .flex_row > .form_label {
  width: auto;
}
.flex_group .flex_row > .form_input {
  width: auto;
}
.flex_group .flex_row.flex_grow {
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
}
.flex_group .flex_row_action {
  margin-left: auto;
}

.form_helper_txt {
  line-height: 20px;
  padding: 5px 0;
  display: block;
}

.form_supplementary_text {
  line-height: 20px;
  padding: 5px 0;
  display: block;
  font-style: italic;
  color: #7e8c99;
}

/* Data Rows */
.data_row {
  display: block;
  margin-bottom: 5px;
  position: relative;
}
.data_row:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.data_row.no_padding {
  margin-bottom: 0;
}
.data_row.inline > .data_label {
  float: left;
  width: 35%;
}
.data_row.inline > .data_value {
  float: left;
  width: 65%;
}
.data_row.inline.split_50 > .data_label {
  float: left;
  width: 50%;
}
.data_row.inline.split_50 > .data_value {
  float: left;
  width: 50%;
}
.data_row > .data_label {
  display: block;
  line-height: 20px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.data_row > .data_label.hidden_label {
  position: absolute;
  font-size: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.data_row > .data_value {
  display: block;
  line-height: 20px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.data_row.mimic_form_row > .data_label {
  padding: 5px 0;
}
.data_row.mimic_form_row > .data_value {
  padding: 5px 0;
}

.panel_title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
}
.panel_title.inline {
  display: inline-block;
}

.login_title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
}

.context_title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0;
  color: #2b495e;
  text-transform: capitalize;
}

.dropdown_title {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 20px;
  padding: 5px 0;
  margin-bottom: 10px;
}

.notification_title {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 0.8rem;
}

#page_header {
  background-color: #2b495e;
  color: #ffffff;
}
#page_header .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_header .page_inner_container #header_left {
  float: left;
}
#page_header .page_inner_container #header_left #header_logo {
  float: left;
  line-height: 30px;
  margin-right: 20px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_icon {
  float: left;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_icon a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  line-height: 30px;
  font-size: 30px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_product {
  float: left;
  margin-left: 10px;
}
#page_header .page_inner_container #header_left #header_logo #header_logo_product span {
  display: block;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #ffffff;
}
#page_header .page_inner_container #header_left #header_create_simulation {
  float: left;
}
#page_header .page_inner_container #header_left #header_create_simulation button {
  margin-right: 5px;
  float: left;
}
#page_header .page_inner_container #header_left #header_create_simulation #save_status {
  float: left;
  margin-left: 10px;
  line-height: 20px;
  padding: 5px 0;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#page_header .page_inner_container #header_right {
  float: right;
}
#page_header .page_inner_container #header_right #header_model_data {
  float: left;
  margin-right: 10px;
}
#page_header .page_inner_container #header_right #header_model_structure {
  float: left;
  margin-right: 20px;
}
#page_header .page_inner_container #header_right #header_settings {
  float: left;
  margin-right: 20px;
}
#page_header .page_inner_container #header_right #header_quick_links {
  float: left;
  margin-right: 10px;
}
#page_header .page_inner_container #header_right #header_user {
  float: left;
}

.header33 {
  width: 50%;
}

#page_header_alt {
  text-align: center;
}
#page_header_alt .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_header_alt .page_inner_container #header_logo_center {
  margin: 40px auto 0;
  height: 100px;
}
#page_header_alt .page_inner_container #header_logo_center img {
  height: 100%;
}

#page_footer {
  background-color: #eceef3;
  color: #7e8c99;
}
#page_footer .page_inner_container {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#page_footer .page_inner_container.no_padding {
  padding: 0;
}
#page_footer .page_inner_container .footer_text {
  text-align: center;
  line-height: 20px;
  padding: 5px;
}

.col_group:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.col_group .col_item {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  position: relative;
}
.col_group .col_item.no_padding {
  padding: 0;
}
.col_group.split_4 .col_item {
  width: 25%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .col_group.split_4 .col_item {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .col_group.split_4 .col_item {
    width: 100%;
  }
}
.col_group.split_4.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_4.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_4.max_height .col_item:nth-child(2) {
  left: 25%;
}
.col_group.split_4.max_height .col_item:nth-child(3) {
  left: 50%;
}
.col_group.split_4.max_height .col_item:nth-child(4) {
  left: 75%;
}
.col_group.split_3 .col_item {
  width: 33.3333333333%;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .col_group.split_3 .col_item {
    width: 33.3333333333%;
  }
}
@media (min-width: 0px) and (max-width: 767px), (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .col_group.split_3 .col_item {
    width: 100%;
  }
}
.col_group.split_3.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_3.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_3.max_height .col_item:nth-child(2) {
  left: 33.3333333333%;
}
.col_group.split_3.max_height .col_item:nth-child(3) {
  left: 66.6666666667%;
}
.col_group.split_2 .col_item {
  width: 50%;
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .col_group.split_2 .col_item {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 767px), (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .col_group.split_2 .col_item {
    width: 100%;
  }
}
.col_group.split_2 .col_item.extra_space {
  padding-right: 15px;
}
.col_group.split_2 .col_item.extra_space:last-of-type {
  padding-right: 0;
  padding-left: 15px;
}
.col_group.split_2.max_height .col_item {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}
.col_group.split_2.max_height .col_item .panel_container {
  height: 100%;
}
.col_group.split_2.max_height .col_item:nth-child(2) {
  left: 50%;
}
.col_group.max_height {
  position: fixed;
  left: 65px;
  top: 65px;
  bottom: 5px;
  right: 5px;
}
@media all and (-ms-high-contrast: none) {
  .column_content.column_content_inner.column_section {
    display: flex;
    flex-direction: column;
    min-height: 1px;
  } /* IE10 */
  *::-ms-backdrop,
  .column_content.column_content_inner.column_section {
    display: flex;
    flex-direction: column;
    min-height: 1px;
  } /* IE11 */
}
.grid_container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-flow: wrap;
  flex-direction: row;
  height: 100%;
  max-width: 1440px;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  -webkit-flex-flow: wrap;
  -webkit-flex-direction: row;
}
.grid_container.auto_height {
  height: auto;
}
.grid_container.fixed_height {
  height: 100%;
  display: flex;
}
@media (max-width: 1279px) {
  .grid_container.fixed_height {
    display: flex;
    height: 100%;
  }
}
@media (max-width: 1279px) {
  .grid_container {
    display: block;
    height: auto;
  }
}
.grid_container.columns_3 .col {
  width: calc(33.3333333333% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_3 .col {
    width: calc(100% - 20px);
  }
}
.grid_container.columns_2 .col {
  width: calc(50% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_2 .col {
    width: calc(100% - 20px);
  }
}
.grid_container.columns_1 .col {
  width: calc(100% - 20px);
}
@media (max-width: 1279px) {
  .grid_container.columns_1 .col {
    width: calc(100% - 20px);
  }
}
.grid_container .col {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px auto;
  height: 100%;
}
@media (max-width: 1279px) {
  .grid_container .col {
    width: inherit;
    margin: 10px auto;
  }
}

/****** Spreadsheet ********/
/********** Base **********/
/********** Colours **********/
/********** Pages **********/
/********** Modal **********/
/********** Button **********/
/******* Icons ********/
/******* Icons ********/
/******* Media Queries ********/
.fadein, .periodequations-tree .star,
.lookup-tree .star, .periodequations-tree .has_changes,
.lookup-tree .has_changes, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*

  Modal Dialog Layout

*/
mat-dialog-container,
.mat-dialog-container {
  padding: 0 !important;
}

.dialog-model {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  padding: 0 !important;
  max-width: 100vw;
  border-radius: 5px;
}
.dialog-model *[class$=-left],
.dialog-model *[class$=-center],
.dialog-model *[class$=-right] {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 auto;
  align-items: stretch;
}
.dialog-model *[class$=-left] {
  justify-content: flex-start;
}
.dialog-model *[class$=-center] {
  justify-content: center;
}
.dialog-model *[class$=-right] {
  justify-content: flex-end;
}
.dialog-model .scrollable-y {
  overflow: hidden;
  overflow-y: auto;
}
.dialog-model .scrollable-x {
  overflow: hidden;
  overflow-x: auto;
}
.dialog-model .scrollable:not(-y):not(-x) {
  overflow: auto;
}
.dialog-model .panel-left,
.dialog-model .panel-right {
  position: relative;
}
.dialog-model .panel-left.with_dragbar:before,
.dialog-model .panel-right.with_dragbar:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  width: 10px;
  height: 30px;
  margin-top: -15px;
  border: 1px solid #dfe0e1;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dialog-model .panel-left .dragbar,
.dialog-model .panel-right .dragbar {
  background-color: black;
  height: 100%;
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  cursor: col-resize;
  opacity: 0;
  filter: alpha(opacity=0);
}
.dialog-model .panel-left .dragbar:hover, .dialog-model .panel-left .dragbar:focus,
.dialog-model .panel-right .dragbar:hover,
.dialog-model .panel-right .dragbar:focus {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dialog-model .panel-right {
  border-left: 1px solid #dfe0e1;
}
.dialog-model .panel-left {
  border-right: 1px solid #dfe0e1;
}
.dialog-model .panel-left-actions,
.dialog-model .panel-right-actions {
  padding: 15px;
  border-bottom: 1px solid #dfe0e1;
}

.modal-header {
  background-color: #51738b;
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 40px;
}

.modal-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.modal-body .component-header,
.modal-body .component-body,
.modal-body .panel-main-header,
.modal-body .panel-left,
.modal-body .panel-main,
.modal-body .panel-right,
.modal-body .panel-main-header-left,
.modal-body .panel-main-header-center,
.modal-body .panel-main-header-right,
.modal-body .modal-header-left,
.modal-body .modal-header-center,
.modal-body .modal-header-right,
.modal-body .modal-footer-left,
.modal-body .modal-footer-center,
.modal-body .modal-footer-right,
.modal-body .modal-header-left,
.modal-body .modal-header-center,
.modal-body .modal-header-right {
  position: relative;
  display: flex;
  flex-direction: row;
}
.modal-body .component-header,
.modal-body .panel-main-header {
  position: relative;
  min-height: fit-content;
}
.modal-body .panel-main-header.min-height {
  min-height: 48px;
}
.modal-body .panel-main-header-center,
.modal-body .modal-header-center,
.modal-body .modal-footer-center {
  margin: 0 auto;
  align-items: center;
}
.modal-body .modal-header-right,
.modal-body .panel-main-header-right,
.modal-body .modal-footer-right {
  margin: 0 0 0 auto;
  align-items: flex-end;
}
.modal-body .panel-left,
.modal-body .modal-header-left,
.modal-body .panel-main-header-left,
.modal-body .modal-footer-left {
  order: 0;
}
.modal-body .panel-main,
.modal-body .modal-header-center,
.modal-body .panel-main-header-center,
.modal-body .modal-footer-center {
  order: 1;
}
.modal-body .panel-right,
.modal-body .modal-header-right,
.modal-body .panel-main-header-right,
.modal-body .modal-footer-right {
  order: 2;
}
.modal-body .panel-main-header-left,
.modal-body .panel-main-header-center,
.modal-body .panel-main-header-right {
  padding: 0.5rem;
}
.modal-body .panel-main-header-left:empty,
.modal-body .panel-main-header-center:empty,
.modal-body .panel-main-header-right:empty {
  padding: 0;
}
.modal-body .panel-left-actions:empty,
.modal-body .panel-right-actions:empty {
  padding: 0;
}
.modal-body .component-header {
  position: relative;
  height: auto;
  width: 100%;
}
.modal-body .component-body {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
  flex-flow: nowrap;
  -webkit-flex-flow: nowrap;
  margin: 0px;
}
.modal-body .component-body .panel-left,
.modal-body .component-body .panel-right {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  min-height: 0;
  flex-grow: 1;
}
.modal-body .component-body .panel-main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
}
.modal-body .component-body .panel-main-body,
.modal-body .component-body .panel-left-body,
.modal-body .component-body .panel-right-body {
  flex-grow: 1;
}
.modal-body .component-body .panel-left:empty,
.modal-body .component-body .panel-left-header:empty,
.modal-body .component-body .panel-left-body:empty,
.modal-body .component-body .panel-left-footer:empty,
.modal-body .component-body .panel-right:empty,
.modal-body .component-body .panel-right-header:empty,
.modal-body .component-body .panel-right-body:empty,
.modal-body .component-body .panel-right-footer:empty {
  width: 0;
  height: 0;
}
.modal-body .component-body .panel-left-body,
.modal-body .component-body .panel-right-body {
  margin: 0;
  padding: 1rem;
}
.modal-body .component-body .panel-main-body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
}

.modal-footer, .dialog_actions {
  position: relative;
  padding: 15px;
  border-top: 1px solid #dfe0e1;
  display: flex;
  flex-direction: row;
}
.modal-footer .modal-footer-right, .dialog_actions .modal-footer-right {
  margin: 0 0 0 auto;
}
.modal-footer .modal-footer-right .btn_normal, .dialog_actions .modal-footer-right .btn_normal, .modal-footer .modal-footer-right .success-button, .dialog_actions .modal-footer-right .success-button, .modal-footer .modal-footer-right .btn_small, .dialog_actions .modal-footer-right .btn_small, .modal-footer .modal-footer-right .notification-banner-message .banner-actions a, .notification-banner-message .banner-actions .modal-footer .modal-footer-right a, .dialog_actions .modal-footer-right .notification-banner-message .banner-actions a, .notification-banner-message .banner-actions .dialog_actions .modal-footer-right a,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .link,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .link,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .banner-action-button,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .banner-action-button,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions button,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right button,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions button,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right button {
  margin: 0 0 0 1rem;
}
.modal-footer .modal-footer-right .btn_normal:first-of-type, .dialog_actions .modal-footer-right .btn_normal:first-of-type, .modal-footer .modal-footer-right .success-button:first-of-type, .dialog_actions .modal-footer-right .success-button:first-of-type, .modal-footer .modal-footer-right .btn_small:first-of-type, .dialog_actions .modal-footer-right .btn_small:first-of-type, .modal-footer .modal-footer-right .notification-banner-message .banner-actions a:first-of-type, .notification-banner-message .banner-actions .modal-footer .modal-footer-right a:first-of-type, .dialog_actions .modal-footer-right .notification-banner-message .banner-actions a:first-of-type, .notification-banner-message .banner-actions .dialog_actions .modal-footer-right a:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .link:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .link:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .link:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .link:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions .banner-action-button:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right .banner-action-button:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions .banner-action-button:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right .banner-action-button:first-of-type,
.modal-footer .modal-footer-right .notification-banner-message .banner-actions button:first-of-type,
.notification-banner-message .banner-actions .modal-footer .modal-footer-right button:first-of-type,
.dialog_actions .modal-footer-right .notification-banner-message .banner-actions button:first-of-type,
.notification-banner-message .banner-actions .dialog_actions .modal-footer-right button:first-of-type {
  margin: 0;
}

.content_container {
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.content_container.padding--all {
  padding: 15px;
}
.content_container.padding--left {
  padding-left: 15px;
}
.content_container.padding--right {
  padding-right: 15px;
}
.content_container.padding--top {
  padding-top: 15px;
}
.content_container.padding--bottom {
  padding-bottom: 15px;
}
.content_container.padding--vertical {
  padding-top: 15px;
  padding-bottom: 15px;
}
.content_container.padding--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.structure-col {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;
}
.structure-col .structure-col--left {
  flex-grow: 1;
  text-align: left;
  font-size: 0;
}
.structure-col .structure-col--left * {
  font-size: 0.86rem;
}
.structure-col .structure-col--left button,
.structure-col .structure-col--left input[type=button] {
  display: inline-block;
  margin-right: 5px;
}
.structure-col .structure-col--left button .btn_text,
.structure-col .structure-col--left input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--left button:last-of-type,
.structure-col .structure-col--left input[type=button]:last-of-type {
  margin-right: 0;
}
.structure-col .structure-col--middle {
  flex-grow: 1;
  text-align: center;
  font-size: 0;
}
.structure-col .structure-col--middle * {
  font-size: 0.86rem;
}
.structure-col .structure-col--middle button,
.structure-col .structure-col--middle input[type=button] {
  display: inline-block;
  margin-right: 5px;
}
.structure-col .structure-col--middle button .btn_text,
.structure-col .structure-col--middle input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--middle button:last-of-type,
.structure-col .structure-col--middle input[type=button]:last-of-type {
  margin-right: 0;
}
.structure-col .structure-col--right {
  flex-grow: 1;
  text-align: right;
  font-size: 0;
}
.structure-col .structure-col--right * {
  font-size: 0.86rem;
}
.structure-col .structure-col--right button,
.structure-col .structure-col--right input[type=button] {
  display: inline-block;
  font-size: 0;
  margin-left: 5px;
}
.structure-col .structure-col--right button .btn_text,
.structure-col .structure-col--right input[type=button] .btn_text {
  font-size: 0.76rem;
  display: block;
}
.structure-col .structure-col--right button:first-of-type,
.structure-col .structure-col--right input[type=button]:first-of-type {
  margin-left: 0;
}

.show_labels .page-container,
.show_labels .modal-header,
.show_labels .modal-body,
.show_labels .modal-footer,
.show_labels .dialog_actions,
.show_labels .component-header,
.show_labels .component-body,
.show_labels .panel-left,
.show_labels .panel-left-header,
.show_labels .panel-left-actions,
.show_labels .panel-left-body,
.show_labels .panel-right,
.show_labels .panel-right-header,
.show_labels .panel-right-actions,
.show_labels .panel-right-body,
.show_labels .panel-main-header-left,
.show_labels .panel-main-header-center,
.show_labels .panel-main-header-right,
.show_labels .modal-header-left,
.show_labels .modal-header-center,
.show_labels .modal-header-right,
.show_labels .modal-footer-left,
.show_labels .modal-footer-center,
.show_labels .modal-footer-right,
.show_labels .panel-main,
.show_labels .panel-main-header,
.show_labels .panel-main-actions,
.show_labels .panel-main-body {
  border-width: 2px;
  border-style: solid;
  position: relative;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.show_labels .page-container:before,
.show_labels .modal-header:before,
.show_labels .modal-body:before,
.show_labels .modal-footer:before,
.show_labels .dialog_actions:before,
.show_labels .component-header:before,
.show_labels .component-body:before,
.show_labels .panel-left:before,
.show_labels .panel-left-header:before,
.show_labels .panel-left-actions:before,
.show_labels .panel-left-body:before,
.show_labels .panel-right:before,
.show_labels .panel-right-header:before,
.show_labels .panel-right-actions:before,
.show_labels .panel-right-body:before,
.show_labels .panel-main-header-left:before,
.show_labels .panel-main-header-center:before,
.show_labels .panel-main-header-right:before,
.show_labels .modal-header-left:before,
.show_labels .modal-header-center:before,
.show_labels .modal-header-right:before,
.show_labels .modal-footer-left:before,
.show_labels .modal-footer-center:before,
.show_labels .modal-footer-right:before,
.show_labels .panel-main:before,
.show_labels .panel-main-header:before,
.show_labels .panel-main-actions:before,
.show_labels .panel-main-body:before {
  content: attr(id);
  background-color: attr(border-color);
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}
.show_labels > div {
  border-color: red;
}
.show_labels > div:before {
  color: white;
  background-color: red;
}
.show_labels > div > div {
  border-color: blue;
}
.show_labels > div > div:before {
  color: white;
  background-color: blue;
}
.show_labels > div > div > div {
  border-color: #ff00fb;
}
.show_labels > div > div > div:before {
  color: white;
  background-color: #ff00fb;
}
.show_labels > div > div > div > div {
  border-color: #7cbd22;
}
.show_labels > div > div > div > div:before {
  color: white;
  background-color: #7cbd22;
}

.modal-body {
  overflow: hidden;
  height: 100%;
}

/* Lookup tables tree & Period Equations */
.tree {
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: auto;
}
.tree button {
  cursor: pointer;
  display: inline-block;
}
.tree .drawer_container .drawer_header {
  padding: 0;
}
.tree .tree-node-parent .drawer_container {
  padding-top: 0.5rem;
}
.tree .drawer_content {
  display: block;
}

.view_all_tables.node_all .tree_link {
  border: 0;
}

.drawer_container {
  /* Drawer Content (Hidden by Default) */
}
.drawer_container .drawer_header {
  position: relative;
  padding-left: 30px;
}
.drawer_container .drawer_header .drawer_header_toggle {
  position: absolute;
  left: 0;
  top: 0;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content {
  margin-left: 30px;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content .tree_link {
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.drawer_container .drawer_header .drawer_header_title a {
  font-weight: 600;
}
.drawer_container .drawer_content {
  display: none;
  position: relative;
}
.drawer_container .drawer_content.drawer_active {
  display: block;
}

.periodequations-tree .star,
.lookup-tree .star {
  color: darkorange;
  filter: blur(0.5px);
  transition: all 200ms;
  margin-left: 0.382em;
  text-decoration: none;
  line-height: 0.5rem;
}
.periodequations-tree .has_changes,
.lookup-tree .has_changes {
  color: darkorange;
  filter: blur(0.5px);
  transition: all 200ms;
  margin-left: 0.382em;
  text-decoration: none;
  line-height: 0.5rem;
}
.periodequations-tree .tree-node-parent .drawer_content,
.lookup-tree .tree-node-parent .drawer_content {
  display: block;
}
.periodequations-tree .tree_link,
.lookup-tree .tree_link {
  text-decoration: none !important;
}
.periodequations-tree .tree_link:before,
.lookup-tree .tree_link:before {
  display: none;
}
.periodequations-tree .tree_link.category,
.lookup-tree .tree_link.category {
  text-decoration: none;
  font-weight: bold;
  color: #72bf44;
  padding-left: 0;
}

.dashboard_grid_item {
  margin-bottom: 40px;
  position: relative;
  background-color: #ffffff;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row {
  color: #505050;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row:hover {
  background-color: transparent;
}
.dashboard_grid_item .dashboard_grid_header .header_row .cell_row .user_data .cell_header {
  padding: 10px 5px;
}
.dashboard_grid_item .dashboard_grid_content .user_row:last-of-type .cell_row {
  border-bottom: 1px solid #dfe0e1;
}
.dashboard_grid_item .dashboard_grid_total .footer_row .cell_row {
  border-bottom: 0px solid #525b6b;
  background-color: #f7fbf5;
}
.dashboard_grid_item .dashboard_grid_total .footer_row .cell_row .user_data .user_name {
  padding-left: 20px;
}

.cell_row {
  border-bottom: 1px solid #dfe0e1;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  width: 100%;
  min-height: 0;
}
.cell_row .overflow-text {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell_row .overflow {
  position: relative;
}
.cell_row .overflow:hover {
  overflow: visible;
  background-color: #f7fbf5;
  z-index: 1;
}
.cell_row .overflow:hover div {
  position: absolute;
  background-color: #f7fbf5;
  z-index: 1;
  padding-right: 20px;
}
.cell_row:hover {
  background-color: #f7fbf5;
}
.cell_row:hover .user_data .user_name a {
  color: #72bf44;
  text-decoration: underline;
}
.cell_row:hover .user_data .user_name a.toggle_row_details {
  display: inline-block;
  text-decoration: underline;
  position: relative;
  padding-left: 0.6rem;
  margin-left: 0.3rem;
}
.cell_row:hover .user_data .user_name a.toggle_row_details:before {
  content: " - ";
  position: absolute;
  left: 0;
  top: 0;
}
.cell_row:hover .user_data .user_name a:hover {
  text-decoration: underline;
}
.cell_row .user_data {
  line-height: 20px;
}
.cell_row .user_data.cell_width_1 {
  flex: 0 0 calc(6.25% * 1);
}
.cell_row .user_data.cell_width_2 {
  flex: 0 0 calc(6.25% * 2);
}
.cell_row .user_data.cell_width_3 {
  flex: 0 0 calc(6.25% * 3);
}
.cell_row .user_data.cell_width_4 {
  flex: 0 0 calc(6.25% * 4);
}
.cell_row .user_data.cell_width_5 {
  flex: 0 0 calc(6.25% * 5);
}
.cell_row .user_data.cell_width_6 {
  flex: 0 0 calc(6.25% * 6);
}
.cell_row .user_data.cell_width_7 {
  flex: 0 0 calc(6.25% * 7);
}
.cell_row .user_data.cell_width_8 {
  flex: 0 0 calc(6.25% * 8);
}
.cell_row .user_data.cell_width_9 {
  flex: 0 0 calc(6.25% * 9);
}
.cell_row .user_data.cell_width_10 {
  flex: 0 0 calc(6.25% * 10);
}
.cell_row .user_data.cell_width_11 {
  flex: 0 0 calc(6.25% * 11);
}
.cell_row .user_data.cell_width_12 {
  flex: 0 0 calc(6.25% * 12);
}
.cell_row .user_data.cell_width_13 {
  flex: 0 0 calc(6.25% * 13);
}
.cell_row .user_data.cell_width_14 {
  flex: 0 0 calc(6.25% * 14);
}
.cell_row .user_data.cell_width_15 {
  flex: 0 0 calc(6.25% * 15);
}
.cell_row .user_data.cell_width_16 {
  flex: 0 0 calc(6.25% * 16);
}
.cell_row .user_data .cell_header {
  padding: 5px 5px;
  font-weight: 600;
}
.cell_row .user_data .cell_header h3 {
  line-height: 20px;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 400;
}
.cell_row .user_data .user_name {
  padding: 5px 5px;
}
.cell_row .user_data .user_name a {
  color: #72bf44;
  text-decoration: underline;
}
.cell_row .user_data .user_name a.toggle_row_details {
  text-decoration: underline;
  display: none;
}
.cell_row .user_data .user_name a.toggle_row_details:before {
  content: " - ";
}
.cell_row .user_data .user_name a:hover {
  text-decoration: underline;
}
.cell_row .user_data .value {
  padding: 5px 5px;
}
.cell_row .user_data .value.negative {
  color: #ea5441;
}

app-lookup-setup .panel-main-header,
app-liquidity-sources .panel-main-header,
app-exogenous-lines .panel-main-header {
  min-height: 50px !important;
  height: 50px !important;
}

app-equation-bar kendo-window,
app-balance-sheet kendo-window {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
}
app-equation-bar kendo-window-titlebar,
app-equation-bar .k-dialog-titlebar,
app-equation-bar .k-window-titlebar,
app-balance-sheet kendo-window-titlebar,
app-balance-sheet .k-dialog-titlebar,
app-balance-sheet .k-window-titlebar {
  background-color: #51738b !important;
  padding: 0.25rem 0.5rem !important;
}

app-input-structure select,
app-lookup-setup select,
app-liquidity-sources select,
app-exogenous-lines select {
  animation: fadein 0.3s;
}

#data_set_container {
  position: relative;
  height: 100%;
  min-width: 9.37rem;
  padding: 0 1rem;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: right;
  z-index: 1;
}
#data_set_container #data_set_filename a {
  color: #ffffff;
  display: inline_block;
  font-weight: 300;
}
#data_set_container #data_set_filename a:hover, #data_set_container #data_set_filename a:focus {
  text-decoration: none;
}
#data_set_container #data_set_timestamp {
  display: block;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-weight: 300;
}

.fadein, .periodequations-tree .star,
.lookup-tree .star, .periodequations-tree .has_changes,
.lookup-tree .has_changes, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.panel-left-body,
.panel-right-body {
  overflow: hidden auto;
  height: auto;
}
.panel-left-body .tree-warning,
.panel-right-body .tree-warning {
  background-color: #ff9d00;
  color: #ffffff;
  padding: 0.618rem;
}
.panel-left-body .tree-nodes,
.panel-right-body .tree-nodes {
  overflow: hidden auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  overflow: hidden auto;
  padding: 0;
  margin: 0 0 0 15px;
  list-style: none;
}
.panel-left-body .tree .tree_link,
.panel-right-body .tree .tree_link {
  display: block;
  text-decoration: underline;
  line-height: 20px;
  padding: 5px 5px 5px 20px;
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 5px 5px;
  text-align: left;
  overflow: visible;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 0px solid;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.86rem;
  color: #2b495e;
  -webkit-tap-highlight-color: transparent;
  padding-left: 1rem;
}
.panel-left-body .tree .tree_link:before,
.panel-right-body .tree .tree_link:before {
  content: "\e902";
  font-family: "empyrean_icons";
  font-size: 10px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 10px;
  left: 0px;
  line-height: 10px;
}
.panel-left-body .tree .tree_link.tree_link_active:before,
.panel-right-body .tree .tree_link.tree_link_active:before {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.panel-left-body .tree .tree_text,
.panel-right-body .tree .tree_text {
  display: block;
  line-height: 20px;
  padding: 5px 5px 5px 20px;
  position: relative;
}
.panel-left-body .tree .tree_text:before,
.panel-right-body .tree .tree_text:before {
  content: "";
  height: 4px;
  width: 4px;
  background-color: #2b495e;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  position: absolute;
  top: 13px;
  left: 5px;
}

#sim_group,
.sim_group {
  width: calc(100% - 30px);
  padding: 15px;
}
#sim_group #sim_header,
.sim_group #sim_header {
  border-bottom: 1px solid #505050;
}
#sim_group #sim_header > div .sim_header_label,
.sim_group #sim_header > div .sim_header_label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
#sim_group .sim_scenario,
#sim_group .sim_mnemonic,
#sim_group .sim_description,
.sim_group .sim_scenario,
.sim_group .sim_mnemonic,
.sim_group .sim_description {
  float: left;
  min-height: 30px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_scenario .is_invalid_mnemonic,
#sim_group .sim_mnemonic .is_invalid_mnemonic,
#sim_group .sim_description .is_invalid_mnemonic,
.sim_group .sim_scenario .is_invalid_mnemonic,
.sim_group .sim_mnemonic .is_invalid_mnemonic,
.sim_group .sim_description .is_invalid_mnemonic {
  line-height: 20px;
  margin: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_scenario .is_invalid_mnemonic .idle-component,
#sim_group .sim_mnemonic .is_invalid_mnemonic .idle-component,
#sim_group .sim_description .is_invalid_mnemonic .idle-component,
.sim_group .sim_scenario .is_invalid_mnemonic .idle-component,
.sim_group .sim_mnemonic .is_invalid_mnemonic .idle-component,
.sim_group .sim_description .is_invalid_mnemonic .idle-component {
  border: 1px solid #ea5441;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
#sim_group .sim_scenario .is_invalid_mnemonic .invalid-message,
#sim_group .sim_mnemonic .is_invalid_mnemonic .invalid-message,
#sim_group .sim_description .is_invalid_mnemonic .invalid-message,
.sim_group .sim_scenario .is_invalid_mnemonic .invalid-message,
.sim_group .sim_mnemonic .is_invalid_mnemonic .invalid-message,
.sim_group .sim_description .is_invalid_mnemonic .invalid-message {
  white-space: normal;
}
#sim_group .sim_scenario:hover .inner_cell_text .inline-edit,
#sim_group .sim_mnemonic:hover .inner_cell_text .inline-edit,
#sim_group .sim_description:hover .inner_cell_text .inline-edit,
.sim_group .sim_scenario:hover .inner_cell_text .inline-edit,
.sim_group .sim_mnemonic:hover .inner_cell_text .inline-edit,
.sim_group .sim_description:hover .inner_cell_text .inline-edit {
  text-decoration: underline;
}
#sim_group .sim_scenario .inner_cell_text,
#sim_group .sim_mnemonic .inner_cell_text,
#sim_group .sim_description .inner_cell_text,
.sim_group .sim_scenario .inner_cell_text,
.sim_group .sim_mnemonic .inner_cell_text,
.sim_group .sim_description .inner_cell_text {
  line-height: 20px;
  padding: 0px 0px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
#sim_group .sim_scenario .inner_cell_text .inline-edit,
#sim_group .sim_mnemonic .inner_cell_text .inline-edit,
#sim_group .sim_description .inner_cell_text .inline-edit,
.sim_group .sim_scenario .inner_cell_text .inline-edit,
.sim_group .sim_mnemonic .inner_cell_text .inline-edit,
.sim_group .sim_description .inner_cell_text .inline-edit {
  line-height: 20px;
  padding: 5px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
#sim_group .sim_scenario .inner_cell_text .inline-edit.force_multiline_text,
#sim_group .sim_mnemonic .inner_cell_text .inline-edit.force_multiline_text,
#sim_group .sim_description .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_scenario .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_mnemonic .inner_cell_text .inline-edit.force_multiline_text,
.sim_group .sim_description .inner_cell_text .inline-edit.force_multiline_text {
  white-space: normal !important;
}
#sim_group .sim_scenario .inner_cell_text input,
#sim_group .sim_mnemonic .inner_cell_text input,
#sim_group .sim_description .inner_cell_text input,
.sim_group .sim_scenario .inner_cell_text input,
.sim_group .sim_mnemonic .inner_cell_text input,
.sim_group .sim_description .inner_cell_text input {
  line-height: 20px;
  margin: 0;
  padding: 4px 5px;
  border: 1px solid #ababab;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .sim_row_toggle,
#sim_group .sim_last_updated,
#sim_group .sim_dataset,
#sim_group .sim_delete,
#sim_group .sim_notifications,
#sim_group .sim_action,
.sim_group .sim_row_toggle,
.sim_group .sim_last_updated,
.sim_group .sim_dataset,
.sim_group .sim_delete,
.sim_group .sim_notifications,
.sim_group .sim_action {
  float: left;
  min-height: 30px;
  padding: 5px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sim_group .inner_cell_text,
.sim_group .inner_cell_text {
  line-height: 20px;
  padding: 5px 0px;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
#sim_group .inner_cell_text a,
.sim_group .inner_cell_text a {
  color: #2b495e;
}
#sim_group .inner_cell_text a:hover, #sim_group .inner_cell_text a:focus,
.sim_group .inner_cell_text a:hover,
.sim_group .inner_cell_text a:focus {
  color: #72bf44;
}
#sim_group .sim_scenario .inner_cell_text,
.sim_group .sim_scenario .inner_cell_text {
  font-weight: 600;
}
#sim_group .sim_row_toggle,
.sim_group .sim_row_toggle {
  width: 40px;
}
#sim_group .sim_scenario,
.sim_group .sim_scenario {
  width: 15%;
}
#sim_group .sim_mnemonic,
.sim_group .sim_mnemonic {
  width: 15%;
}
#sim_group .sim_description,
.sim_group .sim_description {
  width: calc(70% - 370px);
}
#sim_group .manage_resultsets_table .sim_description,
.sim_group .manage_resultsets_table .sim_description {
  width: calc(70% - 370px + 40px);
}
#sim_group .sim_last_updated,
.sim_group .sim_last_updated {
  width: 130px;
}
#sim_group .sim_dataset,
.sim_group .sim_dataset {
  width: 160px;
}
#sim_group .sim_delete,
.sim_group .sim_delete {
  width: 40px;
}
#sim_group .sim_notifications,
.sim_group .sim_notifications {
  width: 40px;
}
#sim_group .sim_action,
.sim_group .sim_action {
  width: 120px;
}
#sim_group .sim_notifications .icon_notification,
.sim_group .sim_notifications .icon_notification {
  color: #ff9d00;
}
#sim_group .sim_delete button,
.sim_group .sim_delete button {
  opacity: 0.1;
  filter: alpha(opacity=10);
}
#sim_group .sim_description .inner_cell_text.wrap_text,
.sim_group .sim_description .inner_cell_text.wrap_text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 30px);
}
#sim_group .sim_description .more_link,
#sim_group .sim_description .less_link,
.sim_group .sim_description .more_link,
.sim_group .sim_description .less_link {
  line-height: 20px;
  padding: 5px 0px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}
#sim_group .sim_description .more_link a,
#sim_group .sim_description .less_link a,
.sim_group .sim_description .more_link a,
.sim_group .sim_description .less_link a {
  color: #2b495e;
}
#sim_group .sim_description .more_link a:hover, #sim_group .sim_description .more_link a:focus,
#sim_group .sim_description .less_link a:hover,
#sim_group .sim_description .less_link a:focus,
.sim_group .sim_description .more_link a:hover,
.sim_group .sim_description .more_link a:focus,
.sim_group .sim_description .less_link a:hover,
.sim_group .sim_description .less_link a:focus {
  text-decoration: underline;
}
#sim_group .sim_row_container,
.sim_group .sim_row_container {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
#sim_group .sim_row_container:last-of-type,
.sim_group .sim_row_container:last-of-type {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
#sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button,
.sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button {
  font-size: 12px;
  position: relative;
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button .btn_icon span,
.sim_group .sim_row_container.show_children .sim_row .sim_row_toggle button .btn_icon span {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row .sim_row_toggle button,
.sim_group .sim_row_container .sim_row .sim_row_toggle button {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row .sim_row_toggle button .btn_icon span,
.sim_group .sim_row_container .sim_row .sim_row_toggle button .btn_icon span {
  font-size: 12px;
}
#sim_group .sim_row_container .sim_row:hover,
.sim_group .sim_row_container .sim_row:hover {
  background-color: #f4f6f7;
}
#sim_group .sim_row_container .sim_row:hover .sim_delete button,
.sim_group .sim_row_container .sim_row:hover .sim_delete button {
  opacity: 1;
  filter: alpha(opacity=100);
}
#sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario,
.sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario {
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario .color-dot,
.sim_group .sim_row_container .sim_row.sim_baseline .sim_scenario .color-dot {
  margin: auto 0;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #cccccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
#sim_group .sim_row_container .sim_row.sim_stress .sim_scenario,
.sim_group .sim_row_container .sim_row.sim_stress .sim_scenario {
  padding-left: 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_row.sim_stress .sim_scenario .color-line,
.sim_group .sim_row_container .sim_row.sim_stress .sim_scenario .color-line {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  width: 4px;
  height: 100%;
  margin-left: -1em;
  background-color: #cccccc;
}
#sim_group .sim_row_container .sim_stress_add .sim_scenario,
.sim_group .sim_row_container .sim_stress_add .sim_scenario {
  padding-left: 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_baseline_add .sim_scenario,
.sim_group .sim_row_container .sim_baseline_add .sim_scenario {
  padding-left: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
#sim_group .sim_row_container .sim_baseline_add .sim_scenario .color-dot,
.sim_group .sim_row_container .sim_baseline_add .sim_scenario .color-dot {
  margin: auto 0;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: #cccccc;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.is_invalid_dataset {
  border: 1px solid red !important;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.8rem;
}

#assumption_container {
  position: relative;
  height: calc(100vh - 242px);
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;
}
#assumption_container #assumption_lookup_list {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  padding: 15px;
}
#assumption_container #assumption_lookup_list:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #dfe0e1;
}
#assumption_container #assumption_lookup_viewer {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  flex: 1;
  margin-left: 270px;
  display: block;
}
#assumption_container #assumption_lookup_viewer #toggle_list_btn_container {
  position: absolute;
  left: -15px;
  top: 0;
  display: none;
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;
}
#assumption_container #assumption_lookup_viewer #toggle_list_btn_container button {
  background-color: #ffffff;
}

#footer_bar {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
  position: relative;
}
#footer_bar:before {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_result_set {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  padding-right: 0;
}
#footer_bar #footer_quick_link {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 0;
  position: relative;
}
#footer_bar #footer_report_summary {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
  padding-left: 0;
}
#footer_bar #footer_report_summary button.btn_sheet {
  min-width: 3rem;
  background-color: #ffffff;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  padding: 10px;
  position: relative;
  color: rgba(80, 80, 80, 0.5);
  text-transform: inherit;
  font-size: 0.86rem;
  font-weight: 400;
}
#footer_bar #footer_report_summary button.btn_sheet:before {
  content: "";
  height: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_report_summary button.btn_sheet:after {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#footer_bar #footer_report_summary button.btn_sheet:hover, #footer_bar #footer_report_summary button.btn_sheet:focus {
  color: #2b495e;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active {
  color: #505050;
  font-weight: 600;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active:before {
  display: none;
}
#footer_bar #footer_report_summary button.btn_sheet.btn_sheet_active:after {
  display: none;
}
#footer_bar #footer_tabs {
  width: 100%;
  flex-grow: 1;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 5px;
}
#footer_bar #footer_controls {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}
#footer_bar #footer_controls.hide_controls {
  display: none;
}
#footer_bar #footer_controls #pan_controls {
  width: 60px;
}
#footer_bar #footer_controls #pan_controls button {
  float: left;
}
#footer_bar #footer_export {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

#result_tab_container > ul {
  margin: 0px 0 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  font-size: 0;
  white-space: nowrap;
}
#result_tab_container > ul > li {
  display: inline-block;
  font-size: 0;
  margin-right: 10px;
  position: relative;
}
#result_tab_container > ul > li:last-child {
  margin-right: 0;
}
#result_tab_container > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  font-size: 0;
}
#result_tab_container > ul > li > ul > li {
  display: inline-block;
  font-size: 0;
  margin-right: 2px;
  position: relative;
  overflow: hidden;
}
#result_tab_container > ul > li > ul > li button.btn_sheet {
  min-width: 6rem;
  background-color: #ffffff;
  background: linear-gradient(to bottom, rgb(246, 246, 246) 10%, white 50%, white);
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  padding: 10px 10px 7px 10px;
  position: relative;
  border-width: 0 0 3px 0;
  border-style: solid;
  color: rgba(80, 80, 80, 0.5);
  text-transform: inherit;
  font-size: 0.86rem;
  font-weight: 600;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:before {
  content: "";
  height: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:after {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
#result_tab_container > ul > li > ul > li button.btn_sheet:hover, #result_tab_container > ul > li > ul > li button.btn_sheet:focus {
  color: #2b495e;
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active {
  color: #505050;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active:before {
  display: none;
}
#result_tab_container > ul > li > ul > li button.btn_sheet.btn_sheet_active:after {
  display: none;
}
#result_tab_container > ul > li > ul > li button.btn_sheet .btn_status span {
  color: #ff9d00;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide {
  position: absolute;
  right: -40px;
  top: 5px;
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;
  margin-left: 5px;
  padding-left: 0;
  padding-right: 5px;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide .btn_icon span {
  font-size: 10px;
  background-color: #f5f6f7;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  padding: 3px;
}
#result_tab_container > ul > li > ul > li button.btn_sheet_hide:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 10px;
  background: rgb(255, 255, 255);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
#result_tab_container > ul > li > ul > li:hover button.btn_sheet_hide {
  right: 0;
}

#context_bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-between;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;
  background-color: #f5f6f7;
}
#context_bar #context_bar_left {
  flex: 1;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#context_bar #context_bar_left #context_source {
  float: left;
  line-height: 30px;
  padding-right: 15px;
  margin-right: 5px;
  position: relative;
}
#context_bar #context_bar_left #context_source:after {
  content: "\e910";
  font-family: "empyrean_icons";
  line-height: 30px;
  font-size: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
#context_bar #context_bar_left #context_source .source_file span {
  display: block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 30px;
}
#context_bar #context_bar_left #context_title {
  float: left;
  padding-right: 10px;
}
#context_bar #context_bar_left #context_edit_assumptions {
  float: left;
  padding-right: 10px;
  cursor: pointer;
}
#context_bar #context_bar_left #context_edit_assumptions .edit_asumptions_link {
  display: block;
  line-height: 20px;
  padding: 5px 0px 5px 0;
}
#context_bar #context_bar_middle {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
#context_bar #context_bar_middle #view_controller {
  display: inline-block;
  width: 160px;
}
#context_bar #context_bar_middle #view_controller .toggle_switch_option {
  width: 80px;
}
#context_bar #context_bar_middle #access_controller {
  display: inline-block;
  color: dark_grey;
  line-height: 20px;
  padding: 5px 0;
  margin-left: 10px;
}
#context_bar #context_bar_middle #access_controller .access_text {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}
#context_bar #context_bar_right {
  text-align: right;
  flex: 1;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#context_bar #context_bar_right #sheet_status {
  display: inline-block;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#context_bar #context_bar_right #notifications_container {
  display: inline-block;
  margin-left: 10px;
}

#sheet_list {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 400px;
  z-index: 1;
  background: #ffffff;
  padding: 15px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#sheet_list > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#sheet_list > ul > li {
  margin-bottom: 20px;
}
#sheet_list > ul > li:last-child {
  margin-bottom: 0;
}
#sheet_list > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#sheet_list > ul > li > ul > li a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0;
}
#sheet_list > ul > li > ul > li a:hover, #sheet_list > ul > li > ul > li a:focus {
  background-color: #f5f6f7;
  color: #505050;
}
#sheet_list > ul > li > ul > li a:hover .sheet_name, #sheet_list > ul > li > ul > li a:focus .sheet_name {
  text-decoration: underline;
}
#sheet_list > ul > li > ul > li a:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
#sheet_list > ul > li > ul > li a .sheet_name {
  width: 70%;
  display: block;
  vertical-align: top;
  float: left;
  word-wrap: break-word;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0 5px 20px;
  line-height: 20px;
  position: relative;
}
#sheet_list > ul > li > ul > li a .sheet_dataset {
  padding: 5px 0;
  line-height: 20px;
  width: 30%;
  display: block;
  vertical-align: right;
  float: left;
  text-align: right;
  word-wrap: break-word;
}
#sheet_list > ul > li > ul > li a.sheet_baseline .sheet_name:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
#sheet_list > ul > li > ul > li a.sheet_stress .sheet_name {
  padding-left: 40px;
}
#sheet_list > ul > li > ul > li a.sheet_stress .sheet_name:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 4px;
}

ul#notifications_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#notifications_list > li {
  padding: 10px 0;
  border-bottom: 1px solid #dfe0e1;
}
ul#notifications_list > li:last-child {
  border-bottom: 0px solid #dfe0e1;
}
ul#notifications_list > li p {
  line-height: 20px;
  color: #525b6b;
}
ul#notifications_list > li p:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}
ul#notifications_list > li p a {
  color: #ff9d00;
  text-decoration: none;
}
ul#notifications_list > li p a:hover, ul#notifications_list > li p a:focus {
  text-decoration: underline;
}

#notification_container {
  font-size: 0.76rem;
  background-color: #ff9d00;
  color: #525b6b;
  padding: 5px;
  text-align: center;
  display: block;
  margin: 0 auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
}
#notification_container p {
  line-height: 15px;
}
#notification_container p:last-child {
  padding: 0;
  margin: 0;
}
#notification_container [class^=icon_], #notification_container [class*=" icon_"] {
  vertical-align: middle;
}

app-hover-notification {
  display: contents;
}

.hover-notification-container {
  background-color: white;
  color: #525b6b;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  display: grid;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.86rem;
  grid-template-rows: auto 1fr;
  grid-gap: 0.4rem;
  min-width: 100px;
  max-width: 300px;
  padding: 0.7rem;
  position: absolute;
  top: 0.5rem;
  width: auto;
  z-index: 999;
  border-left: 6px solid;
  border-left-color: #ff9d00;
}
.hover-notification-container.notificationright {
  right: 0.5rem;
}
.hover-notification-container.notificationleft {
  left: 0.5rem;
}
.hover-notification-container.info {
  border-left-color: #51738b !important;
}
.hover-notification-container.info .title,
.hover-notification-container.info .value {
  color: #51738b !important;
}
.hover-notification-container.success {
  border-left-color: #72bf44 !important;
}
.hover-notification-container.success .title,
.hover-notification-container.success .value {
  color: #72bf44 !important;
}
.hover-notification-container.error {
  border-left-color: #ea5441 !important;
}
.hover-notification-container.error .title,
.hover-notification-container.error .value {
  color: #ea5441 !important;
}
.hover-notification-container.warning {
  border-left-color: #ff9d00 !important;
}
.hover-notification-container.warning .title,
.hover-notification-container.warning .value {
  color: #ff9d00 !important;
}
.hover-notification-container .header {
  display: grid;
  grid-template-columns: 1fr auto;
}
.hover-notification-container .header .title,
.hover-notification-container .header .value {
  font-weight: 600;
}
.hover-notification-container .header .value {
  margin-left: 1rem;
}
.hover-notification-container .body {
  white-space: pre-wrap;
  font-weight: 400;
}

app-multi-notification {
  margin: auto !important;
  padding: 0.5rem;
  width: auto;
  max-width: 90%;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(3, auto);
  grid-gap: 0.5rem;
}

.multi-notification-container {
  padding: 0.5rem;
  border: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 6px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 1fr auto;
}
.multi-notification-container .title {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 0.8rem;
  color: #525b6b;
}
.multi-notification-container .body {
  color: #525b6b;
  font-size: 0.86rem;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 400;
}
.multi-notification-container .actions {
  margin-top: auto;
}
.multi-notification-container .actions button {
  margin-left: 0.5rem;
}

.component-header {
  overflow: hidden;
  transition: all 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: max-height 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  height: auto;
  max-height: 600px;
}

.component-header.slide-up {
  max-height: 0;
  display: none;
}

ul#application_list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#application_list > li {
  float: left;
  width: 33.3333333333%;
  text-align: center;
}
ul#application_list > li a.application_link {
  display: block;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  text-align: center;
  padding: 10px 4px;
  color: inherit;
}
ul#application_list > li a.application_link:hover, ul#application_list > li a.application_link:focus {
  background-color: rgba(43, 73, 94, 0.1);
}
ul#application_list > li a.application_link:hover .application_icon, ul#application_list > li a.application_link:focus .application_icon {
  background-color: rgba(43, 73, 94, 0.3);
  color: #2b495e;
}
ul#application_list > li a.application_link:hover .application_name,
ul#application_list > li a.application_link:hover .application_subtitle, ul#application_list > li a.application_link:focus .application_name,
ul#application_list > li a.application_link:focus .application_subtitle {
  color: #2b495e;
}
ul#application_list > li a.application_link.disabled {
  opacity: 0.3;
  transition: opacity 0.6s;
}
ul#application_list > li a.application_link .application_icon {
  display: block;
  padding: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: rgba(0, 0, 0, 0.05);
  width: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto 10px;
}
ul#application_list > li a.application_link .application_icon span {
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  display: block;
  vertical-align: top;
}
ul#application_list > li a.application_link .application_name {
  text-align: center;
  display: block;
  line-height: 15px;
  min-height: 30px;
  font-size: 0.8rem;
}
ul#application_list > li a.application_link .application_subtitle {
  text-align: center;
  display: block;
  font-size: 0.7rem;
}

.notification-banner-message {
  position: relative;
  width: 100%;
  color: #000000;
  background: #ffffff;
  cursor: default;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 157, 0, 0.25);
  padding-bottom: 1px;
}
.notification-banner-message .banner-title,
.notification-banner-message .banner-body,
.notification-banner-message .banner-actions {
  width: auto;
  text-align: center;
  max-width: 800px;
}
.notification-banner-message .banner-title,
.notification-banner-message .banner-body {
  padding: 0.618rem auto;
}
.notification-banner-message .banner-actions {
  padding-bottom: 0.618rem;
}
.notification-banner-message .banner-actions {
  display: flex;
  justify-content: center;
}
.notification-banner-message .banner-actions a,
.notification-banner-message .banner-actions .link,
.notification-banner-message .banner-actions .banner-action-button,
.notification-banner-message .banner-actions button {
  background: unset;
  color: unset;
  border: unset;
  flex: 1 auto;
  flex-wrap: wrap;
  text-transform: uppercase;
  color: #72bf44;
  width: auto;
  min-width: 7rem;
  min-height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.notification-banner-message .banner-actions a :first-of-type(),
.notification-banner-message .banner-actions .link :first-of-type(),
.notification-banner-message .banner-actions .banner-action-button :first-of-type(),
.notification-banner-message .banner-actions button :first-of-type() {
  margin-left: 0;
}
.notification-banner-message .banner-actions a.primary-action,
.notification-banner-message .banner-actions .link.primary-action,
.notification-banner-message .banner-actions .banner-action-button.primary-action,
.notification-banner-message .banner-actions button.primary-action {
  background: #2b495e;
  color: #ffffff;
}
.notification-banner-message .banner-actions a.secondary-action,
.notification-banner-message .banner-actions .link.secondary-action,
.notification-banner-message .banner-actions .banner-action-button.secondary-action,
.notification-banner-message .banner-actions button.secondary-action {
  background: transparent;
  border: 1px solid #2b495e;
  color: #2b495e;
}

app-notification-banner,
.app-notification-banner,
.notification-banner-message {
  width: 100%;
  padding: 0;
}

/*

  Spreadsheets

*/
.spread-container {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 8;
}
.spread-container .spreadsheet {
  z-index: 9;
  position: relative;
  height: 100%;
  width: 100%;
}
.spread-container .spreadsheet--loading, .spread-container .spreadsheet--empty {
  z-index: 10;
  background-color: #f5f6f7;
  background-image: linear-gradient(to bottom, #f5f6f7 calc(100% - 40px), white);
  position: relative;
  min-height: 100%;
}
.spread-container .spreadsheet--loading:before, .spread-container .spreadsheet--empty:before {
  content: "\e90d";
  font-family: "empyrean_icons";
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 80px;
  color: #cccccc;
  transform: translate(-50%, -50%);
  font-weight: 300;
}
.spread-container .spreadsheet--loading:after, .spread-container .spreadsheet--empty:after {
  content: "Loading Sheet...";
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1rem;
  color: #cccccc;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  text-align: center;
  font-weight: 300;
}
.spread-container .spreadsheet--empty:after {
  content: "No Data Available.";
}
.spread-container .spreadsheet--empty.empty_results:after {
  content: "No Results Available.";
}
.spread-container .spreadsheet--empty.empty_period_equations:after {
  content: "No Period Equations Available.";
}

.sheet-status .error {
  line-height: 20px;
  padding: 5px 0;
  color: #ea5441;
}
.sheet-status .error:after {
  margin-left: 5px;
  content: "\e91f";
  font-family: "empyrean_icons";
  vertical-align: top;
}
.sheet-status .valid {
  line-height: 20px;
  padding: 5px 0;
}
.sheet-status .valid:after {
  color: #ea5441;
  margin-left: 5px;
  content: "\e91f";
  font-family: "empyrean_icons";
  vertical-align: top;
}

.fadein, .periodequations-tree .star,
.lookup-tree .star, .periodequations-tree .has_changes,
.lookup-tree .has_changes, .panel-left-body .tree ul,
.panel-left-body .tree .tree-nodes,
.panel-right-body .tree ul,
.panel-right-body .tree .tree-nodes {
  animation: fadein 0.3s;
}

.fadeout {
  animation: fadeout 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
form {
  margin: 0;
  padding: 0;
}

input,
select,
button {
  font-size: 1em;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  font-size: 1em;
  font-family: "Source Sans Pro", Helvetica, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
fieldset legend {
  display: block;
  box-sizing: border-box;
  line-height: 20px;
  padding: 5px 0 5px 0px;
}
fieldset legend.hidden_legend {
  position: absolute;
  font-size: 0;
}

/* Text Area */
textarea.inputbox {
  width: 100%;
  resize: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  border: 1px solid #ababab;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 5px 5px;
  line-height: 20px;
  vertical-align: bottom;
  background: #ffffff none 0 0 no-repeat;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  color: #505050;
}
textarea.inputbox.no_border {
  border: 1px solid transparent;
}
textarea.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
  padding-right: 25px;
}
textarea.inputbox:focus {
  border: 1px solid #505050;
}
textarea.inputbox:focus[readonly] {
  border: 1px solid #f5f6f7;
}
textarea.inputbox:focus[readonly][disabled] {
  border: 1px solid transparent;
}
textarea.inputbox.auto_width {
  width: initial;
}

/* Inputbox */
input.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 5px;
  border: 1px solid #ababab;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff none 0 0 no-repeat;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: "";
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  color: #505050;
}
input.inputbox.no_border {
  border: 1px solid transparent;
}
input.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
}
input.inputbox:focus {
  border: 1px solid #505050;
}
input.inputbox:focus[readonly] {
  border: 1px solid #ababab;
}
input.inputbox:focus[readonly][disabled] {
  border: 1px solid transparent;
}
input.inputbox.auto_width {
  width: initial;
}

input[type=date] {
  -webkit-appearance: none;
}

input[type=number] {
  -webkit-appearance: none;
}

/* Select */
select.inputbox {
  width: 100%;
  line-height: 20px;
  margin: 0;
  padding: 4px 25px 4px 5px;
  border: 1px solid #ababab;
  color: #505050;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff url("/assets/images/select_dropdown.png") no-repeat;
  background-position: right center;
  -webkit-padding-end: 25px;
  -moz-padding-end: 25px;
  -moz-background-size: 0% auto;
  text-indent: 0.01px;
  text-overflow: "";
  padding-right: 25px\9 ;
  cursor: pointer;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  select.inputbox {
    /* on retina, use image that's scaled by 2 */
    background-image: url("/assets/images/select_dropdown@2x.png");
    background-size: 20px 20px;
  }
}
select.inputbox.no_border {
  border: 1px solid transparent;
}
select.inputbox.no_dropdown {
  background: none !important;
  background-color: #ffffff !important;
  padding: 10px !important;
}
select.inputbox.add_item_select_box {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
select.inputbox[readonly] {
  border: 1px solid #cccccc;
  background-color: #eef0f1;
}
select.inputbox.no_dropdown {
  -webkit-padding-end: 0px;
  -moz-padding-end: 0px;
  padding-right: 0px\9 ;
  padding: 10px 0px 9px 0px;
}
select.inputbox:focus {
  border: 1px solid #505050;
  opacity: 1;
  filter: alpha(opacity=100);
}
select.inputbox.auto_width {
  width: initial;
}
select.inputbox option {
  color: #505050;
}
select.inputbox option[disabled] {
  color: #cccccc;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select::-ms-expand {
  display: none;
  text-indent: 0.01px;
  text-overflow: "";
}

select::-moz-focus-inner {
  padding: 0;
  border: 0;
  margin: 0px;
  background-image: none !important;
}

@-moz-document url-prefix() {
  select.inputbox {
    padding: 6px 25px 7px 5px;
  }
}
/* Disabled States */
input[disabled],
textarea[disabled],
select[disabled] {
  -webkit-text-fill-color: #cccccc;
  -webkit-opacity: 1;
  color: #cccccc;
  background: transparent;
}

input.inputbox[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

select.inputbox[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

textarea[disabled] {
  background-color: #f5f6f7;
  border: 1px solid #f5f6f7;
  color: #cccccc;
  cursor: default;
}

/* Placeholder Text */
.inputbox::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.inputbox:placeholder-shown {
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Error States */
/* Datepicker */
.btn_icon_date {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border: 0;
  padding: 7px;
  -webkit-border-radius: 0px 4px 4px 0px;
  border-radius: 0px 4px 4px 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  cursor: pointer;
}

.btn_icon_date:focus,
.btn_icon_date:hover {
  color: #ffffff;
  background-color: #72bf44;
}

/* Inputbox Widths */
input.inputbox.width_x-small,
select.inputbox.width_x-small,
textarea.inputbox.width_x-small {
  width: 60px;
  display: inline-block;
}
input.inputbox.width_small,
select.inputbox.width_small,
textarea.inputbox.width_small {
  width: 80px;
  display: inline-block;
}

.inputbox.invalid {
  border-color: #ea5441;
}

.invalid_message {
  display: inline-block;
  line-height: 20px;
  clear: both;
  color: #ea5441;
}

@media all and (-ms-high-contrast: none) {
  input.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  input.inputbox {
    height: 30px;
  } /* IE11 */
  select.inputbox {
    height: 30px;
  } /* IE10 */
  *::-ms-backdrop,
  select.inputbox {
    height: 30px;
  } /* IE11 */
  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  }
  *::-ms-backdrop,
  textarea.inputbox[readonly] {
    overflow: auto;
    -ms-overflow-style: none;
  } /* IE11 */
}
.inputbox[type=checkbox]:checked,
.inputbox[type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.inputbox[type=checkbox]:checked + label,
.inputbox[type=checkbox]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px !important;
}

.inputbox[type=checkbox]:checked + label:before,
.inputbox[type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #ababab;
  background: #ffffff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

.inputbox[type=checkbox]:checked + label:after,
.inputbox[type=checkbox]:not(:checked) + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid #2b495e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.inputbox[type=checkbox]:hover + label,
.inputbox[type=checkbox]:focus + label {
  color: #505050;
}

.inputbox[type=checkbox]:hover + label:before,
.inputbox[type=checkbox]:focus + label:before {
  border: 1px solid #505050;
}

.inputbox[type=checkbox]:disabled:checked + label,
.inputbox[type=checkbox]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 5px 0 5px 20px;
}

.inputbox[type=checkbox]:disabled:checked + label:before,
.inputbox[type=checkbox]:disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  background: #eceef3;
}

.inputbox[type=checkbox]:disabled:checked + label:after,
.inputbox[type=checkbox]:disabled:not(:checked) + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid #525b6b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.inputbox[type=checkbox]:not(:checked) + label:after {
  opacity: 0;
}

.inputbox[type=checkbox]:checked + label:after {
  opacity: 1;
  border-color: #ffffff;
}

.inputbox[type=checkbox]:checked + label:before {
  border-color: #2b495e;
  border-width: 0;
  height: 16px;
  width: 16px;
  background-color: #2b495e;
}

.inputbox[type=radio]:checked,
.inputbox[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.inputbox[type=radio]:checked + label,
.inputbox[type=radio]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px !important;
}

.inputbox[type=radio]:checked + label:before,
.inputbox[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #ababab;
  border-radius: 100%;
  background: #ffffff;
}

.inputbox[type=radio]:checked + label:after,
.inputbox[type=radio]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #2b495e;
  position: absolute;
  top: 9px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.inputbox[type=radio]:focus + label,
.inputbox[type=radio]:hover + label {
  color: #505050;
}

.inputbox[type=radio]:focus + label:before,
.inputbox[type=radio]:hover + label:before {
  border: 1px solid #505050;
}

.inputbox[type=radio]:disabled:checked + label,
.inputbox[type=radio]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #505050;
  padding: 5px 0 5px 20px;
}

.inputbox[type=radio]:disabled:checked + label:before,
.inputbox[type=radio]:disabled:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border: 1px solid #cccccc;
  border-radius: 100%;
  background: #eceef3;
}

.inputbox[type=radio]:disabled:checked + label:after,
.inputbox[type=radio]:disabled:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #525b6b;
  position: absolute;
  top: 9px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.inputbox[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.inputbox[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.inputbox[type=radio]:checked + label:before {
  border-color: #2b495e;
}

/* Button Group */
ul.button_group {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.button_group > li {
  float: left;
  margin: 0 !important;
}
ul.button_group > li:first-child button {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  background-clip: padding-box;
  display: block;
  margin-left: 0;
}
ul.button_group > li:last-child button {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  background-clip: padding-box;
  display: block;
}

button.btn_group {
  width: auto;
  background-color: transparent;
  position: relative;
  color: #505050;
  line-height: 20px;
  margin: 0px;
  padding: 4px 10px;
  overflow: visible;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #505050;
  cursor: pointer;
  min-width: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin-left: -1px;
  min-width: 60px;
}
button.btn_group:hover, button.btn_group:focus {
  border-color: #72bf44;
  color: #72bf44;
  z-index: 1;
}
button.btn_group.btn_selected {
  border-color: #505050;
  background-color: #505050;
  color: #ffffff;
}
button.btn_group.btn_selected:hover, button.btn_group.btn_selected:focus {
  border-color: #505050;
  background-color: #505050;
  color: #ffffff;
}

button.btn_dropdown {
  border: none;
  text-align: left;
  border-color: #ababab;
}
button.btn_dropdown .btn_text,
button.btn_dropdown .btn_icon_arrow,
button.btn_dropdown .btn_icon,
button.btn_dropdown .pkg_icon,
button.btn_dropdown .pkg_name,
button.btn_dropdown .btn_indicator {
  pointer-events: none;
}
button.btn_dropdown .btn_text span,
button.btn_dropdown .btn_text div,
button.btn_dropdown .btn_icon_arrow span,
button.btn_dropdown .btn_icon_arrow div,
button.btn_dropdown .btn_icon span,
button.btn_dropdown .btn_icon div,
button.btn_dropdown .pkg_icon span,
button.btn_dropdown .pkg_icon div,
button.btn_dropdown .pkg_name span,
button.btn_dropdown .pkg_name div,
button.btn_dropdown .btn_indicator span,
button.btn_dropdown .btn_indicator div {
  pointer-events: none;
}
.dropdown_container {
  position: relative;
  display: inline-block;
}
.dropdown_container.full_width {
  display: block;
}
.dropdown_container.inline {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom;
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
  display: none;
  min-width: 180px;
  background: #ffffff;
  padding: 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: calc(50vh + 50px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  width: 340px;
  z-index: 1000001;
  color: #505050;
  text-align: left;
}
.dropdown_content.no_scroll_bar {
  max-height: inherit;
}
.dropdown_content.has_padding {
  padding: 10px;
}
.dropdown_content.position_right {
  right: 0;
}
.dropdown_content.full_width {
  left: 0;
  right: 0;
  min-width: inherit;
}
.dropdown_content.min_width {
  min-width: 320px;
}
.dropdown_content .dropdown_content_body {
  padding: 10px;
}
.dropdown_content .dropdown_content_body p:last-of-type {
  margin: 0;
  padding: 0;
}
.dropdown_content .dropdown_footer {
  display: block;
  padding: 10px 10px;
  border-top: 1px solid #dfe0e1;
}
.dropdown_content .dropdown_footer button {
  width: 100%;
}
.dropdown_content .dropdown_footer.align_center {
  text-align: center;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown_content container when the user clicks on the dropdown button) */
.dropdown_content.show {
  display: block;
}

.btn_drawer {
  border: none;
  display: block;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  padding: 5px 0 5px 20px;
  width: 100%;
  text-align: left;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: transparent;
  color: #505050;
  margin: 0px;
  overflow: visible;
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 0px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn_drawer .btn_icon {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.btn_drawer:hover, .btn_drawer:focus {
  color: #51738b;
}
.btn_drawer:hover {
  background-color: #f5f6f7;
}
.btn_drawer.btn_drawer_active {
  color: #505050;
}
.btn_drawer.btn_drawer_active .btn_icon {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.drawer_container {
  /* Drawer Content (Hidden by Default) */
}
.drawer_container .drawer_header {
  position: relative;
  padding-left: 30px;
}
.drawer_container .drawer_header .drawer_header_toggle {
  position: absolute;
  left: 0;
  top: 0;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content {
  margin-left: 30px;
}
.drawer_container .drawer_header .drawer_header_title .drawer_content .tree_link {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.drawer_container .drawer_header .drawer_header_title a {
  font-weight: 600;
}
.drawer_container .drawer_content {
  display: none;
  position: relative;
}
.drawer_container .drawer_content.drawer_active {
  display: block;
}

.drawer_active {
  font-weight: bold !important;
  color: #72bf44 !important;
}

.margin_r-5 {
  margin-right: 5px;
}

.margin_r-10 {
  margin-right: 10px;
}

.margin_r-15 {
  margin-right: 15px;
}

.margin_r-20 {
  margin-right: 20px;
}

.margin_r-25 {
  margin-right: 25px;
}

.margin_r-30 {
  margin-right: 30px;
}

.margin_l-5 {
  margin-left: 5px;
}

.margin_l-10 {
  margin-left: 10px;
}

.margin_l-15 {
  margin-left: 15px;
}

.margin_l-20 {
  margin-left: 20px;
}

.margin_l-25 {
  margin-left: 25px;
}

.margin_l-30 {
  margin-left: 30px;
}

.margin_t-5 {
  margin-top: 5px;
}

.margin_t-10 {
  margin-top: 10px;
}

.margin_t-15 {
  margin-top: 15px;
}

.margin_t-20 {
  margin-top: 20px;
}

.margin_t-25 {
  margin-top: 25px;
}

.margin_t-30 {
  margin-top: 30px;
}

.margin_b-5 {
  margin-bottom: 5px;
}

.margin_b-10 {
  margin-bottom: 10px;
}

.margin_b-15 {
  margin-bottom: 15px;
}

.margin_b-20 {
  margin-bottom: 20px;
}

.margin_b-25 {
  margin-bottom: 25px;
}

.margin_b-30 {
  margin-bottom: 30px;
}

.margin_5 {
  margin: 5px;
}

.margin_10 {
  margin: 10px;
}

.margin_15 {
  margin: 15px;
}

.margin_20 {
  margin: 20px;
}

.margin_25 {
  margin: 25px;
}

.margin_30 {
  margin: 30px;
}

.margin_h-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.margin_h-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.margin_h-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.margin_h-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.margin_h-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.margin_h-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.margin_v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin_v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin_v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin_v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin_v-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin_v-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.padding_r-5 {
  padding-right: 5px;
}

.padding_r-10 {
  padding-right: 10px;
}

.padding_r-15 {
  padding-right: 15px;
}

.padding_r-20 {
  padding-right: 20px;
}

.padding_r-25 {
  padding-right: 25px;
}

.padding_r-30 {
  padding-right: 30px;
}

.padding_l-5 {
  padding-left: 5px;
}

.padding_l-10 {
  padding-left: 10px;
}

.padding_l-15 {
  padding-left: 15px;
}

.padding_l-20 {
  padding-left: 20px;
}

.padding_l-25 {
  padding-left: 25px;
}

.padding_l-30 {
  padding-left: 30px;
}

.padding_t-5 {
  padding-top: 5px;
}

.padding_t-10 {
  padding-top: 10px;
}

.padding_t-15 {
  padding-top: 15px;
}

.padding_t-20 {
  padding-top: 20px;
}

.padding_t-25 {
  padding-top: 25px;
}

.padding_t-30 {
  padding-top: 30px;
}

.padding_b-5 {
  padding-bottom: 5px;
}

.padding_b-10 {
  padding-bottom: 10px;
}

.padding_b-15 {
  padding-bottom: 15px;
}

.padding_b-20 {
  padding-bottom: 20px;
}

.padding_b-25 {
  padding-bottom: 25px;
}

.padding_b-30 {
  padding-bottom: 30px;
}

.padding_5 {
  padding: 5px;
}

.padding_10 {
  padding: 10px;
}

.padding_15 {
  padding: 15px;
}

.padding_20 {
  padding: 20px;
}

.padding_25 {
  padding: 25px;
}

.padding_30 {
  padding: 30px;
}

.padding_h-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.padding_h-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.padding_h-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.padding_h-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.padding_h-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.padding_h-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.padding_v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding_v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding_v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding_v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding_v-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding_v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

table.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  empty-cells: show;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
table.dataTable.hidden_header thead {
  position: absolute;
  font-size: 0;
  padding: 0;
  height: 0;
  width: 0;
  display: block;
}
table.dataTable.hidden_header thead tr td, table.dataTable.hidden_header thead tr th {
  font-size: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0;
  filter: alpha(opacity=0);
}
table.dataTable.hidden_header tbody tr:first-child th, table.dataTable.hidden_header tbody tr:first-child td {
  border-top: 1px solid #dfe0e1;
}
table.dataTable thead tr th, table.dataTable thead tr td {
  text-align: left;
  border-bottom: 1px solid #dfe0e1;
  background-color: #eceef3;
  vertical-align: top;
  padding: 5px;
  line-height: 20px;
  min-height: 20px;
}
table.dataTable thead tr th.col_chbox, table.dataTable thead tr td.col_chbox {
  width: 20px;
  padding: 0 5px !important;
}
table.dataTable thead tr th.col_icon, table.dataTable thead tr td.col_icon {
  width: 20px;
}
table.dataTable thead tr th.col_coldchain, table.dataTable thead tr td.col_coldchain {
  width: 50px;
}
table.dataTable thead tr th [class^=icon_],
table.dataTable thead tr th [class*=" icon_"], table.dataTable thead tr td [class^=icon_],
table.dataTable thead tr td [class*=" icon_"] {
  font-size: 16px;
  display: inline-block;
  vertical-align: text-bottom;
}
table.dataTable thead tr th:empty, table.dataTable thead tr td:empty {
  height: 20px;
}
table.dataTable thead tr th.nowrap, table.dataTable thead tr td.nowrap {
  white-space: nowrap;
}
table.dataTable tbody tr th, table.dataTable tbody tr td {
  border-bottom: 1px solid #dfe0e1;
  vertical-align: top;
  padding: 5px;
  line-height: 20px;
  min-height: 20px;
}
table.dataTable tbody tr th.col_chbox, table.dataTable tbody tr td.col_chbox {
  width: 20px;
  padding: 0 5px !important;
}
table.dataTable tbody tr th.col_icon, table.dataTable tbody tr td.col_icon {
  width: 20px;
}
table.dataTable tbody tr th.col_coldchain, table.dataTable tbody tr td.col_coldchain {
  width: 50px;
}
table.dataTable tbody tr th:empty, table.dataTable tbody tr td:empty {
  height: 20px;
}
table.dataTable tbody tr th.nowrap, table.dataTable tbody tr td.nowrap {
  white-space: nowrap;
}
table.dataTable tbody tr th .table_btn_container, table.dataTable tbody tr td .table_btn_container {
  display: block;
  float: left;
  margin: 0px 0;
  vertical-align: top;
}
table.dataTable tbody tr th .table_btn_container button, table.dataTable tbody tr td .table_btn_container button {
  height: 30px;
  vertical-align: top;
}
table.dataTable tbody tr th [class^=icon_],
table.dataTable tbody tr th [class*=" icon_"], table.dataTable tbody tr td [class^=icon_],
table.dataTable tbody tr td [class*=" icon_"] {
  font-size: 16px;
  display: inline-block;
  vertical-align: text-bottom;
}
table.dataTable tbody tr:hover > td,
table.dataTable tbody tr:hover > th {
  background-color: rgba(81, 115, 139, 0.1);
}
table.dataTable tbody tr.row_swog > th:first-child,
table.dataTable tbody tr.row_swog > td:first-child {
  background-color: #f5f6f7;
}
table.dataTable tbody tr.row_multipiece > th,
table.dataTable tbody tr.row_multipiece > td {
  background-color: #ffffff;
}
table.dataTable tbody tr.selected_row > th,
table.dataTable tbody tr.selected_row > td {
  background-color: rgba(81, 115, 139, 0.2) !important;
}
table.dataTable tbody tr.selected_row > th a,
table.dataTable tbody tr.selected_row > td a {
  color: inherit;
}
table.dataTable tbody tr.row_hidden {
  display: none;
}
table.dataTable tbody tr.row_hidden.row_visible {
  display: table-row;
}
table.dataTable tbody tr.row_visible {
  display: table-row;
}

#table_selection_options {
  float: left;
  display: none;
  margin-left: 10px;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
}

.ui-widget {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

.hide_title_bar .ui-dialog-titlebar {
  display: none;
}

.ui-dialog {
  border: 0px solid !important;
  -webkit-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.14), 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 15px rgba(0, 0, 0, 0.2);
  padding: 0;
}
.ui-dialog .ui-dialog-content {
  padding: 0;
}
.ui-dialog .ui-dialog-content.maximized {
  width: 100% !important;
  height: calc(100% - 50px) !important;
  top: 50px !important;
  position: absolute !important;
}

.ui-widget-overlay {
  background: rgba(43, 73, 94, 0.3) !important;
  opacity: 1;
  filter: alpha(opacity=100);
}

.ui-widget button {
  font-size: 0.76rem;
}

.dialog_header {
  border-bottom: 0px solid #dfe0e1;
  padding: 15px 15px 0;
}
.dialog_header .dialog_back {
  position: absolute;
  left: 10px;
  top: 10px;
}
.dialog_header .dialog_close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  background-color: #ffffff;
}
.dialog_header h1 {
  font-size: 1.2em;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  color: #2b495e;
}
.dialog_header .dialog_desc {
  text-align: center;
  color: #525b6b;
  margin: 10px 0;
  padding: 0;
}

/* Dialog Content */
.dialog_content {
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* Dialog Actions */
}
.dialog_content.padding--all {
  padding: 15px;
}
.dialog_content.padding--left {
  padding-left: 15px;
}
.dialog_content.padding--right {
  padding-right: 15px;
}
.dialog_content.padding--top {
  padding-top: 15px;
}
.dialog_content.padding--bottom {
  padding-bottom: 15px;
}
.dialog_content.padding--vertical {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dialog_content.padding--horizontal {
  padding-left: 15px;
  padding-right: 15px;
}
.dialog_content .dialog_content_inner {
  padding: 15px;
  overflow: auto;
  position: relative;
}
.dialog_content .dialog_content_inner.no_padding {
  padding: 0;
}
@media (max-width: 767px) {
  .dialog_content .dialog_content_inner {
    padding: 15px 15px 10px;
  }
}
.dialog_content .dialog_content_inner:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.dialog_content .dialog_inner_heading_below {
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1em;
  text-align: center;
}
.dialog_content .dialog_actions {
  padding: 15px;
  border-top: 1px solid #dfe0e1;
}
.dialog_content .dialog_actions.fixed_width {
  min-width: 480px;
  max-width: 480px;
}
@media (max-width: 767px) {
  .dialog_content .dialog_actions.fixed_width {
    min-width: 290px;
    max-width: 290px;
  }
}
.dialog_content .dialog_actions .actions_left {
  float: left;
}
.dialog_content .dialog_actions .actions_left button,
.dialog_content .dialog_actions .actions_left input[type=button] {
  margin-right: 10px;
  float: left;
}
.dialog_content .dialog_actions .actions_left button:last-of-type,
.dialog_content .dialog_actions .actions_left input[type=button]:last-of-type {
  margin-right: 0;
}
.dialog_content .dialog_actions .actions_right {
  float: right;
}
.dialog_content .dialog_actions .actions_right button,
.dialog_content .dialog_actions .actions_right input[type=button] {
  margin-left: 10px;
  float: left;
}
.dialog_content .dialog_actions .actions_right button:first-of-type,
.dialog_content .dialog_actions .actions_right input[type=button]:first-of-type {
  margin-left: 0;
}
.dialog_content .dialog_actions .actions_center {
  float: none;
  text-align: center;
}
.dialog_content .dialog_actions .actions_center button,
.dialog_content .dialog_actions .actions_center input[type=button] {
  margin: 0 5px;
}

/* Dialog Actions */
.dialog_actions {
  padding: 15px;
  position: relative;
}
.dialog_actions:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  position: absolute;
}
.dialog_actions.fixed_width {
  min-width: 480px;
  max-width: 480px;
}
@media (max-width: 767px) {
  .dialog_actions.fixed_width {
    min-width: 290px;
    max-width: 290px;
  }
}
.dialog_actions .actions_left {
  float: left;
}
.dialog_actions .actions_left button,
.dialog_actions .actions_left input[type=button] {
  margin-right: 10px;
  float: left;
}
.dialog_actions .actions_left button:last-of-type,
.dialog_actions .actions_left input[type=button]:last-of-type {
  margin-right: 0;
}
.dialog_actions .actions_right {
  float: right;
}
.dialog_actions .actions_right button,
.dialog_actions .actions_right input[type=button] {
  margin-left: 10px;
  float: left;
}
.dialog_actions .actions_right button:first-of-type,
.dialog_actions .actions_right input[type=button]:first-of-type {
  margin-left: 0;
}
.dialog_actions .actions_center {
  float: none;
  text-align: center;
}
.dialog_actions .actions_center button,
.dialog_actions .actions_center input[type=button] {
  margin: 0 5px;
}

/* Button Group for Adding Order */
.button_group > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
}
.button_group > ul > li {
  float: left;
  width: 33.3333333333%;
  margin: 0px 0;
}

.modal_footer_total {
  position: relative;
}
.modal_footer_total .modal_footer_total_value {
  line-height: 30px;
  font-size: 1.4rem;
  font-weight: 600;
  top: 0px;
  position: relative;
}

.dialog_tabs {
  position: relative;
}
.dialog_tabs .dialog_close {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
.dialog_tabs .dialog_close button {
  color: #ffffff;
}

#tab_nav {
  padding: 0px 0px 0;
  position: relative;
}
#tab_nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  height: 40px;
}
#tab_nav > ul:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
#tab_nav > ul > li {
  display: block;
  float: left;
  height: 100%;
}

button.btn_tab {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: transparent;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.03));
  position: relative;
  color: #ffffff;
  border-bottom: 0px solid #cccccc;
  text-transform: inherit;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
}
button.btn_tab.btn_text {
  line-height: 0;
}
button.btn_tab:hover, button.btn_tab:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
button.btn_tab.btn_tab_active {
  background: transparent;
  background-color: #ffffff !important;
  color: #2b495e;
}
button.btn_tab.btn_tab_active:hover, button.btn_tab.btn_tab_active:focus {
  background: transparent;
  background-color: #ffffff !important;
  color: #2b495e;
}
button.btn_tab.btn_tab_active:after {
  display: none;
}

.tab_content {
  display: none;
  padding: 0px;
}
.tab_content.tab_content_active {
  display: block;
}

.panel_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  min-height: 0;
  width: 100%;
  -webkit-justify-content: flex-start;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: column;
}
.panel_container .panel_header {
  position: relative;
  padding-bottom: 10px;
}
.panel_container .panel_header.no_padding {
  padding-bottom: 0;
}
.panel_container .panel_header .panel_header_actions {
  position: absolute;
  right: 0;
  top: 0;
}
.panel_container .panel_controls {
  padding-bottom: 10px;
}
.panel_container .panel_content {
  flex: 1;
  overflow: auto;
}
.panel_container .panel_content .panel_content_inner {
  overflow: auto;
}

.toggle_switch_radio[type=radio]:checked,
.toggle_switch_radio[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.toggle_switch_radio[type=radio]:checked + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #505050;
  background-color: #ffffff;
  padding: 4px 10px 4px 10px !important;
  text-align: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dfe0e1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggle_switch_radio[type=radio]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: rgba(80, 80, 80, 0.5);
  padding: 4px 10px 4px 10px !important;
  text-align: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eceef3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggle_switch_radio[type=radio]:focus + label,
.toggle_switch_radio[type=radio]:hover + label {
  color: #505050;
}

.toggle_switch_radio[type=radio]:disabled:checked + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 4px 0 4px 0px;
}

.toggle_switch_radio[type=radio]:disabled:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #cccccc;
  padding: 4px 0 4px 0px;
}

.toggle_switch {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.toggle_switch .toggle_switch_option {
  flex-grow: 1;
}

.dropzone {
  padding-top: 5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4.5rem 1rem;
  position: relative;
  border: 3px dashed #dfe0e1;
}
.dropzone .dz-message {
  text-align: center;
}
.dropzone .dz-message .dz_msg_icon {
  color: #2b495e;
}
.dropzone .dz-message .dz_msg_icon span {
  vertical-align: text-bottom;
}
.dropzone .dz-message .dz_msg_instruction {
  color: #2b495e;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
}
.dropzone .dz-message .dz_msg_helper {
  line-height: 140%;
  margin-bottom: 10px;
}
.dropzone .dz-preview .dz-details {
  text-align: center;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: inline-block;
}
.dropzone .dz-preview .dz-details .dz-size {
  display: inline-block;
}
.dropzone .dz-preview .dz-details img {
  display: none;
}
.dropzone .dz-preview .dz-success-mark {
  display: inline-block;
  text-align: center;
  display: none;
}
.dropzone .dz-preview .dz-error-mark {
  display: inline-block;
  text-align: center;
  display: none;
}
.dropzone .dz-preview .dz-error-message {
  color: #ea5441;
  line-height: 140%;
  margin-top: 20px;
  text-align: center;
}

[tooltip] {
  position: relative;
}

[tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  z-index: 100;
  opacity: 0;
  filter: alpha(opacity=0);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  color: #ffffff;
  padding: 5px 5px;
  font-size: 0.8rem;
  line-height: 140%;
  min-width: 140px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 101;
}

[tooltip-position=left]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}

[tooltip-position=top]::before {
  left: 50%;
}

[tooltip-position=bottom]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip-position=right]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip-position=left]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

[tooltip-position=top]::after {
  left: 50%;
}

[tooltip-position=bottom]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

[tooltip-position=right]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  filter: alpha(opacity=100);
}

/*

    HELPERS

*/
.link {
  cursor: pointer;
}

.full-size, .modal-body .component-body .panel-left,
.modal-body .component-body .panel-right, app-account-tree,
app-equation-bar,
app-exogenous-lines-equations,
app-exogenous-lines,
app-hover-notification,
app-import-alm-dataset,
app-input-structure,
app-invalid-rows,
app-liquidity-sources,
app-liquity-equations,
app-lookup-data,
app-lookup-setup,
app-lookup-tree,
app-root,
app-balance-sheet,
app-scenario-list,
app-simulation-set,
app-template,
gc-spread-sheets {
  height: 100%;
  width: 100%;
}

.display-content, app-account-tree,
app-lookup-tree,
app-manage-results {
  display: contents;
}

.page-container,
.fill {
  height: 100%;
  width: 100%;
  display: contents;
}

.bold {
  font-weight: bold;
}

.hidden,
.invisible {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.flex {
  display: flex;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.border--left {
  border-left: 1px solid #dfe0e1;
}

.border--right {
  border-right: 1px solid #dfe0e1;
}

.border--bottom, .notification-banner-message {
  border-bottom: 1px solid #dfe0e1;
}

.border--top {
  border-top: 1px solid #dfe0e1;
}

.border--all {
  border: 1px solid #dfe0e1;
}

.shadow, .top-inner-shadow:before, .notification-banner-message:before {
  content: "";
  position: relative;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 10px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  border: 0;
  overflow: hidden;
}

.top-inner-shadow:before, .notification-banner-message:before {
  top: -11px;
}

#login_container {
  max-width: 400px;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4), 0 0px 50px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 40px auto;
}

.product_suite_container {
  text-align: center;
  margin-top: 20px;
}

.product_suit_options {
  max-width: 720px;
  margin: 0 auto;
}

ul#product_suite {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#product_suite > li {
  float: left;
  width: 25%;
  text-align: center;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul#product_suite > li .btn_product {
  display: block;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  text-align: center;
  padding: 20px;
  color: inherit;
  width: 160px;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
ul#product_suite > li .btn_product:hover, ul#product_suite > li .btn_product:focus {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
}
ul#product_suite > li .btn_product:hover .application_icon, ul#product_suite > li .btn_product:focus .application_icon {
  background-color: #51738b;
  color: #ffffff;
}
ul#product_suite > li .btn_product:hover .application_name, ul#product_suite > li .btn_product:focus .application_name {
  color: #51738b;
}
ul#product_suite > li .btn_product.btn_normal:disabled, ul#product_suite > li .btn_product.success-button:disabled, ul#product_suite > li .btn_product.btn_small:disabled, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled {
  background-color: #f5f6f7 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  box-shadow: none !important;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover, ul#product_suite > li .btn_product.success-button:disabled:hover, ul#product_suite > li .btn_product.btn_small:disabled:hover, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover, ul#product_suite > li .btn_product.btn_normal:disabled:focus, ul#product_suite > li .btn_product.success-button:disabled:focus, ul#product_suite > li .btn_product.btn_small:disabled:focus, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus {
  background-color: #f5f6f7 !important;
  opacity: 1;
  filter: alpha(opacity=100);
  box-shadow: none !important;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover .application_icon, ul#product_suite > li .btn_product.success-button:disabled:hover .application_icon, ul#product_suite > li .btn_product.btn_small:disabled:hover .application_icon, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover .application_icon, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover .application_icon, ul#product_suite > li .btn_product.btn_normal:disabled:focus .application_icon, ul#product_suite > li .btn_product.success-button:disabled:focus .application_icon, ul#product_suite > li .btn_product.btn_small:disabled:focus .application_icon, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus .application_icon, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus .application_icon,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus .application_icon,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus .application_icon {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit;
}
ul#product_suite > li .btn_product.btn_normal:disabled:hover .application_name, ul#product_suite > li .btn_product.success-button:disabled:hover .application_name, ul#product_suite > li .btn_product.btn_small:disabled:hover .application_name, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:hover .application_name, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:hover .application_name,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:hover .application_name,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:hover .application_name, ul#product_suite > li .btn_product.btn_normal:disabled:focus .application_name, ul#product_suite > li .btn_product.success-button:disabled:focus .application_name, ul#product_suite > li .btn_product.btn_small:disabled:focus .application_name, ul#product_suite > li .notification-banner-message .banner-actions a.btn_product:disabled:focus .application_name, .notification-banner-message .banner-actions ul#product_suite > li a.btn_product:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.link:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.link:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions .btn_product.banner-action-button:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li .btn_product.banner-action-button:disabled:focus .application_name,
ul#product_suite > li .notification-banner-message .banner-actions button.btn_product:disabled:focus .application_name,
.notification-banner-message .banner-actions ul#product_suite > li button.btn_product:disabled:focus .application_name {
  color: inherit;
}
ul#product_suite > li .btn_product .application_icon {
  display: block;
  padding: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  background-color: rgba(0, 0, 0, 0.05);
  width: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto 10px;
}
ul#product_suite > li .btn_product .application_icon span {
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  display: block;
  vertical-align: top;
}
ul#product_suite > li .btn_product .application_name {
  text-align: center;
  display: block;
  line-height: 15px;
  min-height: 30px;
  font-size: 0.8rem;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: scroll; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #000000;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 8px;
  height: 3px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

@font-face {
  font-family: "empyrean_icons";
  src: url("fonts/empyrean_icons.ttf?bn0vb1") format("truetype"), url("fonts/empyrean_icons.woff?bn0vb1") format("woff"), url("fonts/empyrean_icons.svg?bn0vb1#empyrean_icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon_],
[class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "empyrean_icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_transpose:before {
  content: "\e92b";
}

.icon_triangle_down:before {
  content: "\e928";
}

.icon_triangle_up:before {
  content: "\e929";
}

.icon_products:before {
  content: "\e92a";
}

.icon_asset_liability_management:before {
  content: "\e918";
}

.icon_assumptions:before {
  content: "\e925";
}

.icon_chevron_down:before {
  content: "\e900";
}

.icon_chevron_left:before {
  content: "\e901";
}

.icon_chevron_right:before {
  content: "\e902";
}

.icon_chevron_up:before {
  content: "\e903";
}

.icon_close:before {
  content: "\e908";
}

.icon_current_expected_credit_loss:before {
  content: "\e919";
}

.icon_data_set:before {
  content: "\e90a";
}

.icon_delete:before {
  content: "\e916";
}

.icon_edit:before {
  content: "\e926";
}

.icon_error:before {
  content: "\e91f";
}

.icon_funds_transfer_pricing:before {
  content: "\e91a";
}

.icon_grid:before {
  content: "\e904";
}

.icon_ifrs:before {
  content: "\e91b";
}

.icon_liquidity_modeling:before {
  content: "\e91c";
}

.icon_lock:before {
  content: "\e917";
}

.icon_logo_empyrean:before {
  content: "\e905";
}

.icon_msg_error:before {
  content: "\e920";
}

.icon_msg_success:before {
  content: "\e921";
}

.icon_notification:before {
  content: "\e911";
}

.icon_plus:before {
  content: "\e906";
}

.icon_quicklinks:before {
  content: "\e912";
}

.icon_result_set:before {
  content: "\e913";
}

.icon_sheet_loader:before {
  content: "\e90d";
}

.icon_spinner:before {
  content: "\e924";
}

.icon_stochastic_income_simulation:before {
  content: "\e91d";
}

.icon_stress_testing:before {
  content: "\e91e";
}

.icon_summary:before {
  content: "\e914";
}

.icon_toggle_drawer_open:before {
  content: "\e90e";
}

.icon_toggle_drawer:before {
  content: "\e90f";
}

.icon_trash:before {
  content: "\e90c";
}

.icon_triangle_left:before {
  content: "\e915";
}

.icon_triangle_right:before {
  content: "\e910";
}

.icon_undelete:before {
  content: "\e927";
}

.icon_upload:before {
  content: "\e909";
}

.icon_user:before {
  content: "\e907";
}

.icon_valid:before {
  content: "\e922";
}

.icon_warning:before {
  content: "\e923";
}

.icon_dropdown:before {
  content: "\e90b";
}

.icon_tree:before {
  content: "\e92e";
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: rgba(43, 73, 94, 0.6588235294);
  background-clip: padding-box;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 1px #edf1f6;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #edf1f6;
}