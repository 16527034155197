table.dataTable {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	empty-cells: show;
	background-color: $white;
	@include box-sizing(border-box);
	
	&.hidden_header {
		thead {
			position: absolute;
			font-size: 0;
			padding: 0;
			height: 0;
			width: 0;
			display: block;

			tr {
				td,th {
					font-size: 0 !important;
					padding: 0 !important;
					height: 0 !important;
					width: 0 !important;
					@include opacity(0);
				}
			}
		}

		tbody {
			tr {
				&:first-child {
					th,td {
						border-top: 1px solid $border_color;
					}
				}
			}
		}
	}
	
	thead {
		tr {
			th,td {
				text-align: left;
				border-bottom: 1px solid $border_color;
				background-color: $light_grey;
				vertical-align: top;
				padding: 5px;
				line-height: 20px;
				min-height: 20px;

				&.col_chbox {
					width: 20px;
					padding: 0 5px !important;
				}

				&.col_icon {
					width: 20px;
				}

				&.col_coldchain {
					width: 50px;
				}

				[class^="icon_"], 
				[class*=" icon_"] {
					font-size: 16px;
					display: inline-block;
					vertical-align: text-bottom;
				}

				&:empty {
					height: 20px;
				}

				&.nowrap {
					white-space: nowrap;
				}
				
			}
		}
	}
	
	tbody {
		tr {
			th,td {
				border-bottom: 1px solid $border_color;
				vertical-align: top;
				padding: 5px;
				line-height: 20px;
				min-height: 20px;

				&.col_chbox {
					width: 20px;
					padding: 0 5px !important;
				}

				&.col_icon {
					width: 20px;
				}

				&.col_coldchain {
					width: 50px;
				}

				&:empty {
					height: 20px;
				}

				&.nowrap {
					white-space: nowrap;
				}

				.table_btn_container {
					display: block;
					float: left;
					margin: 0px 0;
					vertical-align: top;

					button {
						height: 30px;
						vertical-align: top;
					}
				}

				[class^="icon_"], 
				[class*=" icon_"] {
					font-size: 16px;
					display: inline-block;
					vertical-align: text-bottom;
				}

			}

			&:hover {
				>td,
				>th {
					background-color: $state_hover;
				}
			}

			&.row_swog {
				>th,
				>td {
					// 

					&:first-child {
						background-color: $lighter_grey;
					}
				}
			}

			&.row_multipiece {
				>th,
				>td {
						background-color: $body_background;
				}
			}

			&.selected_row {
				>th,
				>td {
					background-color: rgba($blue, 0.2) !important;

					a {
						color: inherit;
					}
				}
			}

			&.row_hidden {
				display: none;

				&.row_visible {
					display: table-row;
				}
			}

			&.row_visible {
				display: table-row;
			}

		}
	}
}


// Button Options for Selected Table Row

#table_selection_options {
	float: left;
	display: none;
	margin-left: 10px;
	border-left: 1px solid $grey;
	padding-left: 10px;
}
