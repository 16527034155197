#footer_bar {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;
  position: relative;

  &:before {
    content: '';
    height: 1px;
    background-color: rgba($black, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }

  #footer_result_set {
    @include box-sizing(border-box);
    padding: 10px;
    padding-right: 0;
  }

  #footer_quick_link {
    @include box-sizing(border-box);
    padding: 10px;
    padding-left: 0;
    position: relative;
  }

  #footer_report_summary {
    @include box-sizing(border-box);
    padding: 0 10px;
    padding-left: 0;

    button {
      &.btn_sheet {
        min-width: 3rem;
        background-color: $white;
        @include border-radius(0);
        @include border-bottom-radius(3px);
        padding: 10px;
        position: relative;
        color: rgba($app_color, 0.5);
        text-transform: inherit;
        font-size: 0.86rem;
        font-weight: 400;
        // min-width: 100px;

        &:before {
          content: '';
          height: 3px;
          background-color: rgba($black, 0.05);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
        }

        &:after {
          content: '';
          height: 1px;
          background-color: rgba($black, 0.2);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
        }

        &:hover,
        &:focus {
          color: $navy;
        }

        &.btn_sheet_active {
          color: $app_color;
          font-weight: 600;

          &:before {
            display: none;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }

  #footer_tabs {
    width: 100%;
    flex-grow: 1;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    position: relative;
    @include box-sizing(border-box);
    overflow: auto;
    @include box-sizing(border-box);
    padding: 0 0 5px;
  }

  #footer_controls {
    @include box-sizing(border-box);
    padding: 10px;

    &.hide_controls {
      display: none;
    }

    #pan_controls {
      width: 60px;

      button {
        float: left;
      }
    }
  }

  #footer_export {
    @include box-sizing(border-box);
    padding: 10px;
  }
}

#result_tab_container > ul {
  margin: 0px 0 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  font-size: 0;
  white-space: nowrap;

  > li {
    display: inline-block;
    font-size: 0;
    margin-right: 10px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      white-space: nowrap;
      display: inline-block;
      font-size: 0;

      > li {
        display: inline-block;
        font-size: 0;
        margin-right: 2px;
        position: relative;
        overflow: hidden;

        button {
          &.btn_sheet {
            min-width: 6rem;
            background-color: $white;
            background: linear-gradient(to bottom, rgb(246, 246, 246) 10%, white 50%, white);

            @include border-radius(0);
            @include border-bottom-radius(3px);
            padding: 10px 10px 7px 10px;
            position: relative;
            border-width: 0 0 3px 0;
            border-style: solid;
            color: rgba($app_color, 0.5);
            text-transform: inherit;
            font-size: 0.86rem;
            font-weight: 600;

            &:before {
              content: '';
              height: 3px;
              background-color: rgba($black, 0.05);
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
            }

            &:after {
              content: '';
              height: 1px;
              background-color: rgba($black, 0.2);
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
            }

            &:hover,
            &:focus {
              color: $navy;
            }

            &.btn_sheet_active {
              color: $app_color;
              @include box-shadow(0 2px 10px rgba($black, 0.1));

              &:before {
                display: none;
              }

              &:after {
                display: none;
              }
            }

            .btn_status {
              span {
                color: $notification;
              }
            }
          }

          &.btn_sheet_hide {
            position: absolute;
            right: -40px;
            top: 5px;
            z-index: 1;
            background-color: $white;
            overflow: hidden;
            margin-left: 5px;
            padding-left: 0;
            padding-right: 5px;

            .btn_icon {
              span {
                font-size: 10px;
                background-color: $lighter_grey;
                @include border-radius(30px);
                padding: 3px;
              }
            }

            // &:hover,
            // &:focus {
            // }

            &:before {
              content: '';
              position: absolute;
              left: -10px;
              top: 0;
              bottom: 0;
              width: 10px;
              background: rgb(255, 255, 255);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            }
          }
        }

        &:hover {
          button.btn_sheet_hide {
            right: 0;
          }
        }
      }
    }

    // ! Deprecated since task 3635
    // &:nth-child(0) {
    //   button.btn_sheet {
    //     border-color: $group_color_1;
    //   }
    // }

    // &:nth-child(1) {
    //   button.btn_sheet {
    //     border-color: $group_color_2;
    //   }
    // }

    // &:nth-child(2) {
    //   button.btn_sheet {
    //     border-color: $group_color_3;
    //   }
    // }

    // &:nth-child(3) {
    //   button.btn_sheet {
    //     border-color: $group_color_4;
    //   }
    // }
    // &:nth-child(4) {
    //   button.btn_sheet {
    //     border-color: $group_color_5;
    //   }
    // }
    // &:nth-child(5) {
    //   button.btn_sheet {
    //     border-color: $group_color_6;
    //   }
    // }
    // &:nth-child(6) {
    //   button.btn_sheet {
    //     border-color: $group_color_7;
    //   }
    // }
    // &:nth-child(7) {
    //   button.btn_sheet {
    //     border-color: $group_color_8;
    //   }
    // }
  }
}
