app-multi-notification {
  margin: auto !important;
  padding: 0.5rem;
  width: auto;
  max-width: 90%;
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(3, auto);
  grid-gap: 0.5rem;
}

.multi-notification-container {
  // width: 100%;
  // height: 100%;
  padding: 0.5rem;
  border: rgba($color: #000000, $alpha: 0.2);
  // background-color: rgba($color: #000000, $alpha: 0.05);
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 6px rgba(0, 0, 0, 0.3);

  display: grid;
  grid-template-columns: 1fr auto;

  .title {
    font-weight: 600;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 0.8rem;
    color: #525b6b;
  }
  .body {
    color: #525b6b;
    font-size: 0.86rem;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-weight: 400;
  }
  .actions {
    // display: flex;
    // justify-content: flex-end;
    // height: fit-content;
    margin-top: auto;
    button {
      margin-left: 0.5rem;
    }
  }
}

.component-header {
  overflow: hidden;
  transition: all 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: max-height 1s cubic-bezier(0.65, 0.05, 0.36, 1);
  height: auto;
  max-height: 600px;
}
.component-header.slide-up {
  max-height: 0;
  display: none;
}
