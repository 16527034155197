#assumption_container {
  position: relative;
  height: calc(100vh - 242px); // height: calc(100vh - 180px);
  // max-height: 780px; Remove this as it cut off the lookup table in assumptions..

  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-around;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;

  #assumption_lookup_list {
    @include transition(all 0.3s ease);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    padding: 15px;

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $border_color;
    }
  }

  #assumption_lookup_viewer {
    @include transition(all 0.3s ease);
    flex: 1;
    margin-left: 270px;
    display: block;

    #toggle_list_btn_container {
      position: absolute;
      left: -15px;
      top: 0;
      display: none;
      z-index: 1;

      margin-top: auto;
      margin-bottom: auto;

      button {
        background-color: $white;
      }
    }
  }
}
