#page_header {
  background-color: $navy;
  color: $white;

  .page_inner_container {
    padding: 10px;
    @include box-sizing(border-box);

    #header_left {
      float: left;
      #header_logo {
        float: left;
        line-height: 30px;
        margin-right: 20px;

        #header_logo_icon {
          float: left;

          a {
            display: block;
            color: $white;
            text-decoration: none;
            line-height: 30px;
            font-size: 30px;
          }
        }

        #header_logo_product {
          float: left;
          margin-left: 10px;

          span {
            display: block;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            color: $white;
          }
        }
      }

      #header_create_simulation {
        float: left;

        button {
          margin-right: 5px;
          float: left;
        }

        #save_status {
          float: left;
          margin-left: 10px;
          line-height: 20px;
          padding: 5px 0;
          @include opacity(0.5);
        }
      }
    }

    #header_right {
      float: right;

      #header_model_data {
        float: left;
        margin-right: 10px;
      }

      #header_model_structure {
        float: left;
        margin-right: 20px;
      }
      #header_settings {
        float: left;
        margin-right: 20px;
      }

      #header_quick_links {
        float: left;
        margin-right: 10px;
      }

      #header_user {
        float: left;
      }
    }
  }
}

.header33 {
  width: 50%;
}
