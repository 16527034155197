$icomoon-font-family: 'empyrean_icons' !default;
$icomoon-font-path: './fonts' !default;

$icon_transpose: '\e92b';
$icon_triangle_down: '\e928';
$icon_triangle_up: '\e929';
$icon_products: '\e92a';
$icon_asset_liability_management: '\e918';
$icon_assumptions: '\e925';
$icon_chevron_down: '\e900';
$icon_chevron_left: '\e901';
$icon_chevron_right: '\e902';
$icon_chevron_up: '\e903';
$icon_close: '\e908';
$icon_current_expected_credit_loss: '\e919';
$icon_data_set: '\e90a';
$icon_delete: '\e916';
$icon_edit: '\e926';
$icon_error: '\e91f';
$icon_funds_transfer_pricing: '\e91a';
$icon_grid: '\e904';
$icon_ifrs: '\e91b';
$icon_liquidity_modeling: '\e91c';
$icon_lock: '\e917';
$icon_logo_empyrean: '\e905';
$icon_msg_error: '\e920';
$icon_msg_success: '\e921';
$icon_notification: '\e911';
$icon_plus: '\e906';
$icon_quicklinks: '\e912';
$icon_result_set: '\e913';
$icon_sheet_loader: '\e90d';
$icon_spinner: '\e924';
$icon_stochastic_income_simulation: '\e91d';
$icon_stress_testing: '\e91e';
$icon_summary: '\e914';
$icon_toggle_drawer_open: '\e90e';
$icon_toggle_drawer: '\e90f';
$icon_trash: '\e90c';
$icon_triangle_left: '\e915';
$icon_triangle_right: '\e910';
$icon_undelete: '\e927';
$icon_upload: '\e909';
$icon_user: '\e907';
$icon_valid: '\e922';
$icon_warning: '\e923';
$icon_dropdown: '\e90b';
$icon_hierarchy: '\e92e';
$icon_navigation-more: '\e92f';
$icon_screen-full: '\e930';
$icon_window-restore: '\e92b';
$icon_window-maximize: '\e92c';
$icon_shrink: '\e929';
$icon_collapse: '\e929';
$icon_minimize: '\e929';
$icon_contract: '\e929';
$icon_enlarge: '\e92a';
$icon_expand: '\e92a';
$icon_maximize: '\e92a';
$icon_fullscreen: '\e92a';
$icon_info: '\e928';
