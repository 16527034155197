#page_header_alt {
	text-align: center;

	.page_inner_container {
		padding: 10px;
        @include box-sizing(border-box);

		#header_logo_center {
			margin: 40px auto 0;
			height: 100px;

			img {
				height: 100%;
			}
		}
	}
}
