#page_footer {
	background-color: $light_grey;
	color: $mid_grey;

	.page_inner_container {
		padding: 10px;
        @include box-sizing(border-box);

        &.no_padding {
        	padding: 0;
        }

		.footer_text {
			text-align: center;
			line-height: 20px;
			padding: 5px;
		}
	}
}