.product_suite_container {
	text-align: center;
	margin-top: 20px;
}
.product_suit_options {
	max-width: 720px;
	margin:0 auto;
}


ul#product_suite {
	width: 100%;
	float: left;
	margin:0;
	padding:0;
	list-style: none; 

	>li {
		float: left;
		width: 25%;
		text-align: center;
		padding: 10px;
		@include box-sizing(border-box);

		// This is for the launch Page
		.btn_product {
			display: block;
			text-decoration: none;
			@include border-radius(4px);
			text-align: center;
			padding: 20px;
			color: inherit;
			width: 160px;
			background-color: $white;
			@include box-sizing(border-box);
		    @include border-radius(3px);
		    box-shadow: 0 1px 4px rgba($black, 0.3);

			&:hover, &:focus {
		    	box-shadow: 0 1px 4px rgba($black, 0.3), 0 1px 20px rgba($black, 0.3);

				.application_icon {
					background-color: $blue;
					color: $white;
				}

				.application_name {
					color: $blue;
				}
			}

			&.btn_normal:disabled {
				background-color: $lighter_grey !important;
				@include opacity(1);
				box-shadow: none !important;

				&:hover,
				&:focus {
					background-color: $lighter_grey !important;
					@include opacity(1);
					box-shadow: none !important;

					.application_icon {
						background-color: rgba($black,0.05);
						color: inherit;
					}

					.application_name {
						color: inherit;
					}
				}
			}

			.application_icon {
				display: block;
				padding: 10px;
				@include border-radius(50px);
				background-color: rgba($black,0.05);
				width: 50px;
				@include box-sizing (border-box);
				margin: 0 auto 10px;

				span {
					line-height: 30px;
					width: 30px;
					text-align: center;
					font-size: 24px;
					display: block;
					vertical-align: top;
				}
			}

			.application_name {
				text-align: center;
				display: block;
				line-height: 15px;
				min-height: 30px;
				font-size: 0.8rem;
			}
		}
	}
}
