.dropzone {
  // min-height: 400px;
  padding-top: 5rem;
  @include box-sizing(border-box);
  padding: 4.5rem 1rem;
  position: relative;
  border: 3px dashed $border-color;

  &.dz-drag-hover {
    // border: 3px dashed $green;
  }

  &.dz-started {
    // border: 3px solid $green;
  }

  .dz-default {
    &.dz-message {
    }
  }

  .dz-message {
    text-align: center;

    .dz_msg_icon {
      // margin-top: 60px;
      color: $navy;
      span {
        vertical-align: text-bottom;
        // font-size: 100px;
      }
    }

    .dz_msg_instruction {
      color: $navy;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 140%;
      // margin-bottom: 30px;
    }

    .dz_msg_helper {
      line-height: 140%;
      margin-bottom: 10px;
    }

    .dz_msg_button {
      button {
      }
    }
  }

  .dz-preview {
    .dz-details {
      text-align: center;
      .dz-filename {
        display: inline-block;
      }

      .dz-size {
        display: inline-block;
      }

      img {
        display: none;
      }
    }

    .dz-progress {
      .dz-upload {
      }
    }

    .dz-success-mark {
      display: inline-block;
      text-align: center;
      display: none;
    }

    .dz-error-mark {
      display: inline-block;
      text-align: center;
      display: none;
    }

    .dz-error-message {
      color: $red;
      line-height: 140%;
      margin-top: 20px;
      text-align: center;
    }
  }
}
