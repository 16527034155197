#tab_nav {
  padding: 0px 0px 0;
  position: relative;
  // background-color: $blue;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1;
    position: relative;
    overflow: auto;
    white-space: nowrap;
    height: 40px;

    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      visibility: hidden;
    }

    > li {
      display: block;
      float: left;
      height: 100%;
    }
  }
}

button.btn_tab {
  @include border-radius(0);
  background-color: transparent;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.03));
  position: relative;
  color: $white;
  border-bottom: 0px solid $grey;
  // padding: 15px;
  // line-height: 20px;
  text-transform: inherit;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;

  &.btn_text {
    line-height: 0;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: rgba($black, 0.1);
  }

  &.btn_tab_active {
    background: transparent;
    background-color: $white !important;
    color: $navy;

    &:hover,
    &:focus {
      background: transparent;
      background-color: $white !important;
      color: $navy;
    }

    &:after {
      display: none;
    }
  }
}

.tab_content {
  display: none;
  padding: 0px;

  &.tab_content_active {
    display: block;
  }
}
