app-hover-notification {
  display: contents;
}

.hover-notification-container {
  background-color: white;
  color: $dark_grey;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 20px rgba(0, 0, 0, 0.3);
  display: grid;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 0.86rem;
  grid-template-rows: auto 1fr;
  grid-gap: 0.4rem;
  min-width: 100px;
  max-width: 300px;
  padding: 0.7rem;
  position: absolute;
  // right: -100px;

  top: 0.5rem;
  width: auto;
  z-index: 999;

  $accent: $notification;

  border-left: 6px solid;
  border-left-color: $notification; // default

  &.notificationright {
    right: 0.5rem;
  }
  &.notificationleft {
    left: 0.5rem;
  }
  &.info {
    border-left-color: $blue !important;
    $accent: $blue !important;
    .title,
    .value {
      color: $accent;
    }
  }
  &.success {
    border-left-color: $green !important;
    $accent: $green !important;
    .title,
    .value {
      color: $accent;
    }
  }
  &.error {
    border-left-color: $red !important;
    $accent: $red !important;
    .title,
    .value {
      color: $accent;
    }
  }
  &.warning {
    border-left-color: $notification !important;
    $accent: $notification !important;
    .title,
    .value {
      color: $accent;
    }
  }

  .header {
    display: grid;
    grid-template-columns: 1fr auto;

    .title,
    .value {
      font-weight: 600;
    }
    .value {
      margin-left: 1rem;
    }
  }

  .body {
    white-space: pre-wrap;
    font-weight: 400;
  }
}
