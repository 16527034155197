@use '@angular/material' as mat;
@import '~angular2-toaster/toaster.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$UserManager-primary: mat.define-palette(mat.$indigo-palette);
$UserManager-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$UserManager-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$UserManager-theme: mat.define-light-theme($UserManager-primary, $UserManager-accent, $UserManager-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($UserManager-theme);

/****************************
*     GLOBAL STYLES         *
* for all the application   *
****************************/

@import 'app/styles/base/all';
@import 'app/styles/base';
@import 'app/styles/icons/style';
@import 'app/styles/icons/fonts/source-sans-pro.css';

// Webkitt scroll-bars
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: #2b495ea8;
  background-clip: padding-box;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 1px #edf1f6;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #edf1f6;
}
