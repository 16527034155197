#page_container_outer {
  height: 100vh;
  overflow: hidden;
}

.page_inner_container {
  // max-width: 1280px; // This controls the max-width for the page
  // margin:0 auto;
  width: 100%;
  display: block;
  overflow: hidden;
}

#page_container {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  min-height: 0;

  #page_header {
    position: relative;
    // min-height:0;
    @include box-sizing(border-box);
  }

  #page_content {
    width: 100%;
    flex-grow: 1;
    flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    position: relative;
    height: 100%;
    display: flex;
    @include box-sizing(border-box);
    overflow: auto;
    // min-height:0;

    &:after {
      content: $empyrean_logo;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: rgba($navy, 0.1);
      font-family: 'empyrean_icons';
      font-size: 200px;
      z-index: -1;
    }

    &.no_logo {
      &:after {
        display: none;
      }
    }

    #page_content_inner {
      // padding: 10px;
      padding: 0px;

      .input-structure {
        height: calc(100vh - 153px);
        position: relative;

        display: flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        align-items: stretch;
        -webkit-align-items: stretch;
        width: 100%;
        min-height: 0;

        #button-strip {
        }

        #results {
          width: 100%;
          flex-grow: 1;
          flex: 1 1 auto;
          -webkit-flex: 1 1 auto;
          position: relative;
        }
      }

      #page_body {
      }
    }
  }

  #page_footer {
    @include box-sizing(border-box);
    position: relative;
    // min-height:0;
  }
}
