a.text_link {
  cursor: pointer;
}

input,
button,
textarea {
  font-size: 1rem;
  font-family: $font_pack !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn_normal {
  width: auto;
  background-color: transparent;
  position: relative;
  color: $app_color;
  line-height: 20px;
  margin: 0px;
  padding: 5px 10px;
  overflow: visible;
  @include border-radius(3px);
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  border: 0px solid;
  cursor: pointer;
  @include transition(all 0.3s ease);
  min-width: 20px;
  @include box-sizing(border-box);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.76rem;

  &:hover,
  &:focus {
    color: $blue;
  }

  &:active {
  }

  &:disabled {
    color: $grey !important;
    cursor: default !important;
    background-color: rgba($lighter_grey, 0.2) !important;
    border: 0px solid !important;
    padding: 5px 10px;

    &:hover,
    &:focus {
      border-color: $border-color !important;
      background-color: rgba($lighter_grey, 0.2) !important;
      color: $grey !important;
    }
  }

  &.btn_green {
    background-color: $btn_primary_bg;
    color: $btn_primary_color;

    &:focus,
    &:hover {
      background-color: $btn_primary_bg_hover;
      color: $btn_primary_color_hover;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;

      &:hover,
      &:focus {
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_blue {
    background-color: $btn_secondary_bg;
    color: $btn_secondary_color;

    &:focus,
    &:hover {
      background-color: $btn_secondary_bg_hover;
      color: $btn_secondary_color_hover;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;

      &:hover,
      &:focus {
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_white {
    background-color: transparent;
    color: $white;

    &:focus,
    &:hover {
      background-color: rgba($white, 0.1);
      color: $white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;

      &:hover,
      &:focus {
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_notification {
    background-color: $notification;
    color: $white;

    &:focus,
    &:hover {
      background-color: darken($notification, 10%);
      color: $white;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;

      &:hover,
      &:focus {
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_outline_white {
    background-color: transparent;
    color: $white;
    padding: 4px 9px;
    border: 1px solid $white;
    @include transition(all 0s ease);

    &:focus,
    &:hover {
      background-color: $white;
      color: $app_color;
      border: 0px solid $white;
      padding: 5px 10px;
    }

    &.btn_no_border {
      padding: 5px 10px;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;
      border: 0px solid !important;
      padding: 5px 10px;

      &:hover,
      &:focus {
        border-color: $border-color !important;
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_negative {
    background-color: transparent;
    color: $navy;
    padding: 4px 9px;
    border: 1px solid $navy;
    @include transition(all 0s ease);

    &:focus,
    &:hover {
      background-color: $navy;
      color: $white;
      border: 0px solid $navy;
      padding: 5px 10px;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;
      border: 0px solid !important;
      padding: 5px 10px;

      &:hover,
      &:focus {
        border-color: $border-color !important;
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }

    &.no_focus {
      &:focus {
        background-color: transparent;
        color: $navy;
        padding: 4px 9px;
        border: 1px solid $navy;
      }
    }
  }

  &.btn_green_outline {
    background-color: transparent;
    color: $green;
    padding: 4px 9px;
    border: 1px solid $green;
    @include transition(all 0s ease);

    &:focus,
    &:hover {
      background-color: $green;
      color: $white;
      border: 0px solid $green;
      padding: 5px 10px;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0.2) !important;
      border: 0px solid !important;
      padding: 5px 10px;

      &:hover,
      &:focus {
        border-color: $border-color !important;
        background-color: rgba($lighter_grey, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_add_item {
    background-color: transparent;
    color: $navy;
    padding: 5px 0px;
    border: 0px solid $navy;
    @include transition(all 0s ease);

    &:focus,
    &:hover {
      background-color: transparent;
      color: $green;
      border: 0px solid $green;
      padding: 5px 0px;
    }

    &:disabled {
      color: $grey !important;
      cursor: default !important;
      background-color: rgba($lighter_grey, 0) !important;
      border: 0px solid !important;
      padding: 5px 0px;

      &:hover,
      &:focus {
        border-color: $border-color !important;
        background-color: rgba($lighter_grey, 0) !important;
        color: $grey !important;
      }
    }
  }

  &.btn_icon_only {
    padding: 4px 4px;

    &.btn_no_border {
      padding: 5px 5px;
    }

    &.btn_icon_small {
      .btn_icon,
      .btn_status {
        span {
          font-size: 12px;
        }
      }
    }

    .btn_icon,
    .btn_status {
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      width: 20px;
      text-align: center;
      display: block;
      vertical-align: text-bottom; //text-bottom;

      span {
        display: block;
        line-height: 20px;
        width: 20px;
        font-size: 16px;
      }
    }
  }

  &.btn_icon_small {
    padding: 4px 4px;

    &.btn_no_border {
      padding: 5px 5px;
    }

    .btn_icon,
    .btn_status {
      font-size: 12px;
      height: 20px;
      width: 20px;
      text-align: center;
      display: block;
    }
  }

  &.btn_wide {
    min-width: 100px;
  }

  &.btn_select {
    min-width: 100px;
    border: 1px solid $border_color;
    padding: 4px 9px;
  }

  &.btn_form_input {
    border: 1px solid $input_border;
    @include border-radius(0px);
    @include border-right-radius(4px);
    padding: 4px;
    background-color: $white;

    &:hover,
    &:focus {
      color: $primary_color;
      border-color: $primary_color;
    }
  }

  &.btn_no_border {
    border: 0 solid !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.btn_no_padding {
    padding: 4px 0px;

    &.btn_no_border {
      padding: 5px 0;
    }
  }

  &.btn_extra_padding {
    padding: 4px 9px;

    &.btn_no_border {
      padding: 5px 10px;
    }
  }

  &.btn_offset_margin {
    margin: -5px 0; // This is useful for tables
  }

  &.btn_min_width {
    display: inline-block;
    width: 80px;
  }

  &.btn_table {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  &.btn_hidden {
    display: none;
  }

  > .btn_text {
    line-height: 20px;
    display: inline-block;

    &.hidden_text {
      position: absolute;
      font-size: 0 !important;
    }
  }

  > .btn_icon,
  > .btn_status {
    position: relative;
    pointer-events: none;
    display: inline-block;
    vertical-align: text-bottom;
    color: inherit;
    line-height: 20px;

    span {
      font-size: 16px;
      display: block;
    }

    &.big_icon {
      span {
        font-size: 20px;
      }
    }
  }

  > .btn_status {
    &.status_invalid {
      color: $red;
    }
  }

  > .btn_helper {
    float: right;
    margin-left: 5px;
    line-height: 20px;

    span {
      font-size: 10px;
      display: block;
      line-height: 20px;
    }
  }

  > .btn_icon_arrow {
    float: right;
    margin-left: 5px;
    line-height: 20px;

    span {
      font-size: 16px;
      display: block;
      line-height: 20px;
    }
  }
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.btn_group {
  .btn_group_left {
    float: left;
    padding: 4px;
    border-right: 0px solid !important;
    @include border-radius(4px 0 0 4px);

    &.btn_no_border {
      padding: 5px;
    }

    &.btn_grey {
      padding: 4px;
      border: 1px solid $light_grey !important;

      &:focus,
      &:hover {
        border: 1px solid $dark_grey !important;
      }

      &:disabled {
        color: $grey !important;
        cursor: default !important;
        background-color: $lighter_grey !important;
        border-color: $lighter_grey !important;

        &:hover,
        &:focus {
          border-color: $lighter_grey !important;
          background-color: $lighter_grey !important;
          color: $grey !important;
        }
      }
    }
  }

  .btn_group_right {
    float: left;
    padding: 4px;
    @include border-radius(0 4px 4px 0);

    &.btn_no_border {
      padding: 5px;
    }

    &.btn_grey {
      padding: 4px;
      border: 1px solid $light_grey !important;

      &:focus,
      &:hover {
        border: 1px solid $dark_grey !important;
      }

      &:disabled {
        color: $grey !important;
        cursor: default !important;
        background-color: $lighter_grey !important;
        border-color: $lighter_grey !important;

        &:hover,
        &:focus {
          border-color: $lighter_grey !important;
          background-color: $lighter_grey !important;
          color: $grey !important;
        }
      }
    }
  }
}

.btn_small {
  @extend .btn_normal;
  font-size: 10px;
  padding: 0 !important;
}
