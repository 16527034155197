html {
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  text-rendering: optimizelegibility;
  // overflow: hidden; // Remove Scroll bounce
}

body {
  font-size: $font_size;
  font-family: $font_pack;
  font-weight: 400;
  color: $app_color;
  background: $body_background;
  -webkit-text-size-adjust: none;
  margin: 0px;
  padding: 0;

  &.no_scroll {
    height: 100%;
    overflow: hidden;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  padding: 0px;
}

abbr {
  text-decoration: none;
}

a:link,
a:visited {
  color: $navy;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  color: $primary_color;
}

p {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  line-height: 140%;
}

*:focus {
  outline: none;
}

.clearfix::after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}

.overflow {
  -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
  overflow: auto;
}

.hidden_text {
  position: absolute !important;
  font-size: 0 !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.space_divider {
  height: 10px;
  display: block;

  &.extra_padding {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.line_divider {
  background-color: rgba($black, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  display: block;

  &.extra_padding {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Outcome Modal Styling
.outcome_icon {
  font-size: 80px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  margin: 20px auto 20px;

  &.success {
    color: $green;
  }

  &.error {
    color: $red;
  }
}

.outcome_title {
  font-size: 1.4rem;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;

  &.success {
    color: $green;
  }

  &.error {
    color: $red;
  }
}

.outcome_text {
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid $black;
  border-color: $black transparent $black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Styling for Spread Canvas
#vp {
  border-top: 0px solid $border_color;
}

.chevron_toggle_icon {
  @include chevron-down();
  font-size: 2em;
  font-style: normal;
  cursor: pointer;

  &.expanded:before {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    @include rotate(180);
  }
}

.icon_spinner {
  display: inline-block;
  margin-right: 5px;
  -webkit-animation: rotate-center 0.6s ease-in infinite both;
  animation: rotate-center 0.6s ease-in infinite both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
