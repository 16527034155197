/*

  Spreadsheets

*/

.spread-container {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: $spread-sheet-height;
  height: 100%;
  overflow: hidden;
  z-index: 8;

  .spreadsheet {
    z-index: 9;
    position: relative;
    height: 100%;
    width: 100%;
  }

  // Custom spreadsheet loading placeholder
  .spreadsheet--loading {
    z-index: 10;
    background-color: $lighter_grey;
    background-image: linear-gradient(to bottom, #f5f6f7 calc(100% - 40px), white);
    position: relative;
    min-height: $spread-sheet-height;

    &:before {
      content: $sheet_loader;
      font-family: 'empyrean_icons';
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 80px;
      color: $grey;
      transform: translate(-50%, -50%);
      font-weight: 300;
    }

    &:after {
      content: 'Loading Sheet...';
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1rem;
      color: $grey;
      transform: translate(-50%, -50%);
      margin-top: 50px;
      text-align: center;
      font-weight: 300;
    }
  }

  // No data spreadsheet placeholder
  .spreadsheet--empty {
    @extend .spreadsheet--loading;

    // Generic message
    &:after {
      content: 'No Data Available.';
    }
    // Different messages depending of the page
    &.empty_results:after {
      content: 'No Results Available.';
    }
    &.empty_period_equations:after {
      content: 'No Period Equations Available.';
    }
  }
}

.sheet-status {
  .error {
    line-height: 20px;
    padding: 5px 0;
    color: $red;

    &:after {
      margin-left: 5px;
      content: $error;
      font-family: 'empyrean_icons';
      vertical-align: top;
    }
  }

  .valid {
    line-height: 20px;
    padding: 5px 0;

    &:after {
      color: $red;
      margin-left: 5px;
      content: $error;
      font-family: 'empyrean_icons';
      vertical-align: top;
    }
  }
}
