#content {
}

#page_content_main {
}

.dashboard_grid_item {
  margin-bottom: 40px;
  position: relative;
  background-color: $white;

  .dashboard_grid_header {
    .header_row {
      .cell_row {
        color: $app_color;
        // border-bottom: 1px solid $app_color;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;

        &:hover {
          background-color: transparent;
        }

        .user_data {
          .cell_header {
            padding: 10px 5px;
          }
        }
      }
    }
  }

  .dashboard_grid_content {
    .user_row {
      .cell_row {
        .user_data {
          .user_name {
          }
        }
      }
      &:last-of-type {
        .cell_row {
          border-bottom: 1px solid $border_color;
        }
      }
    }
  }

  .dashboard_grid_total {
    .footer_row {
      .cell_row {
        border-bottom: 0px solid $dark_grey;
        background-color: $primary_color_005;

        .user_data {
          .user_name {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.cell_row {
  border-bottom: 1px solid $border_color;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  width: 100%;
  min-height: 0;

  .overflow-text {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .overflow {
    position: relative;
  }

  .overflow:hover {
    overflow: visible;
    background-color: $primary_color_005;
    z-index: 1;
  }

  .overflow:hover div {
    position: absolute;
    background-color: $primary_color_005;
    z-index: 1;
    padding-right: 20px;
  }

  &:hover {
    background-color: $primary_color_005; //rgba($primary_color, 0.05);

    .user_data {
      .user_name {
        a {
          color: $primary_color;
          text-decoration: underline;

          &.toggle_row_details {
            display: inline-block;
            text-decoration: underline;
            position: relative;
            padding-left: 0.6rem;
            margin-left: 0.3rem;

            &:before {
              content: ' - ';
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .user_data {
    line-height: 20px;

    @for $i from 1 through 16 {
      &.cell_width_#{$i} {
        flex: 0 0 calc(6.25% * #{$i});
      }
    }
    .cell_header {
      padding: 5px 5px;
      font-weight: 600;

      h3 {
        line-height: 20px;
        font-size: 1rem;
        text-transform: initial;
        font-weight: 400;
      }
    }

    .user_name {
      padding: 5px 5px;

      a {
        color: $primary_color;
        text-decoration: underline;

        &.toggle_row_details {
          text-decoration: underline;
          display: none;

          &:before {
            content: ' - ';
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .value {
      padding: 5px 5px;

      &.negative {
        color: $red;
      }
    }
  }
}
