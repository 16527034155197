$col_width_2: calc((100% / 2) - 20px);
$col_width_3: calc((100% / 3) - 20px);

$base_max_width: 1279px;

.grid_container {
	display: flex;
	justify-content: space-around;
	align-items: stretch;
	flex-flow: wrap;
	flex-direction: row;
	height: 100%;
	max-width: 1440px; // Comment this out to force the page to go full width
	margin:0px auto;
	min-height:0;
	-webkit-justify-content: space-around;
	-webkit-align-items: stretch;
	-webkit-flex-flow: wrap;
	-webkit-flex-direction: row;

	&.auto_height {
		height: auto;
	}

	&.fixed_height {
		height: 100%;
		display: flex;

		@media (max-width: $base_max_width) {
			display: flex;
			height: 100%;
		}
	}

	@media (max-width: $base_max_width) {
    	display: block;
    	height: auto;
    }


  	&.columns_3 {
  		.col {
  			width: $col_width_3;

  			@media (max-width: $base_max_width) {
	    		width: calc(100% - 20px);
	    	}
  		}
	}

	&.columns_2 {
  		.col {
  			width: $col_width_2;

  			@media (max-width: $base_max_width) {
	    		width: calc(100% - 20px);
	    	}
  		}
	}

	&.columns_1 {
  		.col {
  			width: calc(100% - 20px);

  			@media (max-width: $base_max_width) {
	    		width: calc(100% - 20px);
	    	}
  		}
	}

	.col {
		@include box-sizing(border-box);
		margin: 10px auto;
		height: 100%;
		
		@media (max-width: $base_max_width) {
    		width: inherit;
    		margin: 10px auto;
    		// margin-bottom: 5px;
		}
	}

}




