@import './../variables';
@import './../common/animations';

/*

  Modal Dialog Layout

*/
mat-dialog-container,
.mat-dialog-container {
  padding: 0 !important;
}

.dialog-model {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  // height: 680px;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  // height: calc(100vh - 30rem);
  width: 100%;
  // width: 90vw !important;
  // height: 90vh !important;
  // min-height:200px !important;
  // max-height: 800px !important;
  // min-width: 600px !important;
  // max-width: 1440px !important;
  padding: 0 !important;
  max-width: 100vw;
  border-radius: 5px;

  // For all "-left" elements
  *[class$='-left'],
  *[class$='-center'],
  *[class$='-right'] {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    align-items: stretch;
  }

  *[class$='-left'] {
    justify-content: flex-start;
  }
  // For all "-center" elements
  *[class$='-center'] {
    justify-content: center;
  }
  // For all "-right" elements
  *[class$='-right'] {
    justify-content: flex-end;
  }

  .scrollable {
    &-y {
      overflow: hidden;
      overflow-y: auto;
    }
    &-x {
      overflow: hidden;
      overflow-x: auto;
    }
    &:not(-y):not(-x) {
      overflow: auto;
    }
  }

  .panel-left,
  .panel-right {
    position: relative;
    // width: 20%;

    // .panel-left-body,
    // .panel-right-body {
    //   max-height: calc($spread-sheet-height);
    // }

    // Dragbar UI
    &.with_dragbar:before {
      content: '';
      position: absolute;
      left: -5px;
      top: 50%;
      width: 10px;
      height: 30px;
      margin-top: -15px;
      border: 1px solid $border_color;
      @include border-radius(5px);
      background-color: $white;
      @include box-sizing(border-box);
    }

    .dragbar {
      // His parent should have position: relative
      background-color: black;
      height: 100%;
      position: absolute;
      top: 0;
      left: -2px;
      width: 5px;
      cursor: col-resize;
      @include opacity(0);

      &:hover,
      &:focus {
        @include opacity(0.5);
      }
    }
    // .panel-left-body,
    // .panel-right-body {
    //   height: 100%;
    //   overflow: hidden auto;
    // }
  }

  .panel-right {
    border-left: 1px solid #dfe0e1;
  }

  .panel-left {
    border-right: 1px solid #dfe0e1;
  }

  .panel-left-actions,
  .panel-right-actions {
    padding: 15px;
    border-bottom: 1px solid #dfe0e1;
  }
}

// Level 1 Structure
// Modal Header
.modal-header {
  background-color: $blue;
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 40px;
}
// Modal Body
.modal-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  // overflow-y: auto;
  position: relative;

  .component-header,
  .component-body,
  .panel-main-header,
  .panel-left,
  .panel-main,
  .panel-right,
  .panel-main-header-left,
  .panel-main-header-center,
  .panel-main-header-right,
  .modal-header-left,
  .modal-header-center,
  .modal-header-right,
  .modal-footer-left,
  .modal-footer-center,
  .modal-footer-right,
  .modal-header-left,
  .modal-header-center,
  .modal-header-right {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  // .panel-main-header-left,
  // .panel-main-header-center,
  // .panel-main-header-right,
  // .modal-footer-left,
  // .modal-footer-center,
  // .modal-footer-right {
  //   flex: auto;
  // }
  // Component Header
  .component-header,
  .panel-main-header {
    position: relative;
    min-height: fit-content;
  }

  // .modal-header-left,
  // .panel-main-header-left,
  // .modal-footer-left {
  // }
  .panel-main-header {
    &.min-height {
      min-height: 48px;
    }
  }

  .panel-main-header-center,
  .modal-header-center,
  .modal-footer-center {
    margin: 0 auto;
    align-items: center;
  }

  .modal-header-right,
  .panel-main-header-right,
  .modal-footer-right {
    margin: 0 0 0 auto;
    align-items: flex-end;
  }

  .panel-left,
  .modal-header-left,
  .panel-main-header-left,
  .modal-footer-left {
    order: 0;
  }

  .panel-main,
  .modal-header-center,
  .panel-main-header-center,
  .modal-footer-center {
    order: 1;
  }

  .panel-right,
  .modal-header-right,
  .panel-main-header-right,
  .modal-footer-right {
    order: 2;
  }

  .panel-main-header-left,
  .panel-main-header-center,
  .panel-main-header-right {
    padding: 0.5rem;
    &:empty {
      padding: 0;
    }
  }

  .panel-left-actions,
  .panel-right-actions {
    &:empty {
      padding: 0;
    }
  }

  // Component Header
  .component-header {
    position: relative;
    height: auto;
    width: 100%;
  }

  // Component Body
  .component-body {
    position: relative;
    // height: 60vh;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    align-items: stretch;
    -webkit-align-items: stretch;
    height: 100%;
    width: 100%;
    min-height: 0;
    flex-flow: nowrap;
    -webkit-flex-flow: nowrap;
    margin: 0px;

    // Panel Side
    .panel-left,
    .panel-right {
      @extend .full-size;
      display: flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: flex-start;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      align-items: stretch;
      -webkit-align-items: stretch;
      min-height: 0;
      flex-grow: 1;
    }
    // Panel Main
    .panel-main {
      position: relative;
      flex-grow: 1;
      // overflow: auto;
      display: flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      // justify-content: flex-start;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      align-items: stretch;
      -webkit-align-items: stretch;
      height: 100%;
      width: 100%;
      min-height: 0;
    }

    .panel-main-body,
    .panel-left-body,
    .panel-right-body {
      flex-grow: 1;
      // height: calc(68vh);
      // overflow: hidden auto;
    }

    .panel-left,
    .panel-left-header,
    .panel-left-body,
    .panel-left-footer,
    .panel-right,
    .panel-right-header,
    .panel-right-body,
    .panel-right-footer {
      &:empty {
        // display: none;
        width: 0;
        height: 0;
      }
    }

    .panel-left-body,
    .panel-right-body {
      margin: 0;
      padding: 1rem;
    }

    .panel-main-body {
      margin: 0;
      padding: 0;
      height: 100%;
      display: flex;
    }
  }
}
// Modal Footer
.modal-footer {
  position: relative;
  padding: 15px;
  border-top: 1px solid #dfe0e1;
  display: flex;
  flex-direction: row;

  .modal-footer-right {
    margin: 0 0 0 auto;

    .btn_normal {
      margin: 0 0 0 1rem;

      // .btn_text {
      //   min-height: 1.5rem;
      // }

      &:first-of-type {
        margin: 0;
      }
    }
  }
}
// Dialog Content
.content_container {
  padding: 0px;
  @include box-sizing(border-box);

  &.padding--all {
    padding: 15px;
  }

  &.padding--left {
    padding-left: 15px;
  }

  &.padding--right {
    padding-right: 15px;
  }

  &.padding--top {
    padding-top: 15px;
  }

  &.padding--bottom {
    padding-bottom: 15px;
  }

  &.padding--vertical {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.padding--horizontal {
    padding-left: 15px;
    padding-right: 15px;
  }
}
// Modal Structure
.structure-col {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: stretch;
  -webkit-align-items: stretch;
  height: 100%;
  width: 100%;
  min-height: 0;

  .structure-col--left {
    flex-grow: 1;
    text-align: left;
    font-size: 0;

    * {
      font-size: $font_size;
    }

    button,
    input[type='button'] {
      display: inline-block;
      margin-right: 5px;

      .btn_text {
        font-size: $btn_font_size;
        display: block;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .structure-col--middle {
    flex-grow: 1;
    text-align: center;
    font-size: 0;

    * {
      font-size: $font_size;
    }

    button,
    input[type='button'] {
      display: inline-block;
      margin-right: 5px;

      .btn_text {
        font-size: $btn_font_size;
        display: block;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .structure-col--right {
    flex-grow: 1;
    text-align: right;
    font-size: 0;

    * {
      font-size: $font_size;
    }

    button,
    input[type='button'] {
      display: inline-block;
      font-size: 0;
      margin-left: 5px;

      .btn_text {
        font-size: $btn_font_size;
        display: block;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

// NOT NEEDED FOR PRODUCTION
.show_labels {
  .page-container,
  .modal-header,
  .modal-body,
  .modal-footer,
  .component-header,
  .component-body,
  .panel-left,
  .panel-left-header,
  .panel-left-actions,
  .panel-left-body,
  .panel-right,
  .panel-right-header,
  .panel-right-actions,
  .panel-right-body,
  .panel-main-header-left,
  .panel-main-header-center,
  .panel-main-header-right,
  .modal-header-left,
  .modal-header-center,
  .modal-header-right,
  .modal-footer-left,
  .modal-footer-center,
  .modal-footer-right,
  .panel-main,
  .panel-main-header,
  .panel-main-actions,
  .panel-main-body {
    border-width: 2px;
    border-style: solid;
    position: relative;
    padding: 20px;
    @include box-sizing(border-box);

    &:before {
      content: attr(id);
      background-color: attr(border-color);
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 5px;
      font-size: 12px;
      line-height: 20px;
      display: block;
    }

    &:hover {
      &:before {
      }
    }
  }
}

.show_labels {
  > div {
    border-color: red;

    &:before {
      color: white;
      background-color: red;
    }

    > div {
      border-color: blue;

      &:before {
        color: white;
        background-color: blue;
      }

      > div {
        border-color: #ff00fb;

        &:before {
          color: white;
          background-color: #ff00fb;
        }

        > div {
          border-color: #7cbd22;

          &:before {
            color: white;
            background-color: #7cbd22;
          }
        }
      }
    }
  }
}

// Scrolls (needs a bit of work)
.modal-body {
  overflow: hidden;
  height: 100%;
}

// To-Do: Deprecated. We are using it in the html and should be removed
.dialog_actions {
  @extend .modal-footer;
}

/* Lookup tables tree & Period Equations */
.tree {
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: auto;

  button {
    // Overwrite all button properties
    // @include overwriteButtonProperties();
    cursor: pointer;
    display: inline-block;
  }

  .drawer_container .drawer_header {
    padding: 0;
  }

  .tree-node-parent .drawer_container {
    padding-top: 0.5rem;
  }

  .drawer_content {
    display: block;
  }
}

.view_all_tables.node_all .tree_link {
  border: 0;
}
// *********************************
.drawer_container {
  .drawer_header {
    position: relative;
    padding-left: 30px;

    .drawer_header_toggle {
      position: absolute;
      left: 0;
      top: 0;
    }

    .drawer_header_title {
      .drawer_content {
        margin-left: 30px;

        .tree_link {
          width: 100%;
          display: block;
          // @include box-sizing(border-box);
          box-sizing: border-box;
        }
      }

      a {
        font-weight: 600;
      }
    }
  }
  /* Drawer Content (Hidden by Default) */
  .drawer_content {
    display: none;
    position: relative;

    &.drawer_active {
      display: block;
    }
  }
}

.periodequations-tree,
.lookup-tree {
  .star {
    @extend .fadein;
    color: darkorange;
    filter: blur(0.5px);
    // font-size: 1.5em;
    transition: all 200ms;
    margin-left: 0.382em;
    text-decoration: none;
    line-height: 0.5rem;
  }

  .has_changes {
    @extend .fadein;
    color: darkorange;
    filter: blur(0.5px);
    transition: all 200ms;
    margin-left: 0.382em;
    text-decoration: none;
    line-height: 0.5rem;
  }

  // .tree-node-parent .tree_link {
  //   text-decoration: none;
  //   font-weight: bold;
  //   cursor: inherit;
  // }
  // .tree-node-child .tree_link ::before {
  //   display: none;
  // }

  .tree-node-parent .drawer_content {
    display: block;
  }

  .tree_link {
    text-decoration: none !important;

    &:before {
      display: none;
    }

    &.category {
      text-decoration: none;
      font-weight: bold;
      color: $primary_color;
      padding-left: 0;
    }

    // &:not(.category) {}
  }
}
