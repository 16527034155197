#context_bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: nowrap;
  flex-direction: row;
  // height: 100%;
  // max-width: 1440px; // Comment this out to force the page to go full width
  margin: 0px auto;
  min-height: 0;
  -webkit-justify-content: space-between;
  -webkit-align-items: stretch;
  -webkit-flex-flow: nowrap;
  -webkit-flex-direction: row;
  background-color: $lighter_grey;
  // Left Context Bar
  #context_bar_left {
    flex: 1;
    padding: 10px;
    @include box-sizing(border-box);

    #context_source {
      float: left;
      line-height: 30px;
      padding-right: 15px;
      margin-right: 5px;
      position: relative;

      &:after {
        content: $triangle_right;
        font-family: 'empyrean_icons';
        line-height: 30px;
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .source_file {
        span {
          display: block;
          vertical-align: middle;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    #context_title {
      float: left;
      padding-right: 10px;
    }

    #context_edit_assumptions {
      float: left;
      padding-right: 10px;
      cursor: pointer;

      .edit_asumptions_link {
        display: block;
        line-height: 20px;
        padding: 5px 0px 5px 0;
      }
    }
  }
  // Middle Context Bar
  #context_bar_middle {
    // flex:1;
    padding: 10px;
    @include box-sizing(border-box);
    text-align: center;

    #view_controller {
      display: inline-block;
      width: 160px;

      .toggle_switch_option {
        width: 80px;
      }
    }

    #access_controller {
      display: inline-block;
      color: dark_grey;
      line-height: 20px;
      padding: 5px 0;
      margin-left: 10px;

      .icon_lock {
      }

      .access_text {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  // Right Context Bar
  #context_bar_right {
    text-align: right;
    flex: 1;
    padding: 10px;
    @include box-sizing(border-box);

    #sheet_status {
      display: inline-block;
      @include opacity(0.5);
    }

    #notifications_container {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
