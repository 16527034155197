/* Button Group */

	ul.button_group {
		margin:0;
		padding: 0;
		list-style:none;
		// float: left;
		// width:100%;

		>li {
			float: left;
			margin:0 !important;
			// width: 50%;

			&:first-child {
				button {
					@include border-left-radius (4px);
					display: block;
					margin-left: 0;
				}
			}

			&:last-child {
				button {
					@include border-right-radius (4px);
					display: block;
					// border-right: 1px solid $base_color;
				}
			}
		}
	}

	button.btn_group {
		width:auto;
		background-color:transparent;
		position: relative;
	  	color: $app_color;
		line-height:20px;
		margin:0px;
		padding:4px 10px;
		overflow:visible;
		@include border-radius(0px);
		-webkit-appearance:none;
		box-sizing:border-box;
		display: inline-block;
		border:1px solid $base_color;
		// border-right:0px solid;
		cursor:pointer;
		min-width:20px;
		@include box-sizing(border-box);
		position: relative;
		margin-left: -1px;
		min-width: 60px; 

		&:hover,
		&:focus {
			border-color:$primary_color;
			color: $primary_color;
			z-index: 1;
		}


		&.btn_selected {
			border-color: $base_color;
			background-color: $base_color;
			color: $white;

			&:hover,
			&:focus {
				border-color: $base_color;
				background-color: $base_color;
				color: $white;
			}				
		}
	}