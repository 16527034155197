.panel_title {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 20px;
	padding: 5px 0;

	&.inline {
		display: inline-block;			
	}
}

.login_title {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 20px;
	padding: 5px 0;
}

.context_title {
	font-size: 1.2rem; // 1.4rem;
	font-weight: 400;
	line-height: 20px;
	padding: 5px 0;
  color: $navy;
  text-transform: capitalize;
}

.dropdown_title {
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 20px;
	padding: 5px 0;
	margin-bottom: 10px;
}


.notification_title {
	font-weight: 600;
	text-transform: uppercase;
	line-height: 20px;
	font-size: 0.8rem;
}