.col_group {
	&:after {
	    clear: both;
	    content: '';
	    display: block;
	    height: 0;
	    visibility: hidden;
	}

	.col_item {
		float: left;
		@include box-sizing(border-box);
		padding: 5px;
		width: 100%;
		position: relative;

		&.no_padding {
			padding: 0;
		}

		.panel_container {
		}
	}

	&.split_4 {

		.col_item {
			width: 25%;

			@media #{$tablet} {
				width: 50%;
			}

			@media #{$mobile} {
				width: 100%;
			}
		}

		&.max_height {

			.col_item {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;

				.panel_container {
					height: 100%;
				}

				&:nth-child(2) {
					left: calc(100% / 4);
				}

				&:nth-child(3) {
					left: calc((100% / 4)*2);
				}

				&:nth-child(4) {
					left: calc((100% / 4)*3);
				}
			}
		}
	}

	&.split_3 {

		.col_item {
			width: calc(100% / 3);

			@media #{$tablet_landscape} {
				width: calc(100% / 3);
			}

			@media #{$mobile, $tablet_portrait} {
				width: 100%;
			}
		}

		&.max_height {

			.col_item {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;

				.panel_container {
					height: 100%;
				}

				&:nth-child(2) {
					left: calc(100% / 3);
				}

				&:nth-child(3) {
					left: calc((100% / 3)*2);
				}
			}
		}
	}

	&.split_2 {

		.col_item {
			width: 50%;

			@media #{$tablet_landscape} {
				width: 50%;
			}

			@media #{$mobile, $tablet_portrait} {
				width: 100%;
			}

			&.extra_space {
				padding-right: 15px;

				&:last-of-type {
					padding-right: 0;
					padding-left: 15px;
				}
			}
		}

		&.max_height {

			.col_item {
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;

				.panel_container {
					height: 100%;
				}

				&:nth-child(2) {
					left: calc(100% / 2);
				}
			}
		}
	}


	&.max_height {
		position: fixed;
		left: 65px;
		top: 65px;
		bottom:5px;
		right: 5px;

		.col_item {
		}
	}
}


// IE 11 Overrides


	@media all and (-ms-high-contrast:none) {
     	.column_content.column_content_inner.column_section {
     		display: flex; // IE11 Fix
   			flex-direction: column; // IE11 Fix
   			min-height: 1px;
     	} /* IE10 */
     	*::-ms-backdrop, 
     	.column_content.column_content_inner.column_section { 
     		display: flex; flex-direction: column; min-height: 1px;
     	} /* IE11 */

    }
