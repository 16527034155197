#data_set_container {
  position: relative;
  height: 100%;
  min-width: 9.37rem;
  padding: 0 1rem;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  justify-content: center;

  color: $white;
  text-align: right;
  z-index: 1;

  #data_set_filename {
    // line-height: 20px;

    // span {}

    a {
      color: $white;
      display: inline_block;
      font-weight: 300;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  #data_set_timestamp {
    // line-height: 20px;
    display: block;
    @include opacity(0.5);
    font-weight: 300;
  }
}
